import React, { useState, useEffect } from 'react';
import seminarCloseSvg from '../../../assets/seminar-close.svg';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';
import axios from '../../../contants/axios';

import Agenda from './Agenda';
import SeminarChat from './SeminarChat';
import SeminarClose from './SeminarClose';

import {
  SeminarContainer,
  CloseButton,
  CloseText,
  Title,
  TitleText,
  TitleClose,
  VideoArticle,
  Content,
  AgendaArticle,
} from './SeminarStyled';

const Seminar = () => {
  const dispatch = useDispatch();
  const { seminaLink, seminarAgenda, seminarTitle } = useSelector(
    (state) => state.settings,
  );
  const [isView, setIsView] = useState(false);
  const { id: userId, username, company } = useSelector((state) => state.auth);

  const handleIsView = (props) => {
    setIsView(props);
  };

  const handleClose = () => {
    dispatch(setModal({ type: '', status: 'hide' }));
    window.location.href = '/showroom';
  };

  // 유저 목록 받아오기
  useEffect(() => {
    if (!seminaLink) return;

    (async () => {
      axios
        .get(
          `/seminar-users?video_url=${seminaLink}&userId=${userId}&_limit=-1`,
        )
        .then((res) => {
          if (res.data.length > 0) {
            return;
          } else {
            axios({
              method: 'post',
              url: `/seminar-users`,
              data: {
                userId: userId,
                company: company,
                username: username,
                video_url: seminaLink,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SeminarContainer>
        <Title>
          <TitleText>{seminarTitle || '세미나'}</TitleText>
          <TitleClose>
            <CloseText
              onClick={() => {
                handleIsView(true);
              }}
            >
              나가기
            </CloseText>
            <CloseButton
              onClick={() => {
                handleIsView(true);
              }}
              src={seminarCloseSvg}
            />
          </TitleClose>
        </Title>
        <Content>
          <VideoArticle>
            {seminaLink !== '' ? (
              <>
                <ReactPlayer
                  className='react-player'
                  url={`${seminaLink}`}
                  width='100%'
                  height='75%'
                  playing
                  muted={false}
                  config={{
                    youtube: {
                      playerVars: {
                        showinfo: 0,
                        controls: 1,
                        modestbranding: 1,
                        origin: 'https://www.youtube.com',
                      },
                    },
                  }}
                />
                <AgendaArticle>
                  <Agenda agendaContent={seminarAgenda} />
                </AgendaArticle>
              </>
            ) : (
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  fontFamily: 'Noto Sans KR',
                  color: '#000',
                  margin: 20,
                }}
              >
                현재 진행중인 세미나가 없습니다.
              </span>
            )}
          </VideoArticle>
          <SeminarChat />
        </Content>
      </SeminarContainer>
      {isView && (
        <SeminarClose handleIsView={handleIsView} handleClose={handleClose} />
      )}
    </>
  );
};

export default Seminar;

import styled from 'styled-components';

const RendingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UIArticle = styled.div`
  position: relative;
  width: 100%;
  height: 50%;
  background: #transparent;
  display: flex;
  flex-direction: row;
  z-index: 10;

  @media (max-width: 945px) {
    flex-direction: column;
  }
`;

const LeftUIWrapper = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 115px;

  @media (max-width: 945px) {
    padding-left: 0;
    justify-content: center;
    align-items: center;
  }
`;

const RightUIWrapper = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 115px;

  @media (max-width: 945px) {
    padding-right: 0;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
`;

const CoverArticle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.5;
`;

const VideoArticle = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  background-color: #000;
  overflow: hidden;
  filter: brightness(1.6);
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Logo = styled.img`
  width: 96px;
  height: 130px;

  @media (max-width: 945px) {
    width: 66px;
    height: 100px;
  }
`;

const Title = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 37px;
  line-height: 128.8%;
  text-transform: uppercase;
  color: #e50043;

  @media (max-width: 945px) {
    font-size: 30px;
  }
`;

const SubTitle = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 37px;
  line-height: 128.8%;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 945px) {
    font-size: 30px;
  }
`;

const SigninButton = styled.button`
  margin-bottom: 20px;
  position: relative;
  width: 260px;
  height: 55px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #ffffff;
  background: #e50043;
  border: 0;
  outline: 0;
  text-shadow: 0;

  &:hover {
    cursor: pointer;
    background: #f9777c;
  }

  &:active {
    background: #f9777c;
  }

  @media (max-width: 945px) {
    width: 220px;
    height: 50px;
    font-size: 18px;
  }
`;

const SignupButton = styled.button`
  position: relative;
  width: 260px;
  height: 55px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #ffffff;
  outline: 0;
  text-shadow: 0;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }

  &:active {
    background: rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 945px) {
    width: 220px;
    height: 50px;
    font-size: 18px;
  }
`;

export {
  RendingContainer,
  UIArticle,
  LeftUIWrapper,
  RightUIWrapper,
  CoverArticle,
  VideoArticle,
  Video,
  Logo,
  Title,
  SubTitle,
  SigninButton,
  SignupButton,
};

import styled from 'styled-components';

const ObjectSurveyContainer = styled.div`
  position: absolute;
  width: 40%;
  height: 60%;
  background-color: #fff;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const TitleArticle = styled.div`
  position: relative;
  width: 100%;
  height: 75px;
  background: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 35px;

  @media (max-width: 767px) {
    height: 50px;
    padding: 10px;
  }
`;

const Title = styled.span`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 17px;
  }
`;

const CloseButton = styled.button`
  position: relative;
  float: right;
  width: 28px;
  height: 28px;
  color: #000;
  background-image: url(${(props) => props.img});
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
  }
`;

const ContentForm = styled.form`
  width: 100%;
  height: 100%;
  padding: 48px;
  background: #fff;
  overflow: overlay;

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

const SurvayArticle = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
`;

const SurvayTitle = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
`;

const SubmitButton = styled.button`
  position: relative;
  bottom: 0;
  width: 160px;
  height: 40px;
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #666666;

  &:hover {
    color: #fff;
    background: #e50043;
    cursor: pointer;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    position: relative;
    margin-top: 50px;
  }
`;

const EtcInput = styled.input`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: black;
  width: auto;
  margin-left: 5px;
`;

export {
  ObjectSurveyContainer,
  TitleArticle,
  Title,
  CloseButton,
  ContentForm,
  SurvayArticle,
  SurvayTitle,
  EtcInput,
  SubmitButton,
};

import React, { useState, useRef, useEffect } from 'react';
import logoImg from '../../assets/logo.svg';
import axios from '../../contants/axios';

import {
  SigninWrapper,
  Logo,
  EmailArticle,
  EmailText,
  EmailInput,
  PasswordArticle,
  PasswordText,
  PasswordInput,
  CheckMark,
  CheckInput,
  CheckLabel,
  SigninButton,
  ButtonText,
  SigninInfo,
  SignupLink,
  FindPwLink,
} from './SigninStyled';
import { message } from 'antd';

const SigninForm = ({ setPage }) => {
  const [check, setCheck] = useState(false);
  const emailRef = useRef();
  const emailSave = localStorage.getItem('email');
  const key = 'noti';

  useEffect(() => {
    if (emailSave !== undefined) {
      emailRef.current.value = emailSave;
    }
  }, [emailSave]);

  const emailReset = () => {
    // 이메일 저장 이후 변경 시 저장된 이메일 삭제
    localStorage.removeItem('email');
  };

  function handleChange(event) {
    setCheck(event.target.checked);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = event.target;

    if (check) {
      if (emailSave !== undefined) {
        localStorage.setItem('email', email.value);
      } else {
        // 기존 내역이 있을 시 제거 후 새로 업데이트
        localStorage.removeItem('email');
        localStorage.setItem('email', email.value);
      }
    }

    (async () => {
      try {
        const response = await axios.post('/auth/local', {
          identifier: email.value,
          password: password.value,
        });
        const token = response.data.jwt;
        sessionStorage.setItem('token', token);

        // 사용자 위치 이동 데이터 입장 시간
        const loginDate = new Date();
        // eslint-disable-next-line no-undef
        gtag('event', 'room_eter', {
          event_category: 'login',
          event_label: `${email.value}|${loginDate}`,
        });

        window.location.href = '/showroom';
      } catch (error) {
        message.error({ content: '이메일과 비밀번호를 확인해주세요.', key });
      }
    })();
  };

  return (
    <>
      <SigninWrapper onSubmit={handleSubmit}>
        <Logo src={logoImg} alt='' />
        <EmailArticle>
          <EmailText>E-mail</EmailText>
          <EmailInput
            type='email'
            name='email'
            placeholder='이메일'
            ref={emailRef}
            onFocus={emailReset}
            autoComplete='new-password'
          />
        </EmailArticle>
        <PasswordArticle>
          <PasswordText>Password</PasswordText>
          <PasswordInput
            type='password'
            name='password'
            placeholder='비밀번호'
            autoComplete='new-password'
          />
        </PasswordArticle>
        <CheckLabel>
          아이디저장
          <CheckInput type='checkbox' check onChange={handleChange} />
          <CheckMark />
        </CheckLabel>
        <SigninButton type='submit'>
          <ButtonText>LOGIN</ButtonText>
        </SigninButton>
        <SigninInfo>
          <SignupLink onClick={() => setPage(3)}>회원가입</SignupLink>
          <FindPwLink onClick={() => setPage(4)}>비밀번호 찾기</FindPwLink>
        </SigninInfo>
      </SigninWrapper>
    </>
  );
};

export default SigninForm;

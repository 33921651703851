import React, { useState } from 'react';
import axios from '../../../contants/axios';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import {
  MobileTitle,
  Title,
  Content,
  MainText,
  ButtonWrapper,
  SaveButton,
  CancelButton,
} from './styled/SecessionConfirmStyled';

const SecessionConfirm = ({ setPart }) => {
  const { id, token, email, username, company } = useSelector(
    (state) => state.auth,
  );
  const [loading, setLoading] = useState(true);
  const key = 'sending';

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(false);
    message.loading({
      content: '회원 탈퇴 진행 중입니다. 잠시만 기다려주세요.',
      key,
    });

    if (loading) {
      await axios({
        method: 'DELETE',
        url: `/users/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          isSecession: true,
        },
      })
        .then(async (res) => {
          await axios({
            method: 'POST',
            url: '/email',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              from: 'no-reply@rittal-exhibition.co.kr',
              subject: `[회원탈퇴] 회원 탈퇴 안내`,
              to: 'mkt@rittal.co.kr',
              replyTo: email,
              cc: '',
              bcc: '',
              text: `
              리탈 가상 전시관에서 발송된 안내문 입니다.<br/><br/>
              회사명 : ${company}<br/>
              성  명 : ${username}<br/>
              이메일 : ${email}<br/>
              <br/>
              내용: 회원 탈퇴 안내문입니다.
              `,
              html: `
              리탈 가상 전시관에서 발송된 안내문 입니다.<br/><br/>
              회사명 : ${company}<br/>
              성  명 : ${username}<br/>
              이메일 : ${email}<br/>
              <br/>
              내용: 회원 탈퇴 안내문입니다.
              `,
            },
          })
            .then((response) => {
              message.success({
                content:
                  '탈퇴 요청이 정상적으로 처리 되었습니다. 이용해주셔서 감사합니다.',
                key,
              });
              setTimeout(() => {
                document.location.href = '/';
              }, 2000);
            })
            .catch((error) => {
              setLoading(true);
            });
        })
        .catch((err) => {
          message.error({ content: '탈퇴 요청 중 문제가 발생했습니다.', key });
          setLoading(true);
        });
    }
  };

  return (
    <>
      <MobileTitle>회원 탈퇴</MobileTitle>
      <Title>회원 탈퇴</Title>
      <Content onSubmit={handleSubmit}>
        <MainText>회원 탈퇴 하시겠습니까?</MainText>
        <ButtonWrapper>
          <SaveButton type='submit'>확인</SaveButton>
          <CancelButton onClick={() => setPart(0)}>취소</CancelButton>
        </ButtonWrapper>
      </Content>
    </>
  );
};

export default SecessionConfirm;

import styled from 'styled-components';
import { Dropdown } from 'antd';

const HeaderContainer = styled.div`
  height: 50px;
  display: none;
  transition: 0.5s;

  @media (max-width: 767px) {
    width: 100%;
    position: fixed;
    display: ${(props) =>
      props.modal !== 'object' && props.modal !== 'stamp' ? 'flex' : 'none'};
    flex-direction: row;
    background-color: rgba(0, 0, 0, 1);
    z-index: 999;
    margin: 0;
    height: 60px;
    bottom: 0;
  }
`;

const MobileArticle = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MobileDropdown = styled(Dropdown)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MobilePopupArticle = styled.div`
  width: 75%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 2%;
  left: 3%;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  display: none;
  transition: all 0.5s ease-in-out;

  @media (max-width: 767px) {
    display: flex;
  }
`;

const MobilePopupMessage = styled.div`
  width: 100%;
  margin: 0;
  font-fmaily: Noto Sans KR;
  font-size: 14px;
  font-weight: bold;
  font-styled: normal;
  text-align: center;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:active {
    color: #e50043;
    cursor: pointer;
  }
`;

const MobileButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const MobileOffButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const MobileDropdownButton = styled.a`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const MobileSubTitle = styled.span`
  font-size: 10px;
  font-family: Noto Sans KR;
  font-weight: bold;
  line-height: 22px
  text-align: center;
  color: #fff;
  word-break: keep-all;
`;

const MobileIcon = styled.img`
  width: 21px;
  height: 24px;
`;

const MenuBtn = styled.button`
  border: none;
  background-color: transparent;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const OnAirArticle = styled.div`
  width: 17%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 2%;
  right: 3%;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  display: none;
  transition: all 0.5s ease-in-out;

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: ${(props) => (props.isView ? '#FF5656' : '#666')};

  &:hover {
    background-color: ${(props) =>
      props.isView ? '#E50043' : 'rgba(0, 0, 0, 0.8)'};
    cursor: ${(props) => (props.isView ? 'pointer' : 'default')};
    color: ${(props) => (props.isView ? '#fff' : '#666')};
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;

export {
  MobilePopupArticle,
  HeaderContainer,
  MobilePopupMessage,
  MobileArticle,
  MobileButton,
  MobileDropdownButton,
  MobileIcon,
  MobileSubTitle,
  MenuBtn,
  MobileDropdown,
  MobileOffButton,
  OnAirArticle,
};

import styled, { keyframes } from 'styled-components';

const InfoContainer = styled.div`
  position: relative;
  width: 70vw;
  height: 75vh;
  background: #fff;
  padding: 25px 60px;
  overflow: overlay;

  @media (max-width: 767px) {
    position: absolute;
    width: 100%;
    height: calc(100%);
    top: 0;
    padding: 30px 15px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  color: #000;
  background-image: url(${(props) => props.img});
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    top: 25px;
    right: 25px;
  }
`;

const Title = styled.span`
  font-size: 25px;
  line-height: 22px;
  color: #000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  border-bottom: 2px solid #000;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 35px;

  @media (max-height: 840px) {
    margin-top: 20px;
  }
`;

const AirViewAritcle = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 0;
  margin-bottom: 18px;

  @media (max-height: 840px) {
    margin-bottom: 15px;
  }
`;

const AirViewImage = styled.img`
  position: relative;
  width: 100%;
  max-height: 530px;

  @media (max-height: 840px) {
    max-height: 400px;
  }

  @media (max-height: 750px) {
    max-height: 350px;
  }
`;

const AirViewInfo = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const bounce = keyframes`
  0% {
    box-shadow: 0 0 0 0px transparent, 0 0 0 0px #fff, 
    0 0 0 0px transparent, 0 0 0 0px #fff, 
    0 0 0 0px transparent, 0 0 0 0px #fff, 
    0 0 0 0px transparent, 0 0 0 0px #fff;
  }

  100% {
    box-shadow: 0 0 0 4px transparent, 0 0 0 6px rgba(255,255,255,0), 
    0 0 0 10px transparent, 0 0 0 12px rgba(255,255,255,0), 
    0 0 0 16px transparent, 0 0 0 18px rgba(255,255,255,0), 
    0 0 0 20px transparent, 0 0 0 22px rgba(255,255,255,0);
  }
`;

const SmartButton = styled.button`
  position: absolute;
  top: 20%;
  left: 44%;
  width: 25px;
  height: 25px;
  background: #e50043;
  margin: 1em;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 0;

  &:hover {
    background: #f9777c;
    cursor: pointer;
    animation: ${bounce} 1s;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1440px) {
    top: 20%;
    left: 44%;
    padding: 0;
  }

  @media (max-width: 1199px) {
    top: 20%;
    left: 43%;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  @media (max-width: 980px) {
    top: 24%;
    left: 42%;
    width: 15px;
    height: 15px;
    margin: 0;
    border: 2px solid #fff;
    padding: 0;
  }
`;

const RasButton = styled.button`
  position: absolute;
  top: 20%;
  left: 60.5%;
  width: 25px;
  height: 25px;
  background: #e50043;
  margin: 1em;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 0;

  &:hover {
    background: #f9777c;
    cursor: pointer;
    animation: ${bounce} 1s;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1440px) {
    top: 20%;
    left: 60.5%;
    padding: 0;
  }

  @media (max-width: 1199px) {
    top: 20%;
    left: 61%;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  @media (max-width: 980px) {
    top: 24%;
    left: 65%;
    width: 15px;
    height: 15px;
    margin: 0;
    border: 2px solid #fff;
    padding: 0;
  }
`;

const ITButton = styled.button`
  position: absolute;
  top: 49%;
  left: 26%;
  width: 25px;
  height: 25px;
  background: #e50043;
  margin: 1em;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 0;

  &:hover {
    background: #f9777c;
    cursor: pointer;
    animation: ${bounce} 1s;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1440px) {
    top: 49%;
    left: 26%;
    padding: 0;
  }

  @media (max-width: 1199px) {
    top: 50%;
    left: 25%;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  @media (max-width: 980px) {
    top: 52%;
    left: 27%;
    width: 15px;
    height: 15px;
    margin: 0;
    border: 2px solid #fff;
    padding: 0;
  }
`;

const EnclosureButton = styled.button`
  position: absolute;
  top: 64%;
  left: 46.5%;
  width: 25px;
  height: 25px;
  background: #e50043;
  margin: 1em;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 0;

  &:hover {
    background: #f9777c;
    cursor: pointer;
    animation: ${bounce} 1s;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1440px) {
    top: 64%;
    left: 46.5%;
    padding: 0;
  }

  @media (max-width: 1199px) {
    top: 64%;
    left: 46.5%;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  @media (max-width: 980px) {
    top: 67%;
    left: 48%;
    width: 15px;
    height: 15px;
    margin: 0;
    border: 2px solid #fff;
    padding: 0;
  }
`;

const CooperateButton = styled.button`
  position: absolute;
  top: 71%;
  left: 52%;
  width: 25px;
  height: 25px;
  background: #e50043;
  margin: 1em;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 0;

  &:hover {
    background: #f9777c;
    cursor: pointer;
    animation: ${bounce} 1s;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1440px) {
    top: 71%;
    left: 52%;
    padding: 0;
  }

  @media (max-width: 1199px) {
    top: 72%;
    left: 51%;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  @media (max-width: 980px) {
    top: 75%;
    left: 54%;
    width: 15px;
    height: 15px;
    margin: 0;
    border: 2px solid #fff;
    padding: 0;
  }
`;

const DistributionButton = styled.button`
  position: absolute;
  top: 71%;
  left: 72%;
  width: 25px;
  height: 25px;
  background: #e50043;
  margin: 1em;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 0;

  &:hover {
    background: #f9777c;
    cursor: pointer;
    animation: ${bounce} 1s;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1440px) {
    top: 71%;
    left: 72%;
    padding: 0;
  }

  @media (max-width: 1199px) {
    top: 72%;
    left: 72%;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  @media (max-width: 980px) {
    top: 75%;
    left: 73%;
    width: 15px;
    height: 15px;
    margin: 0;
    border: 2px solid #fff;
    padding: 0;
  }
`;

export {
  InfoContainer,
  CloseButton,
  Title,
  Content,
  AirViewAritcle,
  AirViewImage,
  SmartButton,
  RasButton,
  ITButton,
  EnclosureButton,
  CooperateButton,
  DistributionButton,
  AirViewInfo,
};

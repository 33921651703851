import React from 'react';

import {
  NotFoundContainer,
  Title,
  SubTitle,
  BackLink,
} from './styled/NotFoundStyled';

const NotFound = () => {
  return (
    <NotFoundContainer>
      <Title>404</Title>
      <SubTitle>Page Not Found</SubTitle>
      <SubTitle>잘못된 요청입니다.</SubTitle>
      <BackLink onClick={() => (document.location.href = '/')}>
        ← 처음으로 돌아가기
      </BackLink>
    </NotFoundContainer>
  );
};

export default NotFound;

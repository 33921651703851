import React, { useEffect, useState } from 'react';
import stampArt from '../../../assets/stamp/stampArt.svg';
import stampDone from '../../../assets/stamp/stamp_complete.svg';
import { API_URL } from '../../../contants/axios';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { moveProduct } from '../../../actions/move.actions';
import { setModal } from '../../../actions/modals.actions';

import {
  ContentArticle,
  TitleImage,
  ContentTitle,
  ContentSub,
  StampArticle1,
  StampArticle2,
  Stamp,
  StampDone,
  StampImg,
  StampText,
  StampButton,
} from './styled/MissionStyled';

const Mission = ({ isCurrent, userStamp, userCheck }) => {
  const dispatch = useDispatch();
  const [topStamp, setTopStamp] = useState([]);
  const [bottomStamp, setBottomStamp] = useState([]);
  const { productlist } = useSelector((state) => state.productlist);
  const [productStamp, setProductStamp] = useState([]);
  const [loading, setLoading] = useState(true);

  const missionCheck = () => {
    isCurrent(1);
  };

  useEffect(() => {
    if (loading) {
      const productFilter = productlist.filter((value) => value.productStamp);
      setProductStamp(productFilter);
    }

    return () => {
      setLoading(false);
    };
  }, [loading, productlist]);

  useEffect(() => {
    if (productStamp.length > 0) {
      let len = productStamp.length;
      let cnt = Math.floor(len / 3) + (Math.floor(len % 3) > 0 ? 1 : 0);
      let tmp = [];

      for (let i = 0; i < cnt; i++) {
        tmp.push(productStamp.splice(0, 3));
      }

      if (tmp.length > 1) {
        setTopStamp(tmp[0]);
        setBottomStamp(tmp[1]);
      } else {
        setTopStamp(tmp[0]);
      }
    }
  }, [productStamp]);

  return (
    <>
      <ContentArticle>
        <TitleImage src={stampArt} alt='stamp' />
        <ContentTitle>
          제품 확인 후에 스탬프를 받으세요.
          <br />다 받으신 분께 선물을 드립니다.
          <ContentSub>
            * 제품 이미지를 클릭하시면 해당제품으로 이동합니다.
          </ContentSub>
        </ContentTitle>
      </ContentArticle>
      <StampArticle1>
        {topStamp.length > 0
          ? topStamp.map((data, index) => {
              let image = '';
              if (data.productMedia.length > 0) {
                image = data.productMedia;
              } else {
                image = [{ url: '' }];
              }
              const number = [0, 1, 2];
              if (image) {
                image.sort((a, b) => {
                  if (a.mime === b.mime) {
                    return 0;
                  }
                  return a.mime > b.mime ? 1 : -1;
                });
              }
              return (
                <Stamp key={number[index]}>
                  {userStamp.indexOf(data.productName) > -1 ? (
                    <>
                      <StampDone img={stampDone} />
                      <StampImg
                        alt={data.productName}
                        src={`${API_URL}${image[0].formats.thumbnail.url}`}
                        done={true}
                      />
                      <StampText done={true}>{data.productName}</StampText>
                    </>
                  ) : (
                    <>
                      <StampImg
                        alt={data.productName}
                        src={`${API_URL}${image[0].url}`}
                        done={false}
                        id={data.id}
                        onClick={(e) => {
                          dispatch(moveProduct(`${e.target.id}`));
                          dispatch(setModal({ type: '', status: 'hide' }));
                          setTimeout(() => {
                            dispatch(moveProduct(''));
                          }, 100);
                        }}
                      />
                      <StampText done={false}>{data.productName}</StampText>
                    </>
                  )}
                </Stamp>
              );
            })
          : ''}
      </StampArticle1>
      <StampArticle2>
        {bottomStamp.length > 0
          ? bottomStamp.map((data, index) => {
              let image = '';
              if (data.productMedia.length > 0) {
                image = data.productMedia;
              } else {
                image = [{ url: '' }];
              }
              const number = [3, 4, 5];
              if (image) {
                image.sort((a, b) => {
                  if (a.mime === b.mime) {
                    return 0;
                  }
                  return a.mime > b.mime ? 1 : -1;
                });
              }
              return (
                <Stamp key={number[index]}>
                  {userStamp.indexOf(data.productName) > -1 ? (
                    <>
                      <StampDone img={stampDone} />
                      <StampImg
                        alt={data.productName}
                        src={`${API_URL}${image[0].formats.thumbnail.url}`}
                        done={true}
                      />
                      <StampText done={true}>{data.productName}</StampText>
                    </>
                  ) : (
                    <>
                      <StampImg
                        alt={data.productName}
                        src={`${API_URL}${image[0].url}`}
                        done={false}
                        id={data.id}
                        onClick={(e) => {
                          dispatch(moveProduct(`${e.target.id}`));
                          dispatch(setModal({ type: '', status: 'hide' }));
                          setTimeout(() => {
                            dispatch(moveProduct(''));
                          }, 100);
                        }}
                      />
                      <StampText done={false}>{data.productName}</StampText>
                    </>
                  )}
                </Stamp>
              );
            })
          : ''}
      </StampArticle2>
      {userCheck === true ? (
        <Tooltip title='이미 참여하셨습니다.' color={'#E50043'}>
          <StampButton done={false}>응모하러가기 {'>'}</StampButton>
        </Tooltip>
      ) : userStamp.length === topStamp.length + bottomStamp.length ? (
        <StampButton onClick={() => missionCheck()} done={true}>
          응모하러가기 {'>'}
        </StampButton>
      ) : (
        <Tooltip
          title='스탬프를 다 모은 후 응모가 가능합니다.'
          color={'#E50043'}
        >
          <StampButton done={false}>응모하러가기 {'>'}</StampButton>
        </Tooltip>
      )}
    </>
  );
};

export default Mission;

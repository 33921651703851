import React, { useState, useRef } from 'react';
import closeImg from '../../../assets/close.svg';
import mobileCloseImg from '../../../assets/closewhite.svg';
import axios from '../../../contants/axios';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { setAuth } from '../../../actions/auth.actions';
import { setModal } from '../../../actions/modals.actions';

import {
  telData,
  emailData,
  industryData,
  departmentData,
  dutyData,
} from '../../assets/SelectData';

import {
  userNameValidate,
  passwordValidate,
  passwordCheckValidate,
  companyValidate,
  phoneValidate,
  departmentValidate,
  dutyValidate,
  industryValidate,
} from '../../assets/validate';

import Secession from '../Secession/Secession';
import SecessionConfirm from '../Secession/SecessionConfirm';

import {
  MyInfoContainer,
  CloseButton,
  Title,
  Content,
  MobileTitle,
  MobileCloseButton,
  ContentArticle,
  ContentTitle,
  ContentInput,
  ContentSelect,
  PhoneArticle,
  PhoneInput,
  PhoneSelect,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectInput,
  EmailSelectBox,
  SecessionWrapper,
  SecessionLink,
  ButtonWrapper,
  SaveButton,
  SubText,
} from './MyInfoStyled';

const MyInfo = () => {
  const [part, setPart] = useState(0);
  const [loading, setLoading] = useState(false);
  const emailSelect = useRef();
  const dispatch = useDispatch();
  const {
    id,
    token,
    username,
    email,
    company,
    department,
    duty,
    industry,
    phone,
  } = useSelector((state) => state.auth);

  const emails = email.split('@');
  const phones = phone.split('|') || '';

  const authentication = async (password) => {
    return await axios
      .post('/auth/local', {
        identifier: email,
        password: password,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const changeUsers = async (
    newName,
    newPassword,
    newCompany,
    newPhoneFirst,
    newPhone,
    newDepartment,
    newDuty,
    newIndustry,
  ) => {
    return await axios({
      method: 'PUT',
      url: `/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        username: newName,
        password: newPassword,
        company: newCompany,
        phone: `${newPhoneFirst}|${newPhone}`,
        department: newDepartment,
        duty: newDuty,
        industry: newIndustry,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {
      newName,
      nowPassword,
      newPassword,
      newPasswordConfirm,
      newPhoneFirst,
      newPhone,
      newCompany,
      newDepartment,
      newDuty,
      newIndustry,
    } = event.target;

    setLoading(true);

    if (nowPassword.value !== '') {
      await authentication(nowPassword.value).then(async (res) => {
        if (newPassword.value !== '' || newPasswordConfirm.value !== '') {
          if (!passwordValidate(newPassword.value)) {
            message.error(
              '비밀번호는 8~15자리 이내의 특수문자/문자/숫자 입니다.',
            );
            setLoading(false);
          } else if (
            !passwordCheckValidate(newPassword.value, newPasswordConfirm.value)
          ) {
            message.error('새 비밀번호가 일치하지 않습니다.');
            setLoading(false);
          } else if (!userNameValidate(newName.value)) {
            message.error('잘못된 이름 입력 입니다.');
            setLoading(false);
          } else if (!companyValidate(newCompany.value)) {
            message.error('잘못된 회사 입력 입니다.');
            setLoading(false);
          } else if (!phoneValidate(newPhone.value)) {
            message.error('잘못된 연락처 입력 입니다.');
            setLoading(false);
          } else if (!departmentValidate(newDepartment.value)) {
            message.error('잘못된 소속부서 입력 입니다.');
            setLoading(false);
          } else if (!dutyValidate(newDuty.value)) {
            message.error('잘못된 직무 입력 입니다.');
            setLoading(false);
          } else if (!industryValidate(newIndustry.value)) {
            message.error('잘못된 산업 입력 입니다.');
            setLoading(false);
          } else {
            await changeUsers(
              newName.value,
              newPassword.value,
              newCompany.value,
              newPhoneFirst.value,
              newPhone.value,
              newDepartment.value,
              newDuty.value,
              newIndustry.value,
            )
              .then((res) => {
                message.success(
                  '새로운 비밀번호와 정보 변경이 완료되었습니다.',
                );
                dispatch(
                  setAuth({
                    username: newName.value,
                    company: newCompany.value,
                    department: newDepartment.value,
                    duty: newDuty.value,
                    industry: newIndustry.value,
                    phone: `${newPhoneFirst.value}|${newPhone.value}`,
                  }),
                );
              })
              .catch((error) => {
                message.error('정보 변경에 실패했습니다.');
                setLoading(false);
              });
          }
        } else if (
          newPassword.value === '' ||
          newPasswordConfirm.value === ''
        ) {
          await changeUsers(
            newName.value,
            nowPassword.value,
            newCompany.value,
            newPhoneFirst.value,
            newPhone.value,
            newDepartment.value,
            newDuty.value,
            newIndustry.value,
          )
            .then((res) => {
              message.success('정보 변경이 완료되었습니다.');
              setLoading(false);
              dispatch(
                setAuth({
                  username: newName.value,
                  company: newCompany.value,
                  department: newDepartment.value,
                  duty: newDuty.value,
                  industry: newIndustry.value,
                  phone: `${newPhoneFirst.value}|${newPhone.value}`,
                }),
              );
            })
            .catch((error) => {
              message.error('정보 변경에 실패했습니다.');
              setLoading(false);
            });
        } else {
          message.error('현재 비밀번호가 틀립니다.');
          setLoading(false);
        }
      });
    } else {
      message.error('변경을 원하시면 현재 비밀번호를 입력해주세요.');
      setLoading(false);
    }
  };

  return (
    <MyInfoContainer part={part}>
      {part === 1 ? (
        <Secession setPart={setPart} />
      ) : part === 2 ? (
        <SecessionConfirm setPart={setPart} />
      ) : (
        <>
          <MobileTitle>내정보 관리</MobileTitle>
          <MobileCloseButton
            onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
            img={mobileCloseImg}
          />
          <CloseButton
            onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
            img={closeImg}
          />
          <Title>내정보 관리</Title>
          <Content onSubmit={handleSubmit}>
            <SubText>변경은 인증을 위해 현재 비밀번호가 요구됩니다.</SubText>
            <ContentArticle>
              <ContentTitle>이름 :</ContentTitle>
              <ContentInput
                type='text'
                name='newName'
                autoComplete='new-password'
                defaultValue={username}
              />
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>이메일 :</ContentTitle>
              <EmailArticle>
                <EmailInput
                  type='text'
                  name='newEmail'
                  defaultValue={emails[0]}
                  disabled={true}
                />
                <AtInput readOnly value='@' disabled={true} />
                <EmailSelectInput
                  type='text'
                  ref={emailSelect}
                  autoComplete='new-password'
                  name='newEmailAddress'
                  defaultValue={emails[1]}
                  disabled={true}
                />
                <EmailSelectBox disabled={true}>
                  {emailData.map((address) => {
                    return (
                      <option key={address} value={address}>
                        {address}
                      </option>
                    );
                  })}
                </EmailSelectBox>
              </EmailArticle>
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>현재 비밀번호 :</ContentTitle>
              <ContentInput
                type='password'
                name='nowPassword'
                autoComplete='new-password'
              />
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>새 비밀번호 :</ContentTitle>
              <ContentInput
                type='password'
                name='newPassword'
                autoComplete='new-password'
              />
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>새 비밀번호 확인 :</ContentTitle>
              <ContentInput
                type='password'
                name='newPasswordConfirm'
                autoComplete='new-password'
              />
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>연락처 :</ContentTitle>
              <PhoneArticle>
                <PhoneSelect name='newPhoneFirst' defaultValue={phones[0]}>
                  {telData.map((tel) => {
                    return (
                      <option key={tel} value={tel}>
                        {tel}
                      </option>
                    );
                  })}
                </PhoneSelect>
                <PhoneInput
                  type='text'
                  placeholder="연락처 입력 ('-' 하이픈 제외)"
                  name='newPhone'
                  defaultValue={phones[1]}
                />
              </PhoneArticle>
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>회사명 :</ContentTitle>
              <ContentInput
                type='text'
                name='newCompany'
                defaultValue={company}
              ></ContentInput>
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>소속부서 :</ContentTitle>
              <ContentSelect name='newDepartment' defaultValue={department}>
                {departmentData.map((data) => {
                  return (
                    <option key={data} value={data}>
                      {data}
                    </option>
                  );
                })}
              </ContentSelect>
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>직무 :</ContentTitle>
              <ContentSelect name='newDuty' defaultValue={duty}>
                {dutyData.map((data) => {
                  return (
                    <option key={data} value={data}>
                      {data}
                    </option>
                  );
                })}
              </ContentSelect>
            </ContentArticle>
            <ContentArticle>
              <ContentTitle>산업 :</ContentTitle>
              <ContentSelect name='newIndustry' defaultValue={industry}>
                {industryData.map((data) => {
                  return (
                    <option key={data} value={data}>
                      {data}
                    </option>
                  );
                })}
              </ContentSelect>
            </ContentArticle>
            <SecessionWrapper>
              <SecessionLink onClick={() => setPart(1)}>
                {'회원탈퇴하기 >'}
              </SecessionLink>
            </SecessionWrapper>
            <ButtonWrapper>
              <SaveButton type='submit' disabled={loading}>
                저장
              </SaveButton>
            </ButtonWrapper>
          </Content>
        </>
      )}
    </MyInfoContainer>
  );
};

export default MyInfo;

export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const SAVE_PRODUCT = 'SAVE_PRODUCT';

export const loadProduct = () => ({
  type: LOAD_PRODUCT,
});

export const getProduct = (product) => ({
  type: GET_PRODUCT,
  product,
});

export const saveProduct = (productlist) => ({
  type: SAVE_PRODUCT,
  productlist,
});

// 제품 상세설명
export const GET_PRODUCT_DETAILED = 'GET_PRODUCT_DETAILED';

export const getproductDetailed = (detail) => ({
  type: GET_PRODUCT_DETAILED,
  payload: detail,
});

import { useEffect } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default (target, type, listener, ...options) => {
  useEffect(() => {
    const targetIsRef = target.hasOwnProperty('current');
    const currentTarget = targetIsRef ? target.current : target;
    if (currentTarget) {
      currentTarget.addEventListener(type, listener, ...options);
    }

    return () => {
      if (currentTarget) {
        currentTarget.removeEventListener(type, listener, ...options);
      }
    };
  }, [target, type, listener, options]);
};

import React from 'react';
import closeImg from '../../../assets/close.svg';
import mobileCloseImg from '../../../assets/closewhite.svg';
import { useDispatch } from 'react-redux';
import {
  setModal,
  setChatModal,
  setObjectBar,
} from '../../../actions/modals.actions';
import { tourState } from '../../../actions/tour.actions';

import {
  TourContainer,
  MobileTitle,
  MobileCloseButton,
  CloseButton,
  Title,
  Content,
  TourButton,
} from './styled/TourStyled';

const Tour = ({ isCurrent }) => {
  const dispatch = useDispatch();

  return (
    <TourContainer>
      <MobileTitle>전시관 투어</MobileTitle>
      <MobileCloseButton onClick={() => isCurrent(0)} img={mobileCloseImg} />
      <CloseButton onClick={() => isCurrent(0)} img={closeImg} />
      <Title>전시관 투어</Title>
      <Content>
        <p>원하시는 투어를 선택하시면 투어가 시작됩니다.</p>
        <TourButton
          onClick={() => {
            dispatch(setModal({ type: '', status: 'hide' }));
            dispatch(setChatModal({ show: 'hide' }));
            dispatch(setObjectBar({ status: 'hide' }));
            dispatch(
              tourState({
                guide: true,
                scene: 1,
                status: 'start',
                objects: [
                  {
                    id: '5fb49e41509f793d2773ba1a',
                    view: 'TS 8',
                  },
                  {
                    id: '601ae03b88983358235d1af1',
                    view: 'RLine Busbar System',
                  },
                  {
                    id: '5fb4a69edcffb63ea0751a04',
                    view: 'blueECooling',
                  },
                  {
                    id: '5fb4a9bbdcffb63ea0751a1d',
                    view: 'smartPanel',
                  },
                ],
                title: '투어모드 1 : 처음이신가요?',
              }),
            );
          }}>
          투어모드 1 : 처음이신가요?
        </TourButton>
        <TourButton
          onClick={() => {
            dispatch(setModal({ type: '', status: 'hide' }));
            dispatch(setChatModal({ show: 'hide' }));
            dispatch(setObjectBar({ status: 'hide' }));
            dispatch(
              tourState({
                guide: true,
                scene: 2,
                status: 'start',
                objects: [
                  {
                    id: '5fb4a9bbdcffb63ea0751a1d',
                    view: 'smartPanel',
                  },
                  {
                    id: '601ae34c88983358235d1afc',
                    view: 'SmartMonitoring',
                  },
                  {
                    id: '5fb4a825dcffb63ea0751a19',
                    view: 'HMDC',
                  },
                  {
                    id: '5fb4a9fadcffb63ea0751a21',
                    view: 'edgeDc',
                  },
                ],
                title: '투어모드 2 : 리탈 솔루션 - 배전&공조 모니터링',
              }),
            );
          }}>
          투어모드 2 : 리탈 솔루션 - 배전&공조 모니터링
        </TourButton>
        <TourButton
          onClick={() => {
            dispatch(setModal({ type: '', status: 'hide' }));
            dispatch(setChatModal({ show: 'hide' }));
            dispatch(setObjectBar({ status: 'hide' }));
            dispatch(
              tourState({
                guide: true,
                scene: 3,
                status: 'start',
                objects: [
                  {
                    id: '602b921217ca810c81fd07aa',
                    view: 'ValueChainZone',
                  },
                  {
                    id: '6267baeb2936b7532ec32d38',
                    view: 'Smart Panel S2',
                  },
                ],
                title: '투어모드 3 : 판넬제작 - 스마트하게!',
              }),
            );
          }}>
          투어모드 3 : 판넬제작 - 스마트하게!
        </TourButton>
      </Content>
    </TourContainer>
  );
};

export default Tour;

import { combineReducers } from 'redux';

import { product, productlist, getProductDetailed } from './product';

import { modals, objectBar, chatModal } from './modals';

import move from './move';
import stamp from './stamp';
import auth from './auth';
import tour from './tour';
import settings, { musicToggle } from './settings';

const reducer = combineReducers({
  product,
  productlist,
  move,
  modals,
  objectBar,
  chatModal,
  stamp,
  auth,
  getProductDetailed,
  tour,
  settings,
  musicToggle,
});

export default reducer;

import React, { useState, useRef, useEffect } from 'react';
import { message } from 'antd';
import axios from '../../contants/axios';

import {
  telData,
  emailData,
  industryData,
  departmentData,
  dutyData,
  pathData,
} from '../assets/SelectData';

import {
  SignupWrapper,
  BackLink,
  NameInput,
  PhoneArticle,
  PhoneSelect,
  PhoneInput,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectInput,
  EmailSelectBox,
  PasswordInput,
  PasswordCheckInput,
  PrivacyContainer,
  PrivacyArticle,
  CheckMark,
  CheckInput,
  CheckLabel,
  PrivacyButton,
  SignupButton,
  ButtonText,
  CompanyInput,
  DepartmentSelect,
  DutySelect,
  IndustrySelect,
  PathSelect,
  ErrorText,
  InformationText,
  CheckText,
} from './styled/SignupStyled';

import {
  userNameValidate,
  emailValidate,
  passwordValidate,
  passwordCheckValidate,
  companyValidate,
  phoneValidate,
  departmentValidate,
  dutyValidate,
  industryValidate,
  pathValidate,
} from '../assets/validate';

const SignupForm = ({ isView, isNumber, setPage }) => {
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState('');
  const [emailCheck, setEmailCheck] = useState({
    first: '',
    last: '',
    isDone: false,
    done: false,
  });
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
    company: '',
    phone: '',
    department: '',
    duty: '',
    industry: '',
    path: '',
  });
  const [allChecked, setAllChecked] = useState({
    allChecked: false,
    checked0: false,
    checked1: false,
    checked2: false,
  });
  const emailSelect = useRef();
  const key = 'updatable';

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  }, [error]);

  useEffect(() => {
    if (emailCheck.isDone) {
      setTimeout(() => {
        setEmailCheck((prevState) => {
          return {
            ...prevState,
            isDone: false,
          };
        });
      }, 1500);
    }
  }, [emailCheck.isDone]);

  useEffect(() => {
    if (validate) {
      message.loading({ content: '회원가입 처리 중입니다.', key });
      (async () => {
        try {
          await axios.post('/auth/local/register', {
            username: user.username,
            email: user.email,
            password: user.password,
            company: user.company,
            phone: user.phone,
            department: user.department,
            duty: user.duty,
            industry: user.industry,
            path: user.path,
          });
          setPage(1);
          message.success({
            content: '회원가입이 완료되었습니다.',
            key,
            duration: 2,
          });
        } catch (error) {
          setError('email');
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, validate]);

  const handleChange = (event) => {
    const selectEmail = event.target.value;
    if (selectEmail === '직접입력') {
      emailSelect.current.value = '';
    } else {
      emailSelect.current.value = selectEmail;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      siusername,
      siemail,
      emailAddress,
      sipassword,
      sipasswordCheck,
      company,
      phoneFirst,
      phone,
      department,
      duty,
      industry,
      path,
    } = event.target;

    // eslint-disable-next-line no-useless-escape
    let removeHyphen = phone.value.replace(/\-/g, '');

    if (allChecked.checked0 === false || allChecked.checked1 === false) {
      setError('agree');

      setTimeout(() => {
        setError('');
      }, 2000);
    } else {
      if (
        !emailValidate(siemail.value, emailAddress.value) ||
        !emailCheck.done
      ) {
        setError('email');
      } else if (!passwordValidate(sipassword.value)) {
        setError('password');
      } else if (
        !passwordCheckValidate(sipassword.value, sipasswordCheck.value)
      ) {
        setError('passwordCheck');
      } else if (!userNameValidate(siusername.value)) {
        setError('name');
      } else if (!companyValidate(company.value)) {
        setError('company');
      } else if (!phoneValidate(removeHyphen)) {
        setError('phone');
      } else if (!departmentValidate(department.value)) {
        setError('department');
      } else if (!dutyValidate(duty.value)) {
        setError('duty');
      } else if (!industryValidate(industry.value)) {
        setError('industry');
      } else if (!pathValidate(path.value)) {
        setError('path');
      } else {
        setUser({
          username: siusername.value,
          email: `${siemail.value}@${emailAddress.value}`,
          password: sipassword.value,
          company: company.value,
          phone: `${phoneFirst.value}|${removeHyphen}`,
          department: department.value,
          duty: duty.value,
          industry: industry.value,
          path: path.value,
        });
        setValidate(true);
      }
    }
  };

  const emailOverlapCheck = () => {
    if (emailCheck.first !== '' && emailCheck.last !== '') {
      if (!emailValidate(emailCheck.first, emailCheck.last)) {
        setError('email');
      } else {
        const email = `${emailCheck.first}@${emailCheck.last}`;
        (async () => {
          axios
            .get(`/users/count?email=${email}`)
            .then((res) => {
              if (res.data === 0) {
                setEmailCheck((prevState) => {
                  return {
                    ...prevState,
                    isDone: true, // 완료 확인 (css 이벤트용)
                    done: true, // 최종 완료 확인
                  };
                });
              } else {
                setError('email');
              }
            })
            .catch((err) => {
              setError('email');
            });
        })();
      }
    } else {
      return null;
    }
  };

  const handleAllChecked = (checked) => {
    return setAllChecked({
      allChecked: checked,
      checked0: checked,
      checked1: checked,
      checked2: checked,
    });
  };

  const handleSingleChecked = (checked, position) => {
    if (position === '0') {
      return setAllChecked((prev) => {
        return {
          ...prev,
          allChecked: checked && prev.checked1 && prev.checked2,
          checked0: checked,
        };
      });
    } else if (position === '1') {
      return setAllChecked((prev) => {
        return {
          ...prev,
          allChecked: prev.checked0 && checked && prev.checked2,
          checked1: checked,
        };
      });
    } else {
      return setAllChecked((prev) => {
        return {
          ...prev,
          allChecked: prev.checked0 && prev.checked1 && checked,
          checked2: checked,
        };
      });
    }
  };

  return (
    <SignupWrapper onSubmit={handleSubmit} autoComplete='off'>
      <BackLink onClick={() => setPage(1)}>처음으로 돌아가기</BackLink>
      <EmailArticle error={error} isDone={emailCheck.isDone}>
        <EmailInput
          type='text'
          placeholder='e-mail 입력'
          name='siemail'
          error={error}
          isDone={emailCheck.isDone}
          onChange={(e) => {
            setEmailCheck((prevState) => {
              return {
                ...prevState,
                first: e.target.value,
              };
            });
          }}
          onBlur={() => {
            emailOverlapCheck();
          }}
        />
        <AtInput
          readOnly
          value='@'
          error={error}
          isDone={emailCheck.isDone}
          tabIndex='-1'
        />
        <EmailSelectInput
          type='text'
          ref={emailSelect}
          name='emailAddress'
          error={error}
          isDone={emailCheck.isDone}
          onChange={(e) => {
            setEmailCheck((prevState) => {
              return {
                ...prevState,
                last: e.target.value,
              };
            });
          }}
          onBlur={() => {
            emailOverlapCheck();
          }}
        />
        <EmailSelectBox
          error={error}
          isDone={emailCheck.isDone}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value !== '직접입력') {
              setEmailCheck((prevState) => {
                return {
                  ...prevState,
                  last: e.target.value,
                };
              });
            }
          }}
          onBlur={() => {
            emailOverlapCheck();
          }}
        >
          {emailData.map((address) => {
            return (
              <option key={address} value={address}>
                {address}
              </option>
            );
          })}
        </EmailSelectBox>
      </EmailArticle>
      {error === 'email' ? (
        <ErrorText error={error}>
          ※ 중복되거나 잘못된 이메일 형식입니다.
        </ErrorText>
      ) : (
        ''
      )}
      {emailCheck.isDone ? (
        <ErrorText style={{ color: '#31992e' }}>
          ※ 사용 가능한 이메일 입니다.
        </ErrorText>
      ) : (
        ''
      )}
      <PasswordInput
        type='password'
        placeholder='비밀번호'
        name='sipassword'
        error={error}
        autoComplete='new-password'
      />
      {error === 'password' ? (
        <ErrorText error={error}>
          ※ 8~15자리 이내의 특수문자/문자/숫자 입니다.
        </ErrorText>
      ) : (
        ''
      )}
      <PasswordCheckInput
        type='password'
        placeholder='비밀번호 확인'
        name='sipasswordCheck'
        error={error}
      />
      {error === 'passwordCheck' ? (
        <ErrorText error={error}>※ 비밀번호가 일치하지 않습니다.</ErrorText>
      ) : (
        ''
      )}
      <NameInput
        type='text'
        placeholder='이름 (실명 입력)'
        name='siusername'
        error={error}
        autoComplete='new-password'
      />
      {error === 'name' ? (
        <ErrorText error={error}>※ 잘못된 이름입니다.</ErrorText>
      ) : (
        ''
      )}
      <CompanyInput
        type='text'
        placeholder='회사명'
        name='company'
        error={error}
      />
      {error === 'company' ? (
        <ErrorText error={error}>※ 잘못된 회사명 입니다.</ErrorText>
      ) : (
        <InformationText>
          *포털 사이트, 기관 등에 등록된 회사명 전체를 기입 요망 (예: 리탈 [X] ,
          ㈜리탈 [O] )
        </InformationText>
      )}
      <PhoneArticle error={error}>
        <PhoneSelect name='phoneFirst' error={error}>
          {telData.map((tel) => {
            return (
              <option key={tel} value={tel}>
                {tel}
              </option>
            );
          })}
        </PhoneSelect>
        <PhoneInput
          type='text'
          placeholder="연락처 입력 ('-' 하이픈 제외)"
          name='phone'
          error={error}
        />
      </PhoneArticle>
      {error === 'phone' ? (
        <ErrorText error={error}>※ 잘못된 연락처 양식 입니다.</ErrorText>
      ) : (
        ''
      )}
      <DepartmentSelect name='department' error={error} defaultValue='default'>
        <option value='default' disabled hidden>
          회사 구분 선택
        </option>
        {departmentData.map((data) => {
          return (
            <option key={data} value={data}>
              {data}
            </option>
          );
        })}
      </DepartmentSelect>
      {error === 'department' ? (
        <ErrorText error={error}>※ 회사 구분을 선택해주세요.</ErrorText>
      ) : (
        ''
      )}
      <DutySelect name='duty' error={error} defaultValue='default'>
        <option value='default' disabled hidden>
          직무 선택
        </option>
        {dutyData.map((data) => {
          return (
            <option key={data} value={data}>
              {data}
            </option>
          );
        })}
      </DutySelect>
      {error === 'duty' ? (
        <ErrorText error={error}>※ 직무를 선택해주세요.</ErrorText>
      ) : (
        ''
      )}
      <IndustrySelect name='industry' error={error} defaultValue='default'>
        <option value='default' disabled hidden>
          산업 선택
        </option>
        {industryData.map((data) => {
          return (
            <option key={data} value={data}>
              {data}
            </option>
          );
        })}
      </IndustrySelect>
      {error === 'industry' ? (
        <ErrorText error={error}>※ 산업을 선택해주세요.</ErrorText>
      ) : (
        ''
      )}
      <PathSelect name='path' error={error} defaultValue='미선택'>
        <option value='미선택' disabled hidden>
          알게된 경로 선택
        </option>
        {pathData.map((data) => {
          return (
            <option key={data} value={data}>
              {data}
            </option>
          );
        })}
      </PathSelect>
      {error === 'path' ? (
        <ErrorText error={error}>※ 알게된 경로를 선택해주세요.</ErrorText>
      ) : (
        ''
      )}
      <PrivacyContainer>
        <PrivacyArticle>
          <CheckLabel
            style={{
              borderBottom: '1px dashed rgba(0, 0, 0, 0.4)',
              marginBottom: 10,
              paddingBottom: 10,
            }}
          >
            전체동의
            <CheckInput
              type='checkbox'
              name='full'
              checked={allChecked.allChecked}
              onChange={(e) => {
                handleAllChecked(e.target.checked);
              }}
            />
            <CheckMark />
          </CheckLabel>
        </PrivacyArticle>
        <PrivacyArticle>
          <CheckLabel>
            <CheckText style={{ color: '#E50043', marginLeft: 5 }}>
              (필수)
            </CheckText>
            개인정보 수집 및 이용 동의
            <CheckInput
              type='checkbox'
              name='privacys'
              checked={allChecked.checked0}
              onChange={(e) => {
                handleSingleChecked(e.target.checked, '0');
              }}
            />
            <CheckMark />
          </CheckLabel>
          <PrivacyButton
            onClick={() => {
              isView(true);
              isNumber('1');
            }}
          >
            보기
          </PrivacyButton>
        </PrivacyArticle>
        <PrivacyArticle>
          <CheckLabel>
            <CheckText style={{ color: '#E50043', marginLeft: 5 }}>
              (필수)
            </CheckText>
            마케팅 목적 개인정보 수집 및 이용 동의
            <CheckInput
              type='checkbox'
              name='marketings'
              checked={allChecked.checked1}
              onChange={(e) => {
                handleSingleChecked(e.target.checked, '1');
              }}
            />
            <CheckMark />
          </CheckLabel>
          <PrivacyButton
            onClick={() => {
              isView(true);
              isNumber('2');
            }}
          >
            보기
          </PrivacyButton>
        </PrivacyArticle>
        <PrivacyArticle>
          <CheckLabel>
            <CheckText style={{ color: 'rgba(0,0,0,0.3)', marginLeft: 5 }}>
              (선택)
            </CheckText>
            프로모션 정보 수신 동의
            <CheckInput
              type='checkbox'
              name='agree'
              checked={allChecked.checked2}
              onChange={(e) => {
                handleSingleChecked(e.target.checked, '2');
              }}
            />
            <CheckMark />
          </CheckLabel>
          <PrivacyButton
            onClick={() => {
              isView(true);
              isNumber('3');
            }}
          >
            보기
          </PrivacyButton>
        </PrivacyArticle>
        {error === 'agree' ? (
          <ErrorText error={error}>
            ※ 개인정보 수집 및 이용 동의를 체크해주세요.
          </ErrorText>
        ) : (
          ''
        )}
      </PrivacyContainer>
      <SignupButton type='submit'>
        <ButtonText>확인</ButtonText>
      </SignupButton>
    </SignupWrapper>
  );
};

export default SignupForm;

import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../../actions/modals.actions';
import mobileCloseImg from '../../../../assets/closewhite.svg';
import axios from '../../../../contants/axios';

import {
  ArchiveContianer,
  TabStyle,
  MobileTabClose,
  MobileCloseButton,
} from './styled/ArchiveStyled';

import EnclosureBoard from './EnclosureBoard';
import CooperateBoard from './CooperateBoard';
import DistributionBoard from './DistributionBoard';
import ITBoard from './ITBoard';
import SolutionBoard from './SolutionBoard';

const ArchivePopup = () => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState({
    enclosure: [],
    cooperate: [],
    distribution: [],
    it: [],
    solution: [],
  });
  const userData = useSelector((state) => state.auth);

  useEffect(() => {
    if (loading) {
      const key = 'load';
      (async () => {
        message.loading({ content: '기술자료 로딩중...', key: key });
        try {
          const response = await axios({
            method: 'get',
            url: `/archives?_limit=0`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const datas = response.data;

          const enclosureData = await datas.filter((item) => {
            return item.category === '인클로저';
          });
          const cooperateData = await datas.filter((item) => {
            return item.category === '공조';
          });
          const distributionData = await datas.filter((item) => {
            return item.category === '배전';
          });
          const itData = await datas.filter((item) => {
            return item.category === 'IT 인프라';
          });
          const solutionData = await datas.filter((item) => {
            return item.category === '솔루션';
          });

          message.success({ content: '기술자료 로딩 성공', key: key });

          setLoading(false);

          return setList({
            enclosure: enclosureData,
            cooperate: cooperateData,
            distribution: distributionData,
            it: itData,
            solution: solutionData,
          });
        } catch (e) {
          message.error({
            content: '기술자료 로딩 실패',
            key: key,
          });
          setLoading(false);
        }
      })();
    }
  }, [loading, token]);

  const items = [
    {
      key: '1',
      label: '인클로저',
      children: (
        <EnclosureBoard
          data={list.enclosure}
          loading={loading}
          userData={userData}
        />
      ),
    },
    {
      key: '2',
      label: '공조',
      children: (
        <CooperateBoard
          data={list.cooperate}
          loading={loading}
          userData={userData}
        />
      ),
    },
    {
      key: '3',
      label: '배전',
      children: (
        <DistributionBoard
          data={list.distribution}
          loading={loading}
          userData={userData}
        />
      ),
    },
    {
      key: '4',
      label: 'IT 인프라',
      children: (
        <ITBoard data={list.it} loading={loading} userData={userData} />
      ),
    },
    {
      key: '5',
      label: '솔루션',
      children: (
        <SolutionBoard
          data={list.solution}
          loading={loading}
          userData={userData}
        />
      ),
    },
  ];

  return (
    <ArchiveContianer>
      <TabStyle tabPosition='left' defaultActiveKey='1' items={items} />
      <MobileTabClose className={'show'}>
        <MobileCloseButton
          onClick={() => dispatch(setModal({ type: '', stauts: 'hide' }))}
          img={mobileCloseImg}
        />
      </MobileTabClose>
    </ArchiveContianer>
  );
};

export default ArchivePopup;

import styled from 'styled-components';

const TourContainer = styled.div`
  position: relative;
  width: 50vw;
  height: 60vh;
  background: #fff;
  padding: 65px 80px;

  @media (max-width: 767px) {
    position: absolute;
    width: 100%;
    height: calc(100%);
    top: 0;
    padding: 30px 15px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  color: #000;
  background-image: url(${(props) => props.img});
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const Title = styled.span`
  font-size: 25px;
  line-height: 22px;
  color: #000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  border-bottom: 2px solid #000;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  margin-top: 35px;
  color: #000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  overflow: auto;
`;

const TourButton = styled.button`
  width: 100%;
  border: 0.5px solid #cbc9c9;
  background: #f8f8f8;
  padding: 14px 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #666666;
  margin-top: 30px;
  text-align: left;
  word-break: keep-all;

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: #fff;
    background: #e50043;
    border: 0.5px solid #fff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

const MobileTitle = styled.div`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 42px;
    background: #000;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }
`;

const MobileCloseButton = styled.button`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    display: block;
    top: 13px;
    right: 25px;
    width: 18px;
    height: 18px;
    color: #000;
    background-image: url(${(props) => props.img});
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

export {
  TourContainer,
  MobileTitle,
  MobileCloseButton,
  CloseButton,
  Title,
  Content,
  TourButton,
};

import styled from 'styled-components';

const PrivacyArticle = styled.div`
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;

  @media (max-width: 850px) {
    border-radius: 0;
  }
`;

const SignupTitle = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  background: #373737;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;

  @media (max-width: 850px) {
    border-radius: 0;
  }
`;

const PrivacyWrapper = styled.div`
  position: relative;
  width: 60vw;
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-family: Noto Sans KR;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);

  @media (max-width: 850px) {
    width: 100%;
    transition: 0.5s;
    padding: 30px;
  }
`;

const PrivacyTitle = styled.h3`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);
  margin: 15px 0px 0px 0px;
`;

const PrivacyContent = styled.div`
  width: 100%;
  padding: 10px;
  height: 200px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin: 5px 0px 15px 0px;
  font-size: 14px;
  line-height: 20px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  word-break: keep-all;
`;

const BackButton = styled.button`
  width: 100%;
  height: 40px;
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  color: #666;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-top: 20px;

  &:hover {
    background: #e7006b;
    color: #fff;
    border: 0;
    cursor: pointer;
  }
`;

const PrivacyLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    color: #e7006b;
    cursor: pointer;
  }

  &:active {
    color: #e7006b;
  }

  &:focus {
    color: #e7006b;
  }
`;

export {
  PrivacyArticle,
  SignupTitle,
  PrivacyWrapper,
  PrivacyTitle,
  PrivacyContent,
  BackButton,
  PrivacyLink,
};

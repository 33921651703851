import styled from 'styled-components';

const SeminarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    position: absolute;
    width: 100%;
    height: calc(100%);
    top: 0;
  }
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  height: 6%;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: overlay;

  @media (max-width: 767px) {
    padding: 0px 20px;
    height: 6%;
    margin: 5px 0px;
  }
`;

const TitleText = styled.span`
  width: 90%;
  font-size: 18px;
  color: rgb(0, 0, 0, 0.87);
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 14px;
    word-break: keep-all;
    word-wrap: break-word;
  }
`;

const TitleClose = styled.div`
  display: flex;
  align-items: center;
  color: #000;

  &:hover {
    cursor: pointer;
    color: #e50043;
  }
`;

const CloseButton = styled.img`
  width: 28px;
  height: 28px;
  color: #000;
  border: 0;

  &:hover {
    cursor: pointer;
    color: #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`;

const CloseText = styled.span`
  margin-right: 11px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 94%;
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
    height: 94%;
    background: #000;
  }
`;

const VideoArticle = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;
const AgendaArticle = styled.div`
  width: 100%;
  height: 25%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 25px;

  @media (max-width: 767px) {
    width: 100%;
    height: 25%;
    padding-right: 0;
  }
`;

export {
  SeminarContainer,
  CloseButton,
  CloseText,
  Title,
  TitleText,
  TitleClose,
  VideoArticle,
  Content,
  AgendaArticle,
};

import styled from 'styled-components';

const SubContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const TitleImage = styled.img`
  @media (max-width: 767px) {
    width: 40px;
    height: 45px;
  }
`;

const ContentTitle = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 36px;
  color: #000;
  margin-left: 22px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ContentSub = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #000;
  margin-top: 5px;
`;

const InputContent = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const NameInput = styled.input`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  border-radius: 2px;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const EmailArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
  color: #000;
`;

const EmailInput = styled.input`
  width: 100%;
  border-left: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-top: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-bottom: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-right: 0;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  background-color: ${(props) =>
    props.error === 'email' ? 'rgba(252, 201, 204, 0.21)' : ''};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const AtInput = styled.input`
  width: 15%;
  height: 40px;
  border-left: 0;
  border-top: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-bottom: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-right: 0;
  color: rgba(0, 0, 0, 0.38);
  background-color: ${(props) =>
    props.error === 'email' ? 'rgba(252, 201, 204, 0.21)' : ''};

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    cursor: initial;
  }

  @media (max-width: 1199px) {
    width: 20%;
  }

  @media (max-width: 767px) {
    width: 25%;
  }
`;

const EmailSelectInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  border-left: 0;
  border-top: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-bottom: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-right: ${(props) =>
    props.error === 'email' ? '0' : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'email' ? 'rgba(252, 201, 204, 0.21)' : ''};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const EmailSelectBox = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px;
  background-color: ${(props) =>
    props.error === 'email' ? 'rgba(252, 201, 204, 0.21)' : '#F6F6F6'};
  font-family: 'Noto Sans KR';
  border-left: ${(props) =>
    props.error === 'email' ? '0' : '1px solid rgba(0, 0, 0, 0.13)'};
  border-top: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-bottom: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-right: ${(props) =>
    props.error === 'email'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const PhoneArticle = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PhoneInput = styled.input`
  width: 100%;
  border: ${(props) =>
    props.error === 'phone'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'phone' ? 'rgba(252, 201, 204, 0.21)' : ''};
  box-sizing: border-box;
  border-radius: 2px;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PhoneText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${(props) => (props.error === 'phone' ? '#EC4C47' : '#666666')};
`;

const StampButton = styled.button`
  background-color: #f8f8f8;
  border: 1px solid #cbc9c9;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #666666;
  padding: 13px 24px;
  margin-top: 50px;

  &:hover {
    background-color: #e50043;
    color: #fff;
    cursor: pointer;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export {
  SubContainer,
  ContentArticle,
  TitleImage,
  ContentTitle,
  ContentSub,
  InputContent,
  NameInput,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectBox,
  EmailSelectInput,
  PhoneArticle,
  PhoneInput,
  PhoneText,
  StampButton,
};

import React from 'react';
import closeImg from '../../../assets/close.svg';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';
import { moveProduct } from '../../../actions/move.actions';
import airViewImg from '../../../assets/airView.png';
import { Tooltip } from 'antd';

import {
  InfoContainer,
  CloseButton,
  Title,
  Content,
  AirViewAritcle,
  AirViewImage,
  SmartButton,
  RasButton,
  ITButton,
  EnclosureButton,
  CooperateButton,
  DistributionButton,
  AirViewInfo,
} from './InfoStyled';

const Info = () => {
  const dispatch = useDispatch();

  const positionChange = () => {
    setTimeout(() => {
      dispatch(moveProduct(''));
    }, 100);

    dispatch(
      setModal({
        type: '',
        status: 'hide',
      }),
    );
  };

  return (
    <InfoContainer>
      <CloseButton
        onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
        img={closeImg}
      />
      <Title>전시관 정보</Title>
      <Content>
        <AirViewAritcle>
          <AirViewImage alt='airview' src={airViewImg} />
          <Tooltip
            title='데이터 센터'
            color={'#E50043'}
            style={{ fontSize: '16px' }}
          >
            <SmartButton
              onClick={() => {
                dispatch(moveProduct('데이터 센터'));
                positionChange();
              }}
            />
          </Tooltip>
          <Tooltip title='스마트 팩토리' color={'#E50043'}>
            <RasButton
              onClick={() => {
                dispatch(moveProduct('스마트 팩토리'));
                positionChange();
              }}
            />
          </Tooltip>
          <Tooltip title='IT 인프라' color={'#E50043'}>
            <ITButton
              onClick={() => {
                dispatch(moveProduct('IT 인프라'));
                positionChange();
              }}
            />
          </Tooltip>
          <Tooltip title='인클로저' color={'#E50043'}>
            <EnclosureButton
              onClick={() => {
                dispatch(moveProduct('인클로저'));
                positionChange();
              }}
            />
          </Tooltip>
          <Tooltip title='공조' color={'#E50043'}>
            <CooperateButton
              onClick={() => {
                dispatch(moveProduct('공조'));
                positionChange();
              }}
            />
          </Tooltip>
          <Tooltip title='배전' color={'#E50043'}>
            <DistributionButton
              onClick={() => {
                dispatch(moveProduct('배전'));
                positionChange();
              }}
            />
          </Tooltip>
        </AirViewAritcle>
        <AirViewInfo>* 클릭시 해당 위치로 바로 이동할수있습니다.</AirViewInfo>
      </Content>
    </InfoContainer>
  );
};

export default Info;

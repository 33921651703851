import {
  LOAD_PRODUCT,
  GET_PRODUCT,
  SAVE_PRODUCT,
  GET_PRODUCT_DETAILED,
} from '../actions/product.actions';

const initialState = {
  product: null,
  loading: false,
};

export function product(state = initialState, action) {
  switch (action.type) {
    case LOAD_PRODUCT:
      return {
        product: null,
        loading: true,
      };
    case GET_PRODUCT:
      return {
        product: action.product,
        loading: false,
      };
    default:
      return state;
  }
}

const listInitialState = {
  productlist: null,
};

export function productlist(state = listInitialState, action) {
  switch (action.type) {
    case SAVE_PRODUCT:
      return {
        productlist: action.productlist,
      };
    default:
      return state;
  }
}

const productInitialState = {
  loading: 'false',
  tourId: '',
  hoverId: '',
  type: '',
};

export function getProductDetailed(state = productInitialState, action) {
  switch (action.type) {
    case GET_PRODUCT_DETAILED:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

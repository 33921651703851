import React from 'react';
import styled from 'styled-components';
import viewicon from '../../../assets/header/viewicon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';

const AirViewBtn = styled.button`
  width: 100%;
  margin: 0px 10px;
  padding: 0;
  background-color: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
    color: #7006B;
  }

  &:active {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

const ViewImg = styled.img`
  position: relative;

  @media (max-width: 767px) {
    width: 15px;
    height: 30px;
  }
`;

const ViewButton = () => {
  const dispatch = useDispatch();
  const { type, status } = useSelector((state) => state.modals);

  return (
    <>
      {type === 'airView' && status === 'show' ? (
        <AirViewBtn
          onClick={() => {
            dispatch(setModal({ type: '', status: 'hide' }));
          }}
        >
          <ViewImg src={viewicon} alt='view' />
        </AirViewBtn>
      ) : (
        <AirViewBtn
          onClick={() => {
            dispatch(setModal({ type: 'airView', status: 'show' }));
          }}
        >
          <ViewImg src={viewicon} alt='view' />
        </AirViewBtn>
      )}
    </>
  );
};

export default ViewButton;

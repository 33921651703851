import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextLoop } from 'react-text-loop-next';
import axios from '../../contants/axios';
import { Menu } from 'antd';
import { setMusic } from '../../actions/setting.actions';

import kioskImg from '../../assets/mobileIcon/mobileKiosk.svg';
import messageImg from '../../assets/mobileIcon/mobileMessage.svg';
import viewImg from '../../assets/mobileIcon/mobileView.svg';
import listImg from '../../assets/mobileIcon/mobileList.svg';
import myinfoImg from '../../assets/header/profile-icon.svg';
import sound from '../../assets/header/Sound.svg';
import soundMute from '../../assets/header/Soundmute.svg';

import {
  setModal,
  setObjectBar,
  setChatModal,
} from '../../actions/modals.actions';

import {
  MobilePopupArticle,
  HeaderContainer,
  MobilePopupMessage,
  MobileArticle,
  MobileButton,
  MobileDropdownButton,
  MobileIcon,
  MobileSubTitle,
  MenuBtn,
  MobileDropdown,
  OnAirArticle,
} from './MobileHeaderStyled';

const MobileHeader = () => {
  const token = sessionStorage.getItem('token');
  const [text, setText] = useState([]);
  const dispatch = useDispatch();
  const { type } = useSelector((state) => state.modals);
  const { status } = useSelector((state) => state.objectBar);
  const { show } = useSelector((state) => state.chatModal);
  const { semina: seminaSwitch } = useSelector((state) => state.settings);
  const { music } = useSelector((state) => state.musicToggle);

  useEffect(() => {
    (async () => {
      const response = await axios({
        method: 'get',
        url: `/notices`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setText(response.data);
    })();
  }, [token]);

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    document.location.href = '/';
  };

  const menu = (
    <Menu theme='dark'>
      <Menu.Item key='0'>
        <MenuBtn
          onClick={() => {
            dispatch(setChatModal({ show: 'hide' }));
            dispatch(setObjectBar({ status: 'hide' }));
            dispatch(setModal({ type: 'myinfo', status: 'show' }));
          }}
        >
          내정보 관리
        </MenuBtn>
      </Menu.Item>
      <Menu.Item key='1'>
        <MenuBtn
          onClick={() => {
            handleLogout();
          }}
        >
          로그아웃
        </MenuBtn>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <MobilePopupArticle>
        <TextLoop interval={4000} className='text-loop'>
          <div>
            <MobilePopupMessage
              onClick={() => {
                dispatch(setChatModal({ show: 'hide' }));
                dispatch(setObjectBar({ status: 'hide' }));
                dispatch(setModal({ type: 'notice', status: 'show' }));
              }}
            >
              디지털 전시관에 오신것을 환영합니다.
            </MobilePopupMessage>
          </div>
          {text.map((data, index) => {
            return (
              <div key={index}>
                <MobilePopupMessage
                  key={index}
                  onClick={() => {
                    dispatch(setChatModal({ show: 'hide' }));
                    dispatch(setObjectBar({ status: 'hide' }));
                    dispatch(setModal({ type: 'notice', status: 'show' }));
                  }}
                >
                  {data.title}
                </MobilePopupMessage>
              </div>
            );
          })}
          <div>
            <MobilePopupMessage
              onClick={() => {
                dispatch(setChatModal({ show: 'hide' }));
                dispatch(setObjectBar({ status: 'hide' }));
                dispatch(setModal({ type: 'notice', status: 'show' }));
              }}
            >
              공지사항을 클릭하면 해당 팝업으로 이동합니다.
            </MobilePopupMessage>
          </div>
        </TextLoop>{' '}
      </MobilePopupArticle>
      {seminaSwitch ? (
        <OnAirArticle
          isView={seminaSwitch}
          onClick={() => {
            window.location.href = '/seminar_room';
          }}
        >
          ON AIR
        </OnAirArticle>
      ) : (
        <OnAirArticle isView={seminaSwitch}>ON AIR</OnAirArticle>
      )}
      <HeaderContainer id='mobile-header' modal={type}>
        <MobileArticle>
          {show === 'show' ? (
            <MobileButton
              onClick={() => {
                dispatch(setChatModal({ show: 'hide' }));
              }}
            >
              <MobileIcon src={messageImg} alt='message' />
              <MobileSubTitle>메세지</MobileSubTitle>
            </MobileButton>
          ) : (
            <MobileButton
              onClick={() => {
                dispatch(setChatModal({ show: 'show' }));
                dispatch(setModal({ type: '', status: '' }));
                dispatch(setObjectBar({ status: 'hide' }));
              }}
            >
              <MobileIcon src={messageImg} alt='message' />
              <MobileSubTitle>메세지</MobileSubTitle>
            </MobileButton>
          )}
        </MobileArticle>
        <MobileArticle>
          {music ? (
            <MobileButton
              onClick={() => {
                dispatch(setMusic({ music: false }));
                const toggle = document.getElementById('musicToggle');
                toggle.click();
              }}
            >
              <MobileIcon src={sound} alt='mute' />
              <MobileSubTitle>음악OFF</MobileSubTitle>
            </MobileButton>
          ) : (
            <MobileButton
              onClick={() => {
                dispatch(setMusic({ music: true }));
                const toggle = document.getElementById('musicToggle');
                toggle.click();
              }}
            >
              <MobileIcon src={soundMute} alt='listen' />
              <MobileSubTitle>음악ON</MobileSubTitle>
            </MobileButton>
          )}
        </MobileArticle>
        <MobileDropdown overlay={menu} trigger={['click']}>
          <MobileDropdownButton>
            <MobileIcon src={myinfoImg} alt='myinfo' />
            <MobileSubTitle>내정보</MobileSubTitle>
          </MobileDropdownButton>
        </MobileDropdown>
        <MobileArticle>
          <MobileButton
            onClick={() => {
              dispatch(setChatModal({ show: 'hide' }));
              dispatch(setObjectBar({ status: 'hide' }));
              dispatch(setModal({ type: 'kiosk', status: 'show' }));
            }}
          >
            <MobileIcon src={kioskImg} alt='kiosk' />
            <MobileSubTitle>키오스크</MobileSubTitle>
          </MobileButton>
        </MobileArticle>
        <MobileArticle>
          <MobileButton
            onClick={() => {
              dispatch(setChatModal({ show: 'hide' }));
              dispatch(setObjectBar({ status: 'hide' }));
              dispatch(setModal({ type: 'airView', status: 'show' }));
            }}
          >
            <MobileIcon src={viewImg} alt='view' />
            <MobileSubTitle style={{ fontSize: 8 }}>전시관정보</MobileSubTitle>
          </MobileButton>
        </MobileArticle>
        <MobileArticle>
          {status === 'show' ? (
            <MobileButton
              onClick={() => {
                dispatch(setObjectBar({ status: 'hide' }));
              }}
            >
              <MobileIcon src={listImg} alt='list' />
              <MobileSubTitle style={{ fontSize: 8 }}>
                제품리스트
              </MobileSubTitle>
            </MobileButton>
          ) : (
            <MobileButton
              onClick={() => {
                dispatch(setObjectBar({ status: 'show' })); // 오브젝트바 열기
                dispatch(setModal({ type: '', status: 'hide' })); // 다른 모달 닫기
                dispatch(setChatModal({ show: 'hide' })); // 채팅 닫기
              }}
            >
              <MobileIcon src={listImg} alt='list' />
              <MobileSubTitle style={{ fontSize: 8 }}>
                제품리스트
              </MobileSubTitle>
            </MobileButton>
          )}
        </MobileArticle>
      </HeaderContainer>
    </>
  );
};

export default MobileHeader;

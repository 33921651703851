import styled from 'styled-components';
import stampdefault from '../../../../assets/stampdefault.svg';
import stamphover from '../../../../assets/stampmini.svg';

const ObejctInfoContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  color: #000;
  height: 100%;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;

const ContentArticle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 85%;
  padding: 20px 25px;

  @media (max-width: 767px) {
    height: 85%;
    padding: 15px;
  }
`;

const TitleArticle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
`;

const StampCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.stamp > -1 ? '#E50043' : '#d3d3d3')};
  border: none;
  border-radius: 100px;
  height: 20px;
  padding: 5px 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 10px;

  @media (max-width: 1200px) {
    margin-left: 5px;
    height: 18px;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    height: 18px;
    font-size: 12px;
  }
`;

const ObjectTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #000;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  word-break: keep-all;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  max-height: inherit;
  height: 90%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  white-space: pre-wrap;
  overflow: auto;
  word-break: keep-all;
  word-wrap: break-word;

  @media (max-width: 767px) {
    font-size: 13px;
    height: 90%;
  }
`;

const FooterStampArticle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 10px;
`;

const FooterStampDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const FooterStampButton = styled.button`
  position: relative;
  right: 10px;
  border: none;
  background: #f8f8f8;
  padding: 5px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-image: url(${stampdefault});
  border: 1px solid #cbc9c9;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    background: #e50043;
    background-image: url(${stamphover});
    background-position: center;
    background-repeat: no-repeat;
    border: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const FooterStampPopup = styled.div`
  display: block;
  position: absolute;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  color: #fff;
  background: #e50043;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  justfiy-content: center;
  align-items: center;
  right: 10px;
  bottom: 110%;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);

  &:after {
    content: '';
    border: 1em solid transparent;
    border-top-color: #e50043;
    position: absolute;
    top: 80%;
    right: 3%;
    width: 0;
    height: 0;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Tooltip = styled.img`
  margin-right: 10px;
`;

const InformationText = styled.div`
  display: inline-block;
  color: #ec4c47;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  text-align: right;
  margin-top: 12px;
`;

export {
  ObejctInfoContainer,
  ContentArticle,
  TitleArticle,
  ObjectTitle,
  StampCheck,
  Content,
  FooterStampArticle,
  FooterStampPopup,
  Tooltip,
  FooterStampDiv,
  FooterStampButton,
  InformationText,
};

// kiosk
export const SET_MODAL = 'SET_MODAL';

export const setModal = (action) => ({
  type: SET_MODAL,
  payload: action,
});

// object Bar
export const SET_OBJECT_BAR = 'SET_OBJECT_BAR';

export const setObjectBar = (action) => ({
  type: SET_OBJECT_BAR,
  payload: action,
});

// chat
export const SET_CHAT_MODAL = 'SET_CHAT_MODAL';

export const setChatModal = (action) => ({
  type: SET_CHAT_MODAL,
  payload: action,
});

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';
import { popupHistory } from '../../../contants/history';

import { ObjectTitle, TitleText, TitleButton } from './styled/HeaderStyled';

const Header = ({ title, productName }) => {
  const dispatch = useDispatch();
  const openDate = new Date();
  const { music } = useSelector((state) => state.musicToggle);
  const userData = useSelector((state) => state.auth);

  return (
    <ObjectTitle>
      <TitleText>{title}</TitleText>
      <TitleButton
        onClick={() => {
          popupHistory(userData, productName, 'close');
          dispatch(setModal({ type: '', status: 'hide' }));
          const closeDate = new Date();
          const interval = closeDate - openDate;
          const minute = Math.floor(
            (interval % (1000 * 60 * 60)) / (1000 * 60),
          );
          const second = Math.floor((interval % (1000 * 60)) / 1000);
          //eslint-disable-next-line no-undef
          gtag('event', 'object_interaction_time', {
            event_category: `interaction_time`,
            event_label: `${productName}|${minute}분|${second}초`,
          });
          if (music) {
            const toggle = document.getElementById('musicToggle');
            toggle.click();
          }
        }}
      >
        X
      </TitleButton>
    </ObjectTitle>
  );
};

export default Header;

import styled from 'styled-components';

const ObjectTitle = styled.div`
  min-height: 65px;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const TitleText = styled.span`
  width: 100%;
  color: #fff;
  font-fmaily: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  margin-left: 20px;
`;

const TitleButton = styled.button`
  color: #fff;
  border: none;
  font-fmaily: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  background: none;

  &:hover {
    color: #e50043;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    color: #e50043;
    outline: none;
    box-shadow: none;
  }
`;

export { ObjectTitle, TitleText, TitleButton };

import styled from 'styled-components';

const ObjectDetailContainer = styled.div`
  position: absolute;
  padding: 25px;
  background: rgba(0, 0, 0, 0.8);
  width: 480px;
  height: auto;
  z-index: 995;
  right: 5%;
  top: 10%;
  opacity: ${(props) => (props.loading === 'true' ? '100%' : '0%')};
  display: flex;
  flex-direction: column;
  word-break: keep-all;
  -webkit-transition: opacity 0.25s, -webkit-transform 0.25s;
  transition: opacity 0.25s, transform 0.25s;
  transition-timing-function: ease;

  @media (max-width: 767px) {
    width: 280px;
  }
`;

const DetailTitle = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;
  color: #ffffff;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  word-break: keep-all;

  @media (max-width: 767px) {
    font-size: 20px;
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const DetailArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DetailContent = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 15px;

  @media (max-width: 767px) {
    font-size: 12px;
    margin-top: 10px;
  }
`;

const DetailSub = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ffffff;
  margin-top: 15px;
`;

export {
  ObjectDetailContainer,
  DetailArticle,
  DetailTitle,
  DetailContent,
  DetailSub,
};

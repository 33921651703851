import React from 'react';
import Header from './Header';
import Footer from './Footer';
import tooltip from '../../../assets/tooltip.svg';
import { useSelector } from 'react-redux';

import {
  ObejctInfoContainer,
  ContentArticle,
  TitleArticle,
  ObjectTitle,
  StampCheck,
  Content,
  FooterStampArticle,
  FooterStampPopup,
  Tooltip,
  FooterStampDiv,
  FooterStampButton,
  InformationText,
} from './styled/ObjectInfoStyled';

const ObjectInfo = ({ object, setCurrent, stampList, tabChange }) => {
  const stampCheck = stampList.indexOf(object.productName);
  const { stamp: stampSwitch } = useSelector((state) => state.settings);

  return (
    <>
      <ObejctInfoContainer>
        <Header title={'제품 정보'} productName={object.productName} />
        <ContentArticle>
          {object !== undefined && object !== '' ? (
            <>
              <TitleArticle>
                <ObjectTitle>{object.productName}</ObjectTitle>
                {object.productStamp === true ? (
                  <StampCheck stamp={stampCheck}>스탬프</StampCheck>
                ) : (
                  ''
                )}
              </TitleArticle>
              <Content>
                {object.productInfo}
                {object.productStamp === true &&
                stampCheck === -1 &&
                stampSwitch === true ? (
                  <FooterStampArticle>
                    <FooterStampPopup>
                      <Tooltip src={tooltip} alt='' />
                      도장찍기 잊지마세요!
                    </FooterStampPopup>
                    <FooterStampDiv>
                      <FooterStampButton
                        onClick={() => setCurrent(1)}
                      ></FooterStampButton>
                    </FooterStampDiv>
                    <InformationText>
                      ※ 스탬프 미션이 있는 제품의 도장 찍기를 모두 완료하시면,
                      <br />
                      이벤트 상품을 드립니다.
                    </InformationText>
                  </FooterStampArticle>
                ) : (
                  ''
                )}
              </Content>
              <Footer
                buy={object.productLink}
                brochure={object.productFile}
                tabChange={tabChange}
                object_id={object.id}
                productName={object.productName}
                category={object.category2}
              />
            </>
          ) : (
            <div>
              데이터 로딩에 실패했습니다. <br /> 다시 시도해주세요.
            </div>
          )}
        </ContentArticle>
      </ObejctInfoContainer>
    </>
  );
};

export default ObjectInfo;

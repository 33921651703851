import React from 'react';
import styled from 'styled-components';

const ClosePopupContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1003;
  margin: 0;
  padding: 0;
  backdrop-filter: blur(5.43656px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClosePopupArticle = styled.div`
  position: relative;
  width: 35%;
  height: 40vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: auto;
  padding: 65px 85px;

  @media (max-width: 1770px) {
    width: 40%;
  }

  @media (max-width: 1550px) {
    width: 45%;
  }

  @media (max-width: 1375px) {
    width: 50%;
  }

  @media (max-width: 1240px) {
    width: 55%;
  }

  @media (max-width: 1125px) {
    width: 60%;
  }

  @media (max-width: 1030px) {
    width: 65%;
  }

  @media (max-width: 767px) {
    width: 80%;
    height: 60%;
    padding: 55px 40px;
  }
`;

const Title = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;
  color: #000;
`;

const SubTitle = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #000;
  margin-bottom: 12px;
`;

const Content = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 45px;
  word-break: keep-all;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SaveButton = styled.button`
  background: #e50043;
  border: 1px solid #e50043;
  width: 149px;
  height: 40px;
  color: #ffffff;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-right: 10px;

  &:hover {
    background: #ff2288;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const CancelButton = styled.button`
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  width: 149px;
  height: 40px;
  color: #666666;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-right: 10px;

  &:hover {
    background: #c6c6c6;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const SeminarClose = ({ handleIsView, handleClose }) => {
  return (
    <ClosePopupContainer>
      <ClosePopupArticle>
        <div
          style={{
            marginBottom: 47,
            position: 'relative',
            width: '100%',
            height: 'auto',
          }}
        >
          <Title style={{ borderBottom: '2px solid #000', paddingBottom: 12 }}>
            세미나실
          </Title>
          <Title> 나가기</Title>
        </div>
        <SubTitle>퇴장하시겠습니까? </SubTitle>
        <Content>
          리탈/ 이플랜의 제품들이 여러분을 기다리고 있습니다. 가기전에 둘러봐
          주세요!
        </Content>
        <ButtonWrapper>
          <SaveButton
            onClick={() => {
              handleClose();
            }}
          >
            확인
          </SaveButton>
          <CancelButton
            onClick={() => {
              handleIsView(false);
            }}
          >
            취소
          </CancelButton>
        </ButtonWrapper>
      </ClosePopupArticle>
    </ClosePopupContainer>
  );
};

export default SeminarClose;

import styled from 'styled-components';

const MyInfoContainer = styled.div`
  position: relative;
  width: 50vw;
  height: ${(props) => (props.part === 2 ? 'auto' : '80vh')};
  background: #fff;
  padding: 40px 70px;

  @media (max-width: 767px) {
    position: absolute;
    width: 100%;
    height: calc(100%);
    top: 0;
    padding: 30px 15px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  color: #000;
  background-image: url(${(props) => props.img});
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const Title = styled.span`
  font-size: 25px;
  line-height: 22px;
  color: #000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  border-bottom: 2px solid #000;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Content = styled.form`
  width: 100%;
  height: 90%;
  margin-top: 25px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    height: calc(100% - 80px);
  }
`;

const MobileTitle = styled.div`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 42px;
    background: #000;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }
`;

const MobileCloseButton = styled.button`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    display: block;
    top: 13px;
    right: 25px;
    width: 18px;
    height: 18px;
    color: #000;
    background-image: url(${(props) => props.img});
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const ContentArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;

  @media(max-width: 767px) {
    flex-direction: column;
  })
`;

const ContentTitle = styled.span`
  width: 40%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 13px;
  }
`;

const ContentInput = styled.input`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
`;

const ContentSelect = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PhoneArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
`;

const PhoneSelect = styled.select`
  width: 20%;
  height: 40px;
  padding: 5px 10px;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 0;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border-radius: 2px;

  @media (max-width: 767px) {
    width: 40%;
  }

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PhoneInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const EmailArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
`;

const EmailInput = styled.input`
  width: 100%;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 0;
  height: 40px;
  padding: 5px 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border-radius: 2px;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const AtInput = styled.input`
  width: 15%;
  height: 40px;
  border-left: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 0;
  color: rgba(0, 0, 0, 0.38);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border-radius: 2px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    cursor: initial;
  }

  @media (max-width: 1199px) {
    width: 20%;
  }

  @media (max-width: 767px) {
    width: 25%;
  }
`;

const EmailSelectInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border-left: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border-radius: 2px;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const EmailSelectBox = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border-radius: 2px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const SecessionWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: right;
  margin-bottom: 28px;

  @media (max-width: 767px) {
    text-align: left;
  }
`;

const SecessionLink = styled.span`
  position: relative;
  width: 89px;
  height: 20px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.65);
  clear: both;

  &:hover {
    color: #e50043;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SaveButton = styled.button`
  background: #e50043;
  border: none;
  width: 160px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-right: 10px;

  &:hover {
    background: #ff2288;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const SubText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 11px;
`;

export {
  MyInfoContainer,
  CloseButton,
  Title,
  Content,
  MobileTitle,
  MobileCloseButton,
  ContentArticle,
  ContentTitle,
  ContentInput,
  ContentSelect,
  PhoneArticle,
  PhoneInput,
  PhoneSelect,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectInput,
  EmailSelectBox,
  SecessionWrapper,
  SecessionLink,
  ButtonWrapper,
  SaveButton,
  SubText,
};

import React, { useEffect, useState } from 'react';

import { TimeStamp, AgendaData, AgendaContainer } from './AgendaStyled';

const Agenda = ({ agendaContent }) => {
  const [agendaData, setAgendaData] = useState([
    {
      timeStamp: '',
      text: '',
    },
  ]);

  useEffect(() => {
    if (agendaContent.length) {
      const agendaJSON = JSON.parse(agendaContent);
      const agendaSorting = agendaJSON.sort((a, b) => a.sequence - b.sequence);
      setAgendaData(agendaSorting);
    } else {
      setAgendaData([
        {
          timeStamp: '',
          text: '',
        },
      ]);
    }
  }, [agendaContent]);

  return (
    <>
      {agendaData.map((data, index) => {
        return (
          <AgendaContainer key={index}>
            <TimeStamp>{data.timeStamp}</TimeStamp>
            <AgendaData>{data.text}</AgendaData>
          </AgendaContainer>
        );
      })}
    </>
  );
};

export default Agenda;

import styled from 'styled-components';

const FooterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  height: 40px;
`;

const OnlineButton = styled.button`
  background: #e50043;
  border: none;
  width: 100%;
  color: #fff;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 10px 5px;

  &:hover {
    background: #ff2288;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const DownloadButton = styled.button`
  margin-left: 10px;
  width: 100%;
  border: 1px solid #cbc9c9;
  background: #f8f8f8;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #666666;
  padding: 10px 5px;

  &:hover {
    cursor: pointer;
    background: #c6c6c6;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export { FooterContainer, OnlineButton, DownloadButton };

import styled from 'styled-components';

const ChatContainer = styled.div`
  width: 30%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    width: 100%;
    height: 55%;
  }
`;

const ChatPopupTitle = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const PopupTitle = styled.span`
  position: relative;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
`;

const ChatArticle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  padding: 20px;

  @media (max-width: 767px) {
    padding: 15px;
    height: 100%;
  }
`;

// 채팅 시간 표시
const DateTitle = styled.span`
  position: relative;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
  text-align: center;
  margin-bottom: 15px;
`;

// ChatPopup Footer
const SendArticle = styled.form`
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  padding: 13px 20px;
  border-top: 2px solid #cbc9c9;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

// 메시지 입력 input
const SendInpit = styled.input`
  position: relative;
  width: 100%;
  border: 0;
  color: #000;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

// 전송 버튼
const SendButton = styled.button`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 3px;
  color: #fff;
  border: 0;
  background: #000;
  width: 55px;
  height: 30px;
  padding: 5px 11px;
  margin-left: 21px;

  &:hover {
    cursor: pointer;
    background: #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export {
  ChatContainer,
  ChatPopupTitle,
  PopupTitle,
  ChatArticle,
  DateTitle,
  SendArticle,
  SendInpit,
  SendButton,
};

import React from 'react';
import styled from 'styled-components';

import { InfoText, PasswordButton, ButtonText } from './PasswordStyled';

const PasswordWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px;
  text-align: center;

  @media (max-width: 797px) {
    padding: 25px;
    width: 100%;
  }
`;

const ConfirmPasswordForm = ({ setPage }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    return setPage(2);
  };

  return (
    <PasswordWrapper onSubmit={handleSubmit} autoComplete='off'>
      <InfoText>변경된 정보로 재로그인 해주세요.</InfoText>
      <PasswordButton type='submit'>
        <ButtonText>확인</ButtonText>
      </PasswordButton>
    </PasswordWrapper>
  );
};

export default ConfirmPasswordForm;

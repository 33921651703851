import styled from 'styled-components';

const WebKioskContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: ${(props) =>
    props.current >= 3 ? 'rgba(0, 0, 0, 0.7)' : '#000'};

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileKioskContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: ${(props) =>
    props.current >= 3 ? 'rgba(0, 0, 0, 0.7)' : '#000'};

  @media (max-width: 767px) {
    display: flex;
  }
`;

const KioskImageArticle = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const KisokPopUpMessage = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  color: #fff;
  background: #e50043;
  padding: 10px 12px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justfiy-content: center;
  align-items: center;

  &:after {
    content: '';
    border: 1em solid transparent;
    border-top-color: #e50043;
    margin-left: -1em;
    position: absolute;
    top: 80%;
    left: 50%;
    width: 0;
    height: 0;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const Tooltip = styled.img`
  margin-right: 10px;
`;

const MockupKiosk = styled.img`
  position: relative;
  width: 200px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const TutorialImg = styled.img`
  position: relative;
  width: 100%;
  height: inherit;
`;

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonArticle = styled.div`
  position: relative;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrevButton = styled.span`
  text-align: center;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 22px;
  color: #000;
  background-color: #fff;
  vertical-align: middle;
  line-height: 48px;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: #e50043;
    color: #fff;
  }
`;

const NextButton = styled.span`
  position: relative;
  text-align: center;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 22px;
  color: #000;
  background-color: #fff;
  vertical-align: middle;
  line-height: 48px;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: #e50043;
    background-color: #e50043;
    color: #fff;
  }
`;

const MobilePrevButton = styled.span`
  width: 10%;
  text-align: left;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;
  color: #ffffff;

  &:hover {
    cursor: pointer;
    color: #e50043;
  }

  @media (max-width: 767px) {
    width: 20%;
    font-size: 14px;
    line-height: 20px;
  }
`;

const MobileNextButton = styled.span`
  width: 80%;
  text-align: right;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;
  color: #ffffff;

  &:hover {
    cursor: pointer;
    color: #e50043;
  }

  @media (max-width: 767px) {
    width: 20%;
    font-size: 14px;
    line-height: 20px;
  }
`;

const MockupContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export {
  WebKioskContainer,
  TutorialImg,
  ButtonContainer,
  PrevButton,
  NextButton,
  KisokPopUpMessage,
  Tooltip,
  MockupKiosk,
  ButtonArticle,
  MobileKioskContainer,
  KioskImageArticle,
  MobilePrevButton,
  MobileNextButton,
  MockupContainer,
};

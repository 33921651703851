import { SET_AUTH } from '../actions/auth.actions';

const initialState = {
  token: null,
  id: null,
  username: '',
  email: '',
  company: '',
  department: '',
  duty: '',
  industry: '',
  phone: '',
  tutorial: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

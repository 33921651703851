import styled from 'styled-components';
import { Row, Col } from 'antd';

const KioskContainer = styled.div`
  margin: auto;
  position: absolute;
  max-width: 670px;
  min-width: 670px;
  height: 90%;
  background: #000;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 25px 25px 0px 25px;
  transition: all 0.5s ease-in-out;

  @media (min-height: 850px) {
    height: 80%;
  }

  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    padding: 0;
    min-width: auto;
    height: 100%;
    flex-direction: column;
  }
`;

const KioskDisplay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 25px 25px 0px 25px;
  overflow: auto;

  @media (max-width: 1120px) {
    padding: 20px;
  }

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: 767px) {
    padding: 10px;
  }

  @media (max-width: 375px) {
    padding: 0;
  }

  @media (max-height: 950px) {
    padding: 10px 10px 0px 10px;
  }
`;

const MenuButton = styled.button`
  width: 100%;
  height: 85px;
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  border-radius: 5px;
  margin-bottom: 15px;

  &:hover {
    cursor: pointer;
    background: #c6c6c6;
  }

  @media (max-width: 767px) {
    height: 80px;
    margin-bottom: 5px;
  }

  @media (max-width: 320px) {
    height: 70px;
    margin-bottom: 5px;
  }
`;

const MenuOffButton = styled.button`
  width: 100%;
  height: 85px;
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  border-radius: 5px;
  margin-bottom: 15px;

  &:hover {
    cursor: default;
  }

  @media (max-width: 767px) {
    height: 80px;
    margin-bottom: 5px;
  }

  @media (max-width: 320px) {
    height: 70px;
    margin-bottom: 5px;
  }
`;

const MenuTitle = styled.span`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #666666;

  @media (max-width: 1120px) {
    font-size: 16px;
    line-height: 20px;
    color: #000;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    color: #000;
  }
`;

const MenuIcon = styled.img`
  width: 30%;
`;

const CloseButton = styled.button`
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  color: #666666;
  width: 200px;
  height: 55px;
  margin-top: 15px;

  &:hover {
    background: #e50043;
    color: #fff;
    cursor: pointer;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1024px) {
    font-size: 15px;
    width: 180px;
    height: 42px;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    font-size: 15px;
    width: 166px;
    height: 42px;
    margin-top: 0;
  }
`;

const MobileKioskClose = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    position: relative;
    width: 100%;
    height: 45px;
    background: 000;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    justify-content: center;
    align-items: center;
  }
`;

const Rows = styled(Row)`
  padding: 5px;

  @media (max-width: 1120px) {
    padding: 5px 15px 0px 15px !important;
  }

  @media (max-width: 767px) {
    padding: 10px 15px 10px 15px !important;
  }

  @media (max-width: 375px) {
    padding: 10px 15px 10px 15px !important;
  }

  @media (max-width: 320px) {
    padding: 10px 15px 0px 15px !important;
  }
`;

const Cols = styled(Col)`
  padding: 10px 40px;

  @media (max-width: 1120px) {
    padding: 5px 20px !important;
  }

  @media (max-width: 767px) {
    padding: 0px 15px !important;
  }

  @media (max-width: 375px) {
    padding: 0px 10px !important;
  }

  @media (max-height: 950px) {
    padding: 0px 40px;
  }
`;

const KioskLogo = styled.img`
  width: auto;
  height: 110px;
  margin-bottom: 5px;

  @media (max-width: 767px) {
    width: auto;
    height: 60px;
    margin-bottom: 0;
  }
`;

export {
  KioskContainer,
  MobileKioskClose,
  KioskDisplay,
  Rows,
  Cols,
  KioskLogo,
  MenuButton,
  MenuIcon,
  MenuTitle,
  CloseButton,
  MenuOffButton,
};

import styled from 'styled-components';

const PasswordWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px;

  @media (max-width: 797px) {
    width: 100%;
    padding: 25px;
  }
`;

const BackLink = styled.span`
  color: #000;
  font-size: 12px;
  font-family: Noto Sans KR;
  font-weight: bold;
  font-style: normal;
  line-height: 22px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #e50043;
    cursor: pointer;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 8px 6px 0;
    border-color: transparent #e50043 transparent transparent;
    margin-right: 8px;
  }
`;

const InfoText = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
  width: 100%;

  @media (max-width: 767px) {
    font-size: 13px;
  }
`;
const CodeInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  border: ${(props) =>
    props.error === 'name'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'name' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-radius: 2px;

  margin-bottom: ${(props) => (props.error === 'name' ? '0' : '12px')};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const EmailArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 10px;
  justify-content: center;
  color: #000;
`;

const EmailInput = styled.input`
  width: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 0;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const AtInput = styled.input`
  width: 15%;
  height: 40px;
  border-left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 0;
  color: rgba(0, 0, 0, 0.38);

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    cursor: initial;
  }

  @media (max-width: 1199px) {
    width: 20%;
  }

  @media (max-width: 767px) {
    width: 25%;
  }
`;

const EmailSelectInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  border-left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 0;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const EmailSelectBox = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px;
  background-color: #fafafa;
  font-family: 'Noto Sans KR';
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 1px solid rgba(0, 0, 0, 0.13);
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const PasswordInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  border: ${(props) =>
    props.error === 'password'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'password' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-radius: 2px;
  margin-bottom: ${(props) => (props.error === 'password' ? '0' : '12px')};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PasswordCheckInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  border: ${(props) =>
    props.error === 'passwordCheck'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'passwordCheck' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-radius: 2px;
  margin-bottom: ${(props) => (props.error === 'passwordCheck' ? '0' : '12px')};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PasswordButton = styled.button`
  width: 100%;
  background: #eee;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  border: 0;
  color: #5a5a5a;
  height: auto;
  margin-top: 25px;
  padding: 15px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;

  &:hover {
    color: #fff;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    background: #e50043;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &:hover:after {
    left: 0;
  }
`;

const ButtonText = styled.span`
  position: relative;
  z-index: 1;
`;

const ErrorText = styled.div`
  display: ${(props) => (props.error !== '' ? 'flex' : 'none')};
  color: #ec4c47;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 12px;
`;

export {
  PasswordWrapper,
  BackLink,
  InfoText,
  CodeInput,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectInput,
  EmailSelectBox,
  PasswordInput,
  PasswordCheckInput,
  PasswordButton,
  ButtonText,
  ErrorText,
};

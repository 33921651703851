import axios from './axios';

export const sendProductHistory = async (data, productName) => {
  const zipidaEmail = data.email.split('@') || '';
  if (zipidaEmail[1] === 'zipida.com' || data.email === 'mkt@rittal.co.kr')
    return;

  return await axios
    .post('/product-histories', {
      name: data.username,
      email: data.email,
      company: data.company,
      phone: data.phone,
      department: data.department,
      industry: data.industry,
      duty: data.duty,
      path: data.path,
      productName: productName,
      accountCreateAt: data.createdAt,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const sendUserHistory = async (data, type) => {
  const zipidaEmail = data.email.split('@') || '';
  if (zipidaEmail[1] === 'zipida.com' || data.email === 'mkt@rittal.co.kr')
    return;

  return await axios
    .post('/user-histories', {
      name: data.username,
      email: data.email,
      company: data.company,
      department: data.department,
      duty: data.duty,
      industry: data.industry,
      phone: data.phone,
      path: data.path,
      accountCreateAt: data.createdAt,
      type,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const sendFileHistory = async (
  data,
  productName,
  fileName,
  isArchive,
) => {
  const zipidaEmail = data.email.split('@') || '';
  if (zipidaEmail[1] === 'zipida.com' || data.email === 'mkt@rittal.co.kr')
    return;

  return await axios
    .post('/file-histories', {
      name: data.username,
      email: data.email,
      company: data.company,
      phone: data.phone,
      department: data.department,
      industry: data.industry,
      duty: data.duty,
      path: data.path,
      product: productName,
      fileName: fileName,
      isArchive: isArchive,
      accountCreateAt: data.createdAt,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const popupHistory = async (data, productName, type) => {
  const zipidaEmail = data.email.split('@') || '';
  if (zipidaEmail[1] === 'zipida.com' || data.email === 'mkt@rittal.co.kr')
    return;

  return await axios
    .post('/popup-histories', {
      name: data.username,
      email: data.email,
      company: data.company,
      phone: data.phone,
      department: data.department,
      industry: data.industry,
      duty: data.duty,
      path: data.path,
      productName: productName,
      type: type,
      accountCreateAt: data.createdAt,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

import React from 'react';
import { message } from 'antd';

import {
  MobileTitle,
  Title,
  Content,
  SubText,
  TableArticle,
  TableContent,
  TableWrapper,
  MainText,
  TextTitle,
  TextContent,
  SubText2,
  CheckLabel,
  CheckInput,
  CheckMark,
  ButtonWrapper,
  SaveButton,
  CancelButton,
} from './styled/SecessionStyled';

const Secession = ({ setPart }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const { agree } = event.target;

    if (agree.checked === false) {
      message.error('회원탈퇴 동의에 체크해주세요.');
    } else {
      setPart(2);
    }
  };

  return (
    <>
      <MobileTitle>회원 탈퇴</MobileTitle>
      <Title>회원 탈퇴</Title>
      <Content onSubmit={handleSubmit}>
        <SubText>
          회원탈퇴를 하기 전에 아래의 안내 사항을 꼭 확인해주세요.
        </SubText>
        <TableArticle>
          <MainText>
            탈퇴 후 회원정보 및 개인형 서비스 이용기록은 모두 삭제됩니다.
          </MainText>
          <SubText>
            회원정보 및 메일, 블로그, 주소록 등 개인형 서비스 이용기록은 모두
            삭제되며, 삭제된 데이터는 복구되지 않습니다.{' '}
          </SubText>
          <TableWrapper>
            <TableContent>
              <TextTitle>이름</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원가입 시 작성한 성명
              </TextContent>
            </TableContent>
            <TableContent>
              <TextTitle>이메일 주소</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원 가입 시 작성한 이메일 주소
              </TextContent>
            </TableContent>
            <TableContent>
              <TextTitle>회사명</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원 가입 시 작성한 회사명
              </TextContent>
            </TableContent>
            <TableContent>
              <TextTitle>연락처</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원 가입 시 작성한 연락처
              </TextContent>
            </TableContent>
            <TableContent>
              <TextTitle>소속부서</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원 가입 시 작성한 소속 부서
              </TextContent>
            </TableContent>
            <TableContent>
              <TextTitle>직무</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원 가입 시 작성한 직무
              </TextContent>
            </TableContent>
            <TableContent>
              <TextTitle>산업</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원 가입 시 작성한 산업
              </TextContent>
            </TableContent>
            <TableContent>
              <TextTitle>알게 된 경로</TextTitle>
              <TextContent>
                전시관 입장을 위한 회원 가입 시 작성한 알게된 경로
              </TextContent>
            </TableContent>
          </TableWrapper>
        </TableArticle>
        <SubText2>
          탈퇴 후에도 게시판 또는 댓글형 서비스에 등록한 게시물은 그대로
          남아있습니다.
        </SubText2>
        <SubText2>
          탈퇴 후에도 전시관을 재방문하시기 위해서는 다시 회원가입 절차를 완료
          하셔야 합니다.
        </SubText2>
        <SubText2>
          탈퇴 시 스탬프 미션을 통해 획득한 스탬프를 삭제합니다.
        </SubText2>
        <SubText2>
          이벤트 응모 후 당첨 보상을 획득하기 위해서는, 회원가입 상태가
          유지되어야 합니다.
        </SubText2>
        <CheckLabel>
          해당 내용을 모두 확인했으며, 회원탈퇴에 동의합니다.
          <CheckInput type='checkbox' name='agree' />
          <CheckMark />
        </CheckLabel>
        <ButtonWrapper>
          <SaveButton type='submit'>확인</SaveButton>
          <CancelButton onClick={() => setPart(0)}>취소</CancelButton>
        </ButtonWrapper>
      </Content>
    </>
  );
};

export default Secession;

import React from 'react';
import styled from 'styled-components';
import ChatImg from '../../../assets/header/message.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setChatModal } from '../../../actions/modals.actions';

const ChatArticle = styled.div`
  min-width: 65px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;

  &:hover {
    background-color: #e50043;
  }

  @media (max-width: 767px) {
    min-width: 50px;
  }
`;

const ChatBtn = styled.button`
  width: 100%;
  margin: 0px 10px;
  padding: 0;
  background-color: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
    color: #7006B;
  }

  &:active {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

const ChatButton = () => {
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.chatModal);

  return (
    <ChatArticle>
      {show === 'hide' ? (
        <ChatBtn onClick={() => dispatch(setChatModal({ show: 'show' }))}>
          <img src={ChatImg} alt='message' />
        </ChatBtn>
      ) : (
        <ChatBtn onClick={() => dispatch(setChatModal({ show: 'hide' }))}>
          <img src={ChatImg} alt='message' />
        </ChatBtn>
      )}
    </ChatArticle>
  );
};

export default ChatButton;

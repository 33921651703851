export const LOAD_STAMP = 'LOAD_STAMP';
export const GET_STAMP = 'GET_STAMP';

export const loadStamp = () => ({
  type: LOAD_STAMP,
});

export const getStamp = () => ({
  type: GET_STAMP,
});

import styled from 'styled-components';

const SigninArticle = styled.div`
  position: relative;
  width: 50vw;
  min-height: 60vh;
  padding: 20px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  @media (max-width: 850px) {
    width: 100%;
    height: 100%;
    transition: 0.5s;
    border-radius: 0;
  }
`;

export { SigninArticle };

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../actions/modals.actions';
import { TextLoop } from 'react-text-loop-next';
import axios from '../../contants/axios';
import { setAuth } from '../../actions/auth.actions';
import { Menu, message } from 'antd';
import myInfoImg from '../../assets/header/profile-icon.svg';
import { setMusic } from '../../actions/setting.actions';
import { sendUserHistory } from '../../contants/history';

import StampButton from './Stamp/StampButton';
import KioskButton from './Kiosk/KioskButton';
import ChatButton from './Message/ChatButton';
import AirViewButton from './AirView/AirViewButton';

import sound from '../../assets/header/Sound.svg';
import soundMute from '../../assets/header/Soundmute.svg';

import gamePadImg from '../../assets/header/game-console-icon.svg';

import {
  HeaderContainer,
  StampArticle,
  PopupArticle,
  PopupMessage,
  AirViewArticle,
  KioskArticle,
  MyinfoDropdown,
  MyInfoBtn,
  MenuBtn,
  OnAirArticle,
  MusicControlArticle,
  MusicControlBtn,
  MusicControlImg,
  GamePadArticle,
  GamePadBtn,
  GamePadImg,
} from './HeaderStyled';

const Header = () => {
  const token = sessionStorage.getItem('token');
  const [text, setText] = useState([]);
  const [stampCount, setStampCount] = useState([]);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.stamp);
  const { music } = useSelector((state) => state.musicToggle);
  const { type, status } = useSelector((state) => state.modals);
  const { semina: seminaSwitch } = useSelector((state) => state.settings);

  useEffect(() => {
    (async () => {
      const response = await axios({
        method: 'get',
        url: `/notices`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setText(response.data);
    })();
  }, [token]);

  useEffect(() => {
    if (loading === false) {
      (async () => {
        const response = await axios({
          method: 'get',
          url: `/users/me`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const {
          id,
          username,
          email,
          company,
          department,
          duty,
          industry,
          phone,
          tutorial,
          path,
          createdAt,
        } = response.data;
        // 로그인 로그 발송
        sendUserHistory(response.data, '로그인');
        // 기본 정보 저장
        dispatch(
          setAuth({
            token,
            id,
            username,
            email,
            company,
            department,
            duty,
            industry,
            phone,
            tutorial,
            path,
            createdAt,
          }),
        );
        const list = response.data.userStamp || '';
        if (list !== '' && list !== undefined) {
          setStampCount(list.split('|'));
        }
      })();
    }
  }, [token, loading, dispatch]);

  const handleClick = () => {
    dispatch(
      setModal({
        type: 'notice',
        status: 'show',
      }),
    );
  };

  const handleLogout = () => {
    dispatch(
      setModal({
        type: 'logout',
        status: 'show',
      }),
    );
  };

  const menu = (
    <Menu theme='dark'>
      <Menu.Item key='0'>
        <MenuBtn
          onClick={() => {
            dispatch(setModal({ type: 'myinfo', status: 'show' }));
          }}
        >
          내정보 관리
        </MenuBtn>
      </Menu.Item>
      <Menu.Item key='1'>
        <MenuBtn
          onClick={() => {
            handleLogout();
          }}
        >
          로그아웃
        </MenuBtn>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <HeaderContainer>
        <StampArticle>
          <StampButton stampCount={stampCount} />
        </StampArticle>
        {seminaSwitch ? (
          <OnAirArticle
            isView={seminaSwitch}
            onClick={() => {
              window.location.href = '/seminar_room';
            }}
          >
            ON AIR
          </OnAirArticle>
        ) : (
          <OnAirArticle isView={seminaSwitch}>ON AIR</OnAirArticle>
        )}
        <PopupArticle>
          <TextLoop interval={4000} className='text-loop'>
            <div>
              <PopupMessage
                onClick={() => {
                  handleClick();
                }}
              >
                디지털 전시관에 오신것을 환영합니다.
              </PopupMessage>
            </div>
            {text.map((data, index) => {
              return (
                <div key={index}>
                  <PopupMessage
                    key={index}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {data.title}
                  </PopupMessage>
                </div>
              );
            })}
            <div>
              <PopupMessage
                onClick={() => {
                  handleClick();
                }}
              >
                공지사항을 클릭하면 해당 팝업으로 이동합니다.
              </PopupMessage>
            </div>
          </TextLoop>{' '}
        </PopupArticle>
        <GamePadArticle>
          <GamePadBtn
            onClick={() => {
              dispatch(setModal({ type: 'control', status: 'show' }));
            }}
          >
            <GamePadImg src={gamePadImg} alt='key' />
          </GamePadBtn>
        </GamePadArticle>
        <MusicControlArticle>
          {status === 'show' && type === 'object' ? (
            <MusicControlBtn
              style={{ color: '#666' }}
              onClick={() => {
                message.error('제품 팝업이 열려 있습니다.');
              }}
            >
              <MusicControlImg src={soundMute} alt='mute' />
            </MusicControlBtn>
          ) : music ? (
            <MusicControlBtn
              onClick={() => {
                dispatch(setMusic({ music: false }));
                const toggle = document.getElementById('musicToggle');
                toggle.click();
              }}
            >
              <MusicControlImg src={sound} alt='mute' />
            </MusicControlBtn>
          ) : (
            <MusicControlBtn
              onClick={() => {
                dispatch(setMusic({ music: true }));
                const toggle = document.getElementById('musicToggle');
                toggle.click();
              }}
            >
              <MusicControlImg src={soundMute} alt='listen' />
            </MusicControlBtn>
          )}
        </MusicControlArticle>
        <AirViewArticle>
          <AirViewButton />
        </AirViewArticle>
        <MyinfoDropdown overlay={menu} trigger={['click']}>
          <MyInfoBtn>
            <img src={myInfoImg} alt='MyInfo' />
          </MyInfoBtn>
        </MyinfoDropdown>
        <ChatButton />
        <KioskArticle>
          <KioskButton />
        </KioskArticle>
      </HeaderContainer>
    </>
  );
};

export default Header;

import styled from 'styled-components';

const SignupArticle = styled.div`
  position: relative;
  background: #fff;
  display: ${(props) => (props.popup === true ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;

  @media (max-width: 850px) {
    border-radius: 0;
  }
`;

const SignupTitle = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  background: #373737;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;

  @media (max-width: 850px) {
    border-radius: 0;
  }
`;

export { SignupArticle, SignupTitle };

import styled from 'styled-components';

const EDMButton = styled.button`
  border: 1px solid white;
  color: white;
  padding: 2px 30px;
  font-size: 14;
  height: 30;
  border-radius: 20px;
  background: transparent;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
  }
`;

const Title = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000e6;
`;

const Content = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #000000e6;
`;

const OkButton = styled.button`
  background: #e50043;
  border: none;
  width: 100px;
  height: 35px;
  color: #fff;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  border: 1px solid #e50043;

  &:hover {
    background: #e50043;
    color: #fff;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-height: 700px) {
    margin-top: 20px;
  }
`;

const ExitButton = styled.button`
  background: #fff;
  border: none;
  width: 100px;
  height: 35px;
  color: #666666;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  border: 1px solid #cbc9c9;

  &:hover {
    border: 1px solid #e50043;
    color: #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-height: 700px) {
    margin-top: 20px;
  }
`;

export { EDMButton, Title, Content, OkButton, ExitButton };

import styled from 'styled-components';
import { API_URL } from '../../../../contants/axios';

const ObjectImageContainer = styled.div`
  position: relative;
  min-width: 43%;
  max-width: 43%;
  background: #000;
  color: #000;
  height: 100%;
  margin-left: 123px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    height: 80%;
    min-height: 45%;
    flex: 1;
  }
`;

const LoadingDiv = styled.div`
  width: 100% !important;
  height: 100% !important;
  display: inline-block !important;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const ImageArticle = styled.div`
  position: relative;
  width: 100%;
  height: inherit;
  display: inline-block;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const VideoArticle = styled.div`
  position: relative;
  width: 100%;
  height: inherit;
  display: inline-block;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  bottom: 0;

  @media (max-width: 767px) {
    position: relative;
    margin-bottom: 10px;
  }
`;

const ImageThumbnail = styled.button`
  width: 65px;
  height: 45px;
  border: 2px solid #969696;
  margin: 10px;
  background-image: url(${(props) => `${API_URL}${props.image}`});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    border: 2px solid #e50043;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
    border: 2px solid #e50043;
  }

  @media (max-width: 767px) {
    width: 45px;
    height: 30px;
    margin: 5px 5px;
  }
`;

const VideoThumbnail = styled.button`
  width: 65px;
  height: 45px;
  border: 2px solid #969696;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    border: 2px solid #e50043;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
    border: 2px solid #e50043;
  }

  @media (max-width: 767px) {
    width: 45px;
    height: 30px;
    margin: 5px 5px;
  }
`;

const PlayButton = styled.i`
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #969696;
  border-radius: 50%;
  color: #f5f5f5;
  text-align: center;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-weight: bold;
  transition: all 0.3s ease;

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 0;
    height: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #969696;
    margin-left: 6px;
    margin-top: 3px;

    @media (max-width: 767px) {
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 5px solid #969696;
      height: 6px;
      margin-left: 4.5px;
      margin-top: 2px;
    }
  }

  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
  }
`;

const SerchIcon = styled.div`
  position: absolute;
  width: 35px;
  height: 35px !important;
  border: 2px solid #fff;
  background-image: url(${(props) => props.icon});
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  right: 10px;
  bottom: 70px;

  @media (max-width: 767px) {
    bottom: 20px;
  }
`;

const MutedPopup = styled.div`
  position: absolute;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12px;
  color: #fff;
  background: #e50043;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justfiy-content: center;
  align-items: center;
  right: 5px;
  bottom: 75px;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);

  &:after {
    content: '';
    border: 1em solid transparent;
    border-top-color: #e50043;
    position: absolute;
    top: 80%;
    right: 40%;
    width: 0;
    height: 0;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export {
  ObjectImageContainer,
  ImageArticle,
  VideoArticle,
  SerchIcon,
  LoadingDiv,
  ThumbnailContainer,
  ImageThumbnail,
  VideoThumbnail,
  PlayButton,
  MutedPopup,
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShowRoom from './ShowRoom';
import Header from '../components/MainMenu/Header';
import MobileHeader from '../components/MainMenu/MobileHeader';
import Content from '../components/MainMenu/Content';

import {
  loadProduct,
  getProduct,
  getproductDetailed,
} from '../actions/product.actions';
import { setModal } from '../actions/modals.actions';
import { tourState } from '../actions/tour.actions';
import { moveProduct } from '../actions/move.actions';

import objectEvent from '../utils/objectEvent';
import viewSwitch from '../utils/viewSwitch';
import sceneSwitch from '../utils/sceneSwitch';

const Main = () => {
  const [onLoad, setOnLoad] = useState(false);
  const [viewer, setViewer] = useState(null);

  const dispatch = useDispatch();
  const { movePosition } = useSelector((state) => state.move);
  const { scene, switching, objects, guide } = useSelector(
    (state) => state.tour,
  );
  const [content, setContent] = useState('');
  const [detail, setDetail] = useState({
    id: '',
    loading: 'false',
    type: 'hover',
  });
  const { semina } = useSelector((state) => state.settings);

  useEffect(() => {
    if (onLoad) {
      // eslint-disable-next-line no-undef
      setViewer(WALK.getViewer());
    }
  }, [onLoad]);

  useEffect(() => {
    dispatch(getproductDetailed({ ...detail }));
  }, [detail, dispatch]);

  useEffect(() => {
    if (onLoad && viewer) {
      objectEvent(
        viewer,
        dispatch,
        loadProduct,
        getProduct,
        setModal,
        moveProduct,
        content,
        semina,
      );
      viewSwitch(viewer, movePosition);
      sceneSwitch(
        viewer,
        dispatch,
        scene,
        switching,
        objects,
        tourState,
        getproductDetailed,
      );
    }
  }, [
    content,
    dispatch,
    movePosition,
    objects,
    onLoad,
    scene,
    switching,
    viewer,
    semina,
  ]);

  const isLoad = (state) => {
    setOnLoad(state);
  };

  const isContent = (state) => {
    setContent(state);
  };

  return (
    <>
      {onLoad && (
        <>
          <Header />
          <Content viewer={viewer} />
          {!guide && !switching ? <MobileHeader /> : ''}
        </>
      )}
      <ShowRoom isLoad={isLoad} isContent={isContent} setDetail={setDetail} />
    </>
  );
};

export default Main;

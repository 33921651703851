import styled from 'styled-components';

// 유저 채팅 타이틀
const TitleArticle = styled.div`
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  display: flex;
  justify-content: start;
`;

// 유저 이름
const UserName = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-right: 10px;
`;

// 유저 채팅 시간
const ChatTime = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
`;

// 상대 채팅 아티클
const ContentArticle = styled.div`
  position: relative;
  width: 70%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 10px;
  background: #f8f8f8;
  word-break: keep-all;

  @media (max-width: 767px) {
    width: 90%;
  }
`;

// 상대 채팅
const Content = styled.span`
  position: relative;
  max-width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  color: #000000;
  white-space: pre-line;
  word-break: keep-all;
  overflow-wrap: break-word;
`;

// 유저 채팅 로그 아티클
const UserChatArticle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  overflow-wrap: break-word;
`;

// 유저 채팅 로그
const UserChat = styled.span`
  position: relative;
  max-width: 70%;
  padding: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #313131 100%),
    #000000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  color: #ffffff;
  white-space: pre-line;
  word-break: keep-all;
  overflow-wrap: break-word;

  @media (max-width: 767px) {
    max-width: 90%;
  }
`;

export {
  TitleArticle,
  UserName,
  ChatTime,
  ContentArticle,
  Content,
  UserChat,
  UserChatArticle,
};

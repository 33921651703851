import React from 'react';
import {
  ControlContainer,
  TitleArticle,
  Title,
  CloseButton,
  ContentArticle,
  ImageArticle,
} from './ControlPopupStyled';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';
import closeImg from '../../../assets/closewhite.svg';
import help3 from '../../../assets/help/help3.png';

const ControlPopup = () => {
  const dispatch = useDispatch();

  return (
    <ControlContainer>
      <TitleArticle>
        <Title>조작방법</Title>
        <CloseButton
          onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
          img={closeImg}
        />
      </TitleArticle>
      <div>
        <ContentArticle>
          <ImageArticle style={{ backgroundImage: `url(${help3})` }}>
            <img
              width={'100%'}
              src={help3}
              style={{ opacity: '0', zoom: '1', filter: 'alpha(opacity=0)' }}
              alt='control'
            />
          </ImageArticle>
        </ContentArticle>
      </div>
    </ControlContainer>
  );
};

export default ControlPopup;

import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { saveProduct } from '../actions/product.actions';
import { setSetting } from '../actions/setting.actions';
import { setModal } from '../actions/modals.actions';
import axios from '../contants/axios';
import materialHover from '../utils/materialHover';
import videoTexture from '../utils/videoTexture';
import imageTexture from '../utils/imageTexture';
import { Carousel, message } from 'antd';
import styled from 'styled-components';

const CarouselCustom = styled(Carousel)`
  width: 50vw;

  @media (max-width: 767px) {
    width: 100vw;
  }
`;

const Guard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: transparent;
`;

export default function ShowRoom({ isLoad, isContent, setDetail }) {
  const [loading, setLoading] = useState(true);
  const [connect, setConnect] = useState({
    product: false,
    setting: false,
    content: false,
  });
  const [slideLoad, setSlideLoad] = useState(true);
  const token = sessionStorage.getItem('token');
  const dispatch = useDispatch();
  useAuth(true);

  const newText = [
    '방문을 환영합니다!',
    '스마트 엔지니어링 솔루션을 확인하세요!',
    '딱 맞는 판넬을 준비하고 있습니다',
    '효율 좋은 에어컨을 설치하고 있습니다',
    '공간 활용성 좋은 Busbar를 구성 중입니다',
    'IT 산업에 필요한 솔루션 여기 있습니다',
    '열심히 힘내고 있습니다. 조금만 기다려주세요',
  ];

  // 쇼룸 로딩과 동시에 제품 리스트 받아오기
  useEffect(() => {
    if (!connect.product && token) {
      (async () => {
        const productKey = 'product';
        message.loading({
          content: '제품 정보를 불러옵니다.',
          key: productKey,
          duration: 0,
        });
        await axios({
          method: 'get',
          url: `/products`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            message.success({
              content: '제품 정보를 불러왔습니다.',
              key: productKey,
            });
            setConnect((prevState) => {
              return {
                ...prevState,
                product: true,
              };
            });
            dispatch(saveProduct(res.data));
          })
          .catch((err) => {
            message.error({
              content: '제품 정보가 누락되었습니다. 새로고침 해주세요.',
              key: productKey,
            });
            console.log(err);
            setConnect((prevState) => {
              return {
                ...prevState,
                product: true,
              };
            });
          });
      })();
    }
  }, [connect.product]);

  // 이벤트 on, off 정보 받아오기
  useEffect(() => {
    if (!connect.setting && token) {
      (async () => {
        const settingKey = 'setting';
        message.loading({
          content: '설정 정보를 불러옵니다.',
          key: settingKey,
          duration: 0,
        });
        await axios({
          method: 'get',
          url: `/settings`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            message.success({
              content: '설정 정보를 불러왔습니다.',
              key: settingKey,
            });
            setConnect((prevState) => {
              return {
                ...prevState,
                setting: true,
              };
            });
            dispatch(
              setSetting({
                stamp: res.data[0].stamp,
                tour: res.data[0].tour,
                semina: res.data[0].semina,
                stampBanner: res.data[0].stampBanner || '',
              }),
            );
          })
          .catch((err) => {
            message.error({
              content: '설정 정보가 누락되었습니다. 새로고침 해주세요.',
              key: settingKey,
            });
            setConnect((prevState) => {
              return {
                ...prevState,
                setting: true,
              };
            });
          });
      })();
    }
  }, [connect.setting]);

  useEffect(() => {
    if (!loading && !connect.content) {
      var viewer = WALK.getViewer();
      // 쇼룸 로딩 후 컨텐츠 리스트 받아오기
      (async () => {
        const contentKey = 'content';
        message.loading({
          content: '텍스쳐 정보를 불러옵니다.',
          key: contentKey,
          duration: 0,
        });
        await axios({
          method: 'get',
          url: '/contents',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            message.success({
              content: '텍스쳐 정보를 불러왔습니다.',
              key: contentKey,
            });
            setConnect((prevState) => {
              return {
                ...prevState,
                content: true,
              };
            });

            const media = res.data;
            media.map((data) => {
              if (data.ContentType === '동영상') {
                if (data.ContentPosition === 'RAS_Semi_Auto') return;
                return videoTexture(viewer, data);
              } else {
                if (
                  data.ContentPosition === '좌측기둥' ||
                  data.ContentPosition === '우측기둥'
                )
                  return;
                return imageTexture(viewer, data);
              }
            });

            const magazine = [];
            media.map((data) => {
              if (data.isLink) {
                magazine.push({
                  position: data.ContentPosition,
                  link: data.link,
                });
              }
            });
            isContent(magazine);
          })

          .catch((err) => {
            message.error({
              content: '텍스쳐 정보가 누락되었습니다. 새로고침 해주세요.',
              key: contentKey,
            });
            console.log(err);
            setConnect((prevState) => {
              return {
                ...prevState,
                content: true,
              };
            });
          });
      })();
    }
  }, [loading, connect.content]);

  if (token !== null) {
    useEffect(() => {
      if (loading) {
        // style.css 적용
        const link = document.createElement('link');
        link.href = './space/webwalk/style.css';
        link.rel = 'stylesheet';
        link.crossOrigin = 'anonymous';
        document.body.appendChild(link);

        // Shapespark 엔진 적용
        const script = document.createElement('script');
        script.src = './space/webwalk/walk.min.js';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        const start = document.createElement('script');

        script.onload = async () => {
          start.innerHTML = `WALK.ASSETS_URL = './space/';`;
          start.type = 'module';
          document.body.appendChild(start);

          // Shapespark API 요소 로드
          var viewer = await WALK.getViewer();
          viewer.play();
          materialHover(viewer, setDetail);

          // 내부공간 비디오 및 이미지 교체를 위한 에디트 활성화
          viewer.setAllMaterialsEditable();

          // 모든 로딩이 끝난 후 callbacky
          viewer.onSceneLoadComplete(loadingDone);

          function loadingDone() {
            isLoad(true);
            setSlideLoad(false);
            setLoading(false);
          }
        };
      }
    }, [loading]);
  }

  // 뒤로가기 방지 및 안정적인 쇼룸 분리를 위한 로그아웃창 출력
  useEffect(() => {
    (function (ua) {
      history.pushState(null, null, location.href);
      ua = ua || navigator.userAgent;
      ua = ua.toLowerCase();
      // 사파리 이외 브라우저
      if (/safari/.test(ua) && !!window.chrome) {
        window.onpopstate = function (event) {
          event = event || window.event;
          history.go(1); // 뒤로가기 방지
          dispatch(setModal({ type: 'logout', status: 'show' }));
        };
      } else {
        setTimeout(function () {
          window.onpopstate = function (event) {
            history.go(1); // 뒤로가기 방지
            dispatch(setModal({ type: 'logout', status: 'show' }));
          };
        }, 500);
      }
    })(navigator.userAgent);
  }, []);

  return (
    <>
      {!connect.product || !connect.setting || !connect.content ? (
        <Guard />
      ) : (
        ''
      )}
      <noscript>
        <div class='message error-message absolute-center'>
          Shapespark visualizations require JavaScript. See
          <a
            href='http://enable-javascript.com/'
            target='_blank'
            rel='noreferrer'
          >
            the instructions
          </a>{' '}
          to enable JavaScript in your browser.
        </div>
      </noscript>

      <div id='cover-image'></div>

      <canvas id='walk-canvas' tabIndex='-1'></canvas>

      <div
        id='info-message'
        className='message info-message'
        style={{ display: 'none' }}
      ></div>

      <div
        id='error-message'
        className='message error-message absolute-center'
        style={{ display: 'none' }}
      ></div>

      <div id='info-bar' className='ui-top ui-left'>
        <div id='info-bar-slide'>
          <a
            href='/'
            id='author-logo'
            className='info-item ui-panel'
            style={{ display: 'none' }}
            rel='noreferrer'
          >
            <img alt='' />
          </a>
          <div
            id='info-text'
            className='info-item ui-panel'
            style={{ display: 'none' }}
          >
            <div>
              <div id='info-title'></div>
            </div>
            <div>
              <a id='info-author' href='/' target='_blank' rel='noreferrer'>
                .
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        id='menu-bar'
        className='ui-bottom ui-right'
        style={{ dispaly: 'none' }}
      >
        <div id='menu-bar-slide'>
          <div
            id='menu-bar-folder'
            className='menu-item ui-hoverable ui-panel'
            style={{ display: 'none' }}
          >
            <img src='./space/webwalk/img/fold-horizontal.svg' alt='' />
          </div>
          <div id='menu-bar-content'>
            <div id='menu-buttons' style={{ display: 'none' }}>
              <div
                id='help-button'
                className='menu-button menu-item ui-hoverable ui-panel'
              >
                ?
              </div>
              <div
                id='minimap-button-toggle'
                className='menu-button menu-item ui-hoverable ui-panel'
                style={{ display: 'none' }}
              >
                <img src='./space/webwalk/img/map.svg' />
              </div>
              <div
                id='tour-button'
                className='menu-button menu-item ui-hoverable ui-panel'
                style={{ display: 'none' }}
              >
                <img src='./space/webwalk/img/tour.svg' alt='' />
              </div>
              <div
                id='fullscreen-button'
                className='fullscreen-specific menu-button menu-item ui-hoverable
                      ui-panel'
                style={{ display: 'none' }}
              >
                <img src='./space/webwalk/img/fullscreen.svg' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id='view-list'
        className='ui-top ui-right'
        style={{ display: 'none' }}
      >
        <div id='view-list-slide'>
          <div id='view-list-items'>
            <div className='view ui-hoverable ui-panel'></div>
          </div>
          <div id='view-list-folder' className='ui-hoverable ui-panel'>
            <img src='./space/webwalk/img/fold-vertical.svg' alt='' />
          </div>
        </div>
      </div>
      <div
        id='minimap-container'
        className='minimap-container-default'
        style={{ display: 'none' }}
      >
        <div id='minimap-wrapper' className='minimap-wrapper-default'>
          <div
            id='minimap-button-close'
            className='minimap-button minimap-button-close'
          >
            <img src='./space/webwalk/img/close.svg' />
          </div>
          <div
            id='minimap-button-minimize'
            className='minimap-button minimap-button-minimize'
          >
            <img src='./space/webwalk/img/minimize.svg' />
          </div>
          <div
            id='minimap-button-maximize'
            className='minimap-button minimap-button-maximize'
          >
            <img src='./space/webwalk/img/maximize.svg' />
          </div>
          <div id='minimap-content-wrapper' className='minimap-content-wrapper'>
            <div id='minimap-content' className='minimap-content' />
          </div>
          <div
            id='minimap-tabs'
            className='minimap-tabs-default'
            style={{ display: 'none' }}
          />
        </div>
      </div>
      <img
        id='play-button'
        className='absolute-center'
        src='./space/webwalk/img/play.svg'
        style={{ display: 'none' }}
        alt=''
      />
      <div
        id='interaction-prompt'
        className='ui-all-center ui-panel'
        style={{ display: 'none' }}
      >
        <div className='interaction-prompt-icon'>
          <img
            className='interaction-prompt-icon-idle interaction-prompt-desktop'
            src='./space/webwalk/img/pointer.svg'
          />
          <img
            className='interaction-prompt-icon-pressed interaction-prompt-desktop'
            src='./space/webwalk/img/pointer-pressed.svg'
          />
          <img
            className='interaction-prompt-icon-idle interaction-prompt-mobile'
            src='./space/webwalk/img/hand.svg'
          />
          <img
            className='interaction-prompt-icon-pressed interaction-prompt-mobile'
            src='./space/webwalk/img/hand-pressed.svg'
          />
        </div>
        <div
          data-strings='help.interactiveDesktop'
          className='interaction-prompt-indicator-text interaction-prompt-desktop'
        />
        <div
          data-strings='help.interactiveMobile'
          className='interaction-prompt-indicator-text interaction-prompt-mobile'
        />
      </div>
      <div id='help-and-primary-progress' className='absolute-center'>
        <div
          id='desktop-help'
          className='help ui-panel'
          style={{ display: 'none' }}
        >
          <img
            id='close-desktop-help-button'
            className='close-help-button ui-close-hoverable'
            src='./space/webwalk/img/close.svg'
          />
          <div id='desktop-help-switch'>
            <div data-strings='help.basic' id='basic-desktop-help-option' />
            <div
              data-strings='help.advanced'
              id='advanced-desktop-help-option'
            />
          </div>
          <div id='desktop-help-content'>
            <div
              id='desktop-basic-help'
              className='help-columns'
              style={{ display: 'none' }}
            >
              <div className='help-column3'>
                <h1 data-strings='help.mouse' />
                <img src='./space/webwalk/img/mouse-controls.svg' />
              </div>
              <div className='help-column3-sep' />
              <div className='help-column3'>
                <h1 data-strings='help.keyboard' />
                <img src='./space/webwalk/img/keyboard-controls.svg' />
              </div>
              <div className='help-column3-sep' />
              <div className='help-column3'>
                <h1 data-strings='help.vrTeleport' />
                <img src='./space/webwalk/img/vr-controls.svg' />
              </div>
            </div>
            <div id='advanced-desktop-help' style={{ display: 'none' }}>
              <h1 data-strings='help.mouse' />
              <div>
                <div className='help-how' data-strings='help.holdLeftButton' />
                <div data-strings='help.lookAround' className='help-what' />
              </div>
              <div>
                <div className='help-how' data-strings='help.clickLeftButton' />
                <div
                  data-strings='help.walkToClickedPlace'
                  className='viewer-specific help-what'
                />
                <div
                  data-strings='help.selectMaterialLightObject'
                  className='editor-specific help-what'
                  style={{ display: 'none' }}
                />
              </div>
              <div>
                <div className='help-how' data-strings='help.scroll' />
                <div data-strings='help.walkStraight' className='help-what' />
              </div>
              <h1 data-strings='help.keyboard' />
              <div>
                <div className='help-how'>
                  <div>
                    <div className='help-key-placeholder' />
                    <div className='help-key'>↑</div>
                    <div className='help-key-placeholder' />
                  </div>
                  <div>
                    <div className='help-key'>←</div>
                    <div className='help-key'>↓</div>
                    <div className='help-key'>→</div>
                  </div>
                </div>
                <div
                  data-strings='help.walkStraightAndLookAround'
                  className='help-what'
                />
              </div>
              <div>
                <div className='help-how'>
                  <div>
                    <div className='help-key-placeholder' />
                    <div className='help-key'>W</div>
                    <div className='help-key-placeholder' />
                  </div>
                  <div>
                    <div className='help-key'>A</div>
                    <div className='help-key'>S</div>
                    <div className='help-key'>D</div>
                  </div>
                </div>
                <div
                  data-strings='help.walkStraightAndSideways'
                  className='help-what'
                />
              </div>
              <div>
                <div className='help-how'>
                  <div className='help-key'>PgUp</div>
                  <div className='help-key'>PgDn</div>
                  <div className='help-key-sep' />
                  <div className='help-key'>E</div>
                  <div className='help-key'>Q</div>
                </div>
                <div data-strings='help.changeHeight' className='help-what' />
              </div>
              <div>
                <div className='help-how'>
                  <div className='help-key'>1</div>–
                  <div className='help-key'>9</div>
                  <div className='help-key-sep' />
                  <div className='help-key'>[</div>
                  <div className='help-key'>]</div>
                </div>
                <div data-strings='help.switchView' className='help-what' />
              </div>
              <div className='help-how'>
                <div className='help-key'>P</div>
              </div>
              <div data-strings='help.screenshot' className='help-what' />
              <div
                className='editor-specific help-how'
                style={{ display: 'none' }}
              >
                <div className='help-key'>I</div>
              </div>
              <div
                data-strings='help.illuminationPreview'
                className='editor-specific help-what'
                style={{ display: 'none' }}
              />
              <div className='help-how'>
                <div className='help-key'>M</div>
              </div>
              <div data-strings='help.hideShowMenu' className='help-what' />
              <div className='help-how'>
                <div className='help-key'>L</div>
              </div>
              <div
                data-strings='help.hideShowMousePointer'
                className='help-what'
              />
              <h1 data-strings='help.vrMode' />
              <div>
                <div className='help-how'>
                  <div data-strings='help.gazeAtFixedPlace' />
                  <div data-strings='help.or' />
                  <div data-strings='help.clickControllerButton' />
                </div>
                <div data-strings='help.teleport' className='help-what' />
              </div>
            </div>
          </div>
        </div>
        <div
          id='mobile-help'
          className='help ui-panel'
          style={{ display: 'none' }}
        >
          <img
            id='close-mobile-help-button'
            className='close-help-button ui-close-hoverable'
            src='./space/webwalk/img/close.svg'
          />
          <div className='help-columns'>
            <div className='help-column2'>
              <h1 data-strings='help.touch' />
              <img src='./space/webwalk/img/touch-controls.svg' />
            </div>
            <div className='help-column2-sep' />
            <div className='navigation-help-column2'>
              <h1 data-strings='help.vrTeleport' />
              <img src='./space/webwalk/img/vr-controls.svg' />
            </div>
          </div>
        </div>

        <svg
          id='primary-progress'
          viewBox='-200 -68 400 230'
          style={{ display: 'none' }}
        >
          <circle cx='0' cy='0' r='50' strokeWidth='10'></circle>
          <circle
            id='primary-progress-done'
            cx='0'
            cy='0'
            r='50'
            strokeWidth='10'
            strokeDasharray='315.159'
            strokeDashoffset='314.159'
            transform='rotate(-90)'
          ></circle>
          <text
            id='primary-progress-count'
            dominantBaseline='middle'
            textAnchor='middle'
            fill='white'
            fontFamily='Poppins'
            fontSize='20px'
          ></text>
        </svg>
      </div>
      {slideLoad ? (
        <div
          style={{
            width: '100vw',
            height: '30px',
            position: 'absolute',
            bottom: '43%',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
          }}
        >
          <CarouselCustom autoplay dots={false}>
            {newText.map((text, index) => {
              return (
                <div key={index}>
                  <p
                    id='primary-progress-text'
                    style={{
                      fontSize: '22px',
                      fontWeight: '800',
                      fontFamily: 'Poppins',
                      color: 'white',
                      textAlign: 'center',
                      margin: 'auto',
                    }}
                  >
                    {text}
                  </p>
                </div>
              );
            })}
          </CarouselCustom>
        </div>
      ) : (
        ''
      )}
      <div id='secondary-progress' style={{ display: 'none' }}>
        <div id='secondary-progress-done'></div>
      </div>

      <div
        id='ext-html-label'
        className='ext-popup ui-top ui-left'
        style={{ display: 'none' }}
      >
        <div id='ext-html-label-content' className='ext-popup-content'></div>
        <div className='ext-popup-close-button-panel ui-close-hoverable'>
          <img
            id='ext-html-label-close'
            className='ext-popup-close-button'
            src='./space/webwalk/img/close.svg'
            alt=''
          />
        </div>
      </div>

      <div
        id='ext-meeting-status'
        className='ext-popup ui-top ui-left'
        style={{ display: 'none' }}
      >
        <div id='ext-meeting-status-content' className='ext-popup-content'>
          <strong>Here:</strong>
          <ul id='ext-meeting-who-list' className='ext-meeting-list'></ul>
          <div>
            <button
              id='ext-meeting-mic-toggle'
              className='ext-meeting-button ext-meeting-button-highlight'
            >
              <span className='fa-microphone' style={{ display: 'none' }} />
              <span className='fa-microphone-slash' />
            </button>
            <button
              id='ext-meeting-camera-toggle'
              className='ext-meeting-button ext-meeting-button-highlight'
            >
              <span className='fa-video' style={{ display: 'none' }} />
              <span className='fa-video-slash' />
            </button>
            <button id='ext-meeting-pointer' className='ext-meeting-button'>
              <span className='fa-hand-pointer' />
            </button>
          </div>
          <div id='ext-meeting-notes-preview' style={{ display: 'none' }}>
            <strong>Notes:</strong>
            <ul id='ext-meeting-note-list' className='ext-meeting-list' />
            <button id='ext-meeting-add-note' className='ext-meeting-button'>
              Add note
            </button>
          </div>
          <div id='ext-meeting-status-footer'>
            <span id='ext-meeting-quality-indicator-container'> </span>
            <span>
              <button
                id='ext-meeting-leave'
                className='ext-meeting-button ext-meeting-button-highlight'
              >
                Leave meeting
              </button>
            </span>
          </div>
        </div>
        <div
          id='ext-meeting-who-list-folder'
          className='ui-folder-vertical ui-hoverable ui-panel'
        >
          <img src='./space/webwalk/img/fold-vertical.svg' />
        </div>
      </div>
      <div id='ext-meeting-camera-preview' style={{ display: 'none' }} />
    </>
  );
}

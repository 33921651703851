import styled from 'styled-components';
import { Dropdown } from 'antd';

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 1);
  z-index: 999;
  margin: 0;
  padding: 0;
  height: 60px;
  cursor: default;

  @media (max-width: 767px) {
    height: 50px;
    display: none !important;
    transition: 0.5s;
  }
`;

const StampArticle = styled.div`
  min-width: 125px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    min-width: 85px;
  }
`;

const PopupArticle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px;
`;

const AirViewArticle = styled.div`
  min-width: 65px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;

  &:hover {
    background-color: #e50043;
  }

  @media (max-width: 767px) {
    min-width: 50px;
  }
`;

const KioskArticle = styled.div`
  min-width: 65px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e50043;
  }

  @media (max-width: 767px) {
    min-width: 50px;
  }
`;

const PopupMessage = styled.div`
  width: 100%;
  margin: 0;
  font-fmaily: Noto Sans KR;
  font-size: 16px;
  font-weight: bold;
  font-styled: normal;
  text-align: center;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: #e50043;
    cursor: pointer;
  }
`;

const MyInfoBtn = styled.a`
  width: auto;
  margin: 0px 10px;
  padding: 0;
  background-color: transparent;
  border: 0;
  text-align: center;

  &:hover {
    cursor: pointer;
    color: #7006B;
  }

  &:active {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

const MyinfoDropdown = styled(Dropdown)`
  min-width: 65px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  margin-left: 0;

  &:hover {
    background-color: #e50043;
  }

  @media (max-width: 767px) {
    min-width: 50px;
  }
`;

const MenuBtn = styled.button`
  border: none;
  background-color: transparent;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const OnAirArticle = styled.div`
  min-width: 70px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.isView ? '#FF5656' : '#666')};

  &:hover {
    background-color: ${(props) =>
      props.isView ? '#E50043' : 'rgba(255, 255, 255, 0.1)'};
    cursor: ${(props) => (props.isView ? 'pointer' : 'default')};
    color: ${(props) => (props.isView ? '#fff' : '#666')};
  }

  @media (max-width: 767px) {
    min-width: 60px;
  }
`;

const MusicControlArticle = styled.div`
  min-width: 65px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;

  &:hover {
    background-color: #e50043;
  }

  @media (max-width: 767px) {
    min-width: 50px;
  }
`;

const MusicControlBtn = styled.button`
  width: 100%;
  margin: 0px 10px;
  padding: 0;
  background-color: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
    color: #e50043;
  }

  &:active {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

const MusicControlImg = styled.img`
  position: relative;

  @media (max-width: 767px) {
    width: 15px;
    height: 30px;
  }
`;

const GamePadArticle = styled.div`
  min-width: 65px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;

  &:hover {
    background-color: #e50043;
  }

  @media (max-width: 767px) {
    min-width: 50px;
  }
`;

const GamePadBtn = styled.button`
  width: 100%;
  margin: 0px 10px;
  padding: 0;
  background-color: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
    color: #e50043;
  }

  &:active {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

const GamePadImg = styled.img`
  position: relative;

  @media (max-width: 767px) {
    width: 15px;
    height: 30px;
  }
`;

export {
  HeaderContainer,
  StampArticle,
  PopupArticle,
  PopupMessage,
  AirViewArticle,
  KioskArticle,
  MyinfoDropdown,
  MyInfoBtn,
  MenuBtn,
  OnAirArticle,
  MusicControlArticle,
  MusicControlBtn,
  MusicControlImg,
  GamePadArticle,
  GamePadBtn,
  GamePadImg,
};

import styled from 'styled-components';

const ObjectEstimateContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  color: #000;
  height: 100%;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`;

const ContentForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 25px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.87);
  overflow: auto;

  @media (max-width: 767px) {
    height: auto;
    padding: 15px;
    justify-content: center;
    align-items: center;
  }
`;

const TopArticle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const CompanyInput = styled.input`
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: Noto Sans KR;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;

  &:focus {
    outline: 1px solid #E50043;
    border: 1px solid #E50043;
  }

  @media(max-width: 767px) {
    font-size 12px;
    height: 30px;
  }
`;

const IndustrySelect = styled.select`
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: Noto Sans KR;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  margin-left: 15px;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;

  &:focus {
    outline: 1px solid #E50043;
    border: 1px solid #E50043;
  }

  @media(max-width: 767px) {
    margin-left: 0;
    margin-top: 10px;
    font-size 12px;
    height: 30px;
  }
`;

const TitleInput = styled.input`
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: Noto Sans KR;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;

  &:focus {
    outline: 1px solid #E50043;
    border: 1px solid #E50043;
  }

  @media(max-width: 767px) {
    font-size 12px;
    height: 30px;
  }
`;

const PhoneInput = styled.input`
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: Noto Sans KR;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;

  &:focus {
    outline: 1px solid #E50043;
    border: 1px solid #E50043;
  }

  @media(max-width: 767px) {
    font-size 12px;
    height: 30px;
  }
`;

const AddressInput = styled.input`
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: Noto Sans KR;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;

  &:focus {
    outline: 1px solid #E50043;
    border: 1px solid #E50043;
  }

  @media(max-width: 767px) {
    font-size 12px;
    height: 30px;
  }
`;

const ContentTextArea = styled.textarea`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  height: 90px;
  font-size: 14px;
  font-family: Noto Sans KR;
  border-radius: 2px;
  resize: none;

  &:focus {
    outline: 1px solid #E50043;
    border: 1px solid #E50043;
  }

  @media(max-width: 767px) {
    font-size 12px;
    height: 120px;
    min-height: auto;
    margin-bottom: 15px;
  }
`;

const NoticeText = styled.span`
  position: relative;
  width: 100%;
  color: #ec4c47;
  font-size: 12px;
`;

const SendContainer = styled.div`
  position: relative;
  width: 100%;
  height: 35px;
  font-size: 14px;
  font-family: Noto Sans KR;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: flex-end;
`;

const SendButton = styled.button`
  position: relative;
  background: #E50043;
  border: 0;
  color: #fff;
  letter-spacing: 4px;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: bold;
  font-family: Poppins;
  width: 140px;
  height: 35px;

  &:hover {
    background: #ff2288;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow; none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  @media(max-width: 767px) {
    font-size: 12px;
    position: relative;
    right: 0;
    bottom: 20px;
    margin-top: 0;
    min-height: 35px;
  }
`;

export {
  ObjectEstimateContainer,
  ContentForm,
  TopArticle,
  CompanyInput,
  IndustrySelect,
  PhoneInput,
  AddressInput,
  TitleInput,
  ContentTextArea,
  NoticeText,
  SendContainer,
  SendButton,
};

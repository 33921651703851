import React from 'react';
import styled from 'styled-components';
import waitingImg from '../assets/waiting.svg';

const WaitingContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 10px;
`;

const Image = styled.img`
  margin-bottom: 77px;

  @media (max-width: 767px) {
    width: 15%;
  }
`;

const Title = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 42px;

  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

const Content = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 16px;
    word-break: keep-all;
  }
`;

const SubContent = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 12px;
    word-break: keep-all;
  }
`;

const WaitingPage = () => {
  return (
    <WaitingContainer>
      <Image src={waitingImg} alt='' />
      <Title>
        전시관
        <Title style={{ color: '#E50043' }}> 접속대기 </Title>
        중입니다.
      </Title>
      <Content>현재 접속자가 일시적으로 집중되어</Content>
      <Content style={{ marginBottom: '12px' }}>
        귀하보다 먼저 접속한 접속자부터 순차적으로 입장하고 있습니다.
      </Content>
      <Content style={{ marginBottom: '42px' }}>
        잠시만 기다리시면 전시관으로 자동 접속되오니 양해부탁드립니다.
      </Content>
      <SubContent>
        ※ 접속 순서대로 입장함에 따라 재접속하면 대기시간이 길어집니다.
      </SubContent>
    </WaitingContainer>
  );
};

export default WaitingPage;

import styled from 'styled-components';

const WaitingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5.43656px);
`;

const WaitingArticle = styled.div`
  position: relative;
  width: 40%;
  height: 65vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 66px 84px;

  @media (max-width: 1560px) {
    width: 45%;
  }

  @media (max-width: 1390px) {
    width: 50%;
  }

  @media (max-width: 1250px) {
    width: 60%;
  }

  @media (max-width: 1040px) {
    width: 70%;
  }

  @media (max-width: 890px) {
    width: 80%;
  }

  @media (max-width: 780px) {
    width: 100%;
    height: 100vh;
    padding: 30px;
  }
`;

const BodyArticle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: #fff;
  overflow: auto;
`;

const Content = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  word-break: break-word;
  margin-top: 12px;
`;

const ButtonArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const ExitButton = styled.button`
  background: #e50043;
  border: none;
  width: 149px;
  height: 42px;
  color: #fff;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  border: 1px solid #cbc9c9;

  &:hover {
    background: #ff2288;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const Title = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #000000;

  &:after {
    content: '';
    display: block;
    width: 70px;
    border-bottom: 2px solid #000;
    margin: 15px 0px 16px 0px;
  }
`;

const HelpContainer = styled.div`
  width: 100%;
  hegith: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 24px;
  border: 1px solid #cbc9c9;
  box-sizing: border-box;
  margin-top: 12px;

  @media (max-width: 767px) {
    padding: 0px;
  }
`;

const HelpArticle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const HelpContent = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  word-break: keep-all;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
    margin-top: 15px;
  }
`;

export {
  WaitingContainer,
  WaitingArticle,
  BodyArticle,
  Content,
  ButtonArticle,
  ExitButton,
  Title,
  HelpArticle,
  HelpContainer,
  HelpContent,
};

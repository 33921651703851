import React, { useRef, useState, useEffect } from 'react';
import stampArt from '../../../assets/stamp/stampArt.svg';
import { emailData } from '../../assets/SelectData';
import axios from '../../../contants/axios';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';

import {
  SubContainer,
  ContentArticle,
  TitleImage,
  ContentTitle,
  ContentSub,
  InputContent,
  NameInput,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectBox,
  EmailSelectInput,
  PhoneArticle,
  PhoneInput,
  PhoneText,
  StampButton,
} from './styled/SubscribeStyled';

const Subscribe = ({ email, username }) => {
  const token = sessionStorage.getItem('token');
  const emailSelect = useRef();
  const key = 'sending';
  const dispatch = useDispatch();
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const eamilSplit = email.split('@');

  useEffect(() => {
    if (validate) {
      (async () => {
        try {
          axios({
            method: 'POST',
            url: `/subscribes`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              email: user.email,
              name: user.name,
              phone: user.phone,
              stampdone: true,
            },
          });
          message.success({ content: '참여해주셔서 감사합니다.', key });
          // 이벤트 수행 시간 응모 시간
          const clickDate = new Date();
          // eslint-disable-next-line no-undef
          gtag('event', 'stamp_apply', {
            event_category: 'last_stamp',
            event_label: `${user.email}|${clickDate}`, // 사용자 위치 이동 데이터
          });
          dispatch(setModal({ type: '', status: 'hide' }));
        } catch {
          message.error({ content: '요청에 실패했습니다.', key });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, validate]);

  const handleChange = (event) => {
    const selectEmail = event.target.value;
    if (selectEmail === '직접입력') {
      emailSelect.current.value = '';
    } else {
      emailSelect.current.value = selectEmail;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { phone } = event.target;

    // 전화번호 체크
    const phoneRule = /^\d{3}\d{3,4}\d{4}$/;
    const telrule = /^\d{2,3}\d{3,4}\d{4}$/;

    if (!phoneRule.test(phone.value) || !telrule.test(phone.value)) {
      setError('phone');

      setTimeout(() => {
        setError('');
      }, 2000);
    } else {
      setUser({
        name: username,
        email: email,
        phone: phone.value,
      });
      setValidate(true);
      message.loading({ content: '잠시만 기다려주세요.', key });
    }
  };

  return (
    <SubContainer>
      <ContentArticle>
        <TitleImage src={stampArt} alt='stamp' />
        <ContentTitle>
          스탬프를 다 받으셨군요!
          <ContentSub>
            아래 정보를 빠짐없이 기입해주셔야 참여가 완료됩니다.
          </ContentSub>
        </ContentTitle>
      </ContentArticle>
      <InputContent onSubmit={handleSubmit}>
        <NameInput
          type='text'
          placeholder='이름 입력'
          name='name'
          value={username}
          disabled={true}
        />
        <EmailArticle>
          <EmailInput
            type='text'
            placeholder='e-mail 입력'
            name='email'
            error={error}
            value={eamilSplit[0]}
            disabled={true}
          />
          <AtInput readOnly value='@' error={error} />
          <EmailSelectInput
            type='text'
            ref={emailSelect}
            name='emailAddress'
            error={error}
            value={eamilSplit[1]}
            disabled={true}
          />
          <EmailSelectBox onChange={handleChange} error={error} disabled={true}>
            {emailData.map((address) => {
              return (
                <option key={address} value={address}>
                  {address}
                </option>
              );
            })}
          </EmailSelectBox>
        </EmailArticle>
        <PhoneArticle>
          <PhoneInput
            type='text'
            placeholder="연락처 입력 ('-' 하이픈 제외)"
            name='phone'
            error={error}
          />
          <PhoneText error={error}>
            {error === 'email'
              ? '※ 이메일을 확인 해주세요.'
              : error === 'phone'
              ? '※ 전화번호를 다시 입력해주세요.'
              : '* 입력하신 전화번호로 기프티콘이 보내집니다.'}
          </PhoneText>
        </PhoneArticle>
        <StampButton type='submit'>응모하기</StampButton>
      </InputContent>
    </SubContainer>
  );
};

export default Subscribe;

import React, { useState, useEffect } from 'react';
import {
  WaitingContainer,
  WaitingArticle,
  BodyArticle,
  Content,
  ButtonArticle,
  ExitButton,
  Title,
  HelpArticle,
  HelpContainer,
  HelpContent,
} from './OpenWaitingStyled';
import axios from '../../contants/axios';
import onAirIcon from '../../assets/popup/on-air.svg';
import kioskIcon from '../../assets/popup/kiosk.svg';
import seminarIcon from '../../assets/popup/seminar.svg';

const OpenWaiting = () => {
  const [data, setData] = useState({
    title: '',
    seminar: false,
  });

  useEffect(() => {
    (async () => {
      await axios
        .get('/settings')
        .then((res) => {
          if (!res) return;

          const { data } = res;

          return setData({
            title: data[0].seminarTitle || '',
            seminar: data[0].semina || false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  return (
    <>
      {data.seminar && (
        <>
          {' '}
          <WaitingContainer>
            <WaitingArticle>
              <BodyArticle>
                <Title>세미나 안내</Title>
                <Content
                  style={{ fontSize: 15, fontWeight: 'bold', marginTop: 0 }}
                >
                  {`[${data.title || '세미나'}] 개최!`}
                </Content>
                <Content>
                  지금 여러분들을 위한 온라인 세미나를 진행하고 있습니다.
                </Content>
                <Content>많은 참여 부탁드립니다.</Content>
                <Content style={{ marginTop: 25 }}>참여방법:</Content>
                <HelpContainer>
                  <HelpArticle>
                    <HelpContent>{'방법1)'}</HelpContent>
                    <img
                      src={onAirIcon}
                      alt='on-air'
                      style={{ marginRight: 15 }}
                    />
                    <HelpContent style={{ fontSize: 15, fontWeight: 'bold' }}>
                      클릭
                    </HelpContent>
                  </HelpArticle>
                  <HelpArticle style={{ marginTop: 30 }}>
                    <HelpContent>{'방법2)'}</HelpContent>
                    <img
                      src={kioskIcon}
                      alt='kiosk'
                      style={{ marginRight: 15 }}
                    />
                    <HelpContent style={{ fontSize: 15, fontWeight: 'bold' }}>
                      클릭 후
                    </HelpContent>
                    <img
                      src={seminarIcon}
                      alt='seminar'
                      style={{ marginRight: 15 }}
                    />
                    <HelpContent style={{ fontSize: 15, fontWeight: 'bold' }}>
                      클릭
                    </HelpContent>
                  </HelpArticle>
                </HelpContainer>
                <ButtonArticle>
                  <ExitButton
                    onClick={() => {
                      setData({
                        title: '',
                        seminar: false,
                      });
                    }}
                  >
                    확인
                  </ExitButton>
                </ButtonArticle>
              </BodyArticle>
            </WaitingArticle>
          </WaitingContainer>
        </>
      )}
    </>
  );
};

export default OpenWaiting;

import React from 'react';
import SeminarPopup from './Seminar/Seminar';
import {
  Mobile100vh,
  ContentArticle,
  ClosePopupContainer,
  ClosePopupArticle,
  Title,
  SubTitle,
  ButtonWrapper,
  SaveButton,
  CancelButton,
} from './ContentStyled';

const Content = ({ handleClose, handleIsView, isView }) => {
  return (
    <>
      <Mobile100vh>
        <ContentArticle>
          <SeminarPopup />
        </ContentArticle>
      </Mobile100vh>
      {isView && (
        <ClosePopupContainer>
          <ClosePopupArticle>
            <div
              style={{
                marginBottom: 47,
                position: 'relative',
                width: '100%',
                height: 'auto',
              }}
            >
              <Title
                style={{ borderBottom: '2px solid #000', paddingBottom: 12 }}
              >
                세미나실
              </Title>
              <Title> 나가기</Title>
            </div>
            <SubTitle>쇼룸으로 돌아가시겠습니까? </SubTitle>
            <ButtonWrapper>
              <SaveButton
                onClick={() => {
                  handleClose();
                }}
              >
                확인
              </SaveButton>
              <CancelButton
                onClick={() => {
                  handleIsView(false);
                }}
              >
                취소
              </CancelButton>
            </ButtonWrapper>
          </ClosePopupArticle>
        </ClosePopupContainer>
      )}
    </>
  );
};

export default Content;

import React, { useState } from 'react';
import Header from './Header';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import axios from '../../../contants/axios';

import {
  ObjectQnAContainer,
  ContentForm,
  TitleInput,
  ContentArea,
  NoticeText,
  SendContainer,
  SendButton,
} from './styled/ObjectQnAStyled';

const ObjectQnA = ({ productName, category }) => {
  const key = 'sending';
  const { email, company, industry, phone } = useSelector(
    (state) => state.auth,
  );
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { title, content } = event.target;
    message.loading({ content: 'Q&A 요청을 처리 중입니다.', key });

    if (title.value !== '' && content.value !== '' && loading) {
      setLoading(false);
      await axios({
        method: 'POST',
        url: '/email',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          from: 'no-reply@rittal-exhibition.co.kr',
          subject: `[Q&A] ${title.value}`,
          to: 'mkt@rittal.co.kr',
          replyTo: email,
          cc: '',
          bcc: '',
          text: `
          리탈 가상 전시관에서 발송된 Q&A 입니다.<br/>
          답장은 문의하신 고객님의 주소로 연결됩니다.
          <br/><br/>
          회사명 : ${company}<br/>
          산업군 : ${industry}<br/>
          이메일 : ${email}<br/>
          연락처 : ${phone}<br/>
          제품명 : ${productName}<br/>
          <br/>
          내용 : ${content.value}
          `,
          html: `
          리탈 가상 전시관에서 발송된 Q&A 입니다.<br/>
          답장은 문의하신 고객님의 주소로 연결됩니다.
          <br/><br/>
          회사명 : ${company}<br/>
          산업군 : ${industry}<br/>
          이메일 : ${email}<br/>
          연락처 : ${phone}<br/>
          제품명 : ${productName}<br/>
          <br/>
          내용 : ${content.value}
          `,
        },
      })
        .then((response) => {
          message.success({
            content: 'Q&A 요청을 완료 했습니다.',
            key,
            duration: 2,
          });
          event.target.reset();
          setLoading(true);

          //eslint-disable-next-line no-undef
          gtag('event', 'send_email', {
            event_category: `QnA_email`,
            event_label: `${category}|${productName}`,
          });
        })
        .catch((error) => {
          message.error({
            content: '없는 이메일 이거나, 잘못된 요청입니다.',
            key,
          });
          setLoading(true);
        });
    } else if (title.value === '' || content.value === '') {
      message.error({
        content: 'Q&A 작성에 실패했거나 빈칸이 있습니다.',
        key,
        duration: 2,
      });
    }
  };

  return (
    <>
      <ObjectQnAContainer onSubmit={handleSubmit}>
        <Header title={'Q & A'} />
        <ContentForm>
          <TitleInput placeholder='제목 *' type='text' name='title' />
          <ContentArea placeholder='내용' type='text' name='content' />
          <NoticeText>※ 회신은 입장하실때 정보로 회신드립니다.</NoticeText>
          <SendContainer>
            <SendButton type='submit'>보내기</SendButton>
          </SendContainer>
        </ContentForm>
      </ObjectQnAContainer>
    </>
  );
};

export default ObjectQnA;

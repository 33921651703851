import styled from 'styled-components';

const FindPasswordArticle = styled.div`
  position: relative;
  width: 45%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;

  @media (max-width: 1100px) {
    width: 65%;
  }

  @media (max-width: 850px) {
    border-radius: 0;
  }

  @media (max-width: 767px) {
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
  }
`;

const FindPasswordTitle = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  background: #373737;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;

  @media (max-width: 850px) {
    border-radius: 0;
  }
`;

export { FindPasswordArticle, FindPasswordTitle };

import React, { useEffect, useState } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import useAuth from '../hooks/useAuth';

import SigninForm from '../components/Signin/SigninForm';
import OpenWaiting from '../components/Signin/OpenWaiting';

import { SigninArticle } from './styled/SigninStyled';

import SignupForm from '../components/Signup/SignupForm';
import PrivacyForm from '../components/Signup/PrivacyForm';

import { SignupArticle, SignupTitle } from './styled/SignupStyled';

import FindPasswordForm from '../components/ResetPassword/FindPasswordForm';
import ResetPasswordForm from '../components/ResetPassword/ResetPasswordForm';
import ConfirmPasswordForm from '../components/ResetPassword/ConfirmPasswordForm';

import {
  FindPasswordArticle,
  FindPasswordTitle,
} from './styled/FindPasswordStyled';

import rending_video_01 from '../assets/video/rendered_bg_video_01.mp4';
import rending_video_02 from '../assets/video/rendered_bg_video_02.mp4';
import rending_video_03 from '../assets/video/rendered_bg_video_03.mp4';
import rending_video_04 from '../assets/video/rendered_bg_video_04.mp4';

import poster01 from '../assets/video/poster01.png';
import poster02 from '../assets/video/poster02.png';
import poster03 from '../assets/video/poster03.png';
import poster04 from '../assets/video/poster04.png';

import pattern from '../assets/video/pattern-2px.png';

import logo from '../assets/RITTAL_logo.png';

import {
  RendingContainer,
  UIArticle,
  LeftUIWrapper,
  RightUIWrapper,
  CoverArticle,
  VideoArticle,
  Video,
  Logo,
  Title,
  SubTitle,
  SigninButton,
  SignupButton,
} from './styled/RendingPageStyled';

const videoSource = [
  { video: rending_video_01, poster: poster01 },
  { video: rending_video_02, poster: poster02 },
  { video: rending_video_03, poster: poster03 },
  { video: rending_video_04, poster: poster04 },
];

const RendingPage = () => {
  useAuth(false);

  // rending
  const [page, setPage] = useState(1);
  const [assets, setAssets] = useState({
    video: '',
    poster: '',
  });

  // signup
  const [popup, setPopup] = useState(false);
  const [number, setNumber] = useState('1');

  const isView = (state) => {
    setPopup(state);
  };

  const isNumber = (state) => {
    setNumber(state);
  };

  // findPassword
  const [current, setCurrent] = useState(0);

  const isCurrent = (state) => {
    setCurrent(state);
  };

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 4);

    return setAssets(videoSource[randomNumber]);
  }, []);

  return (
    <RendingContainer>
      {page === 1 ? (
        <UIArticle>
          <LeftUIWrapper>
            <Logo src={logo} alt='logo' />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 15,
              }}
            >
              <Title>Rittal</Title>
              <SubTitle>Digital</SubTitle>
              <SubTitle>Showroom</SubTitle>
            </div>
          </LeftUIWrapper>
          <RightUIWrapper>
            <SigninButton onClick={() => setPage(2)}>
              <div style={{ display: 'flex' }}>
                <span style={{ width: '100%' }}>로그인</span>
                <RightCircleOutlined style={{ width: '45%' }} />
              </div>
            </SigninButton>
            <SignupButton onClick={() => setPage(3)}>
              <div style={{ display: 'flex' }}>
                <span style={{ width: '100%' }}>회원가입</span>
                <RightCircleOutlined style={{ width: '45%' }} />
              </div>
            </SignupButton>
          </RightUIWrapper>
        </UIArticle>
      ) : page === 2 ? (
        <>
          <OpenWaiting />
          <SigninArticle>
            <SigninForm setPage={setPage} />
          </SigninArticle>
        </>
      ) : page === 3 ? (
        <>
          <SignupArticle popup={popup}>
            <SignupTitle>회원가입</SignupTitle>
            <SignupForm isView={isView} isNumber={isNumber} setPage={setPage} />
          </SignupArticle>
          {popup && <PrivacyForm isView={isView} number={number} />}
        </>
      ) : (
        <FindPasswordArticle>
          {current === 0 ? (
            <>
              <FindPasswordTitle>비밀번호 찾기</FindPasswordTitle>
              <FindPasswordForm isCurrent={isCurrent} setPage={setPage} />
            </>
          ) : current === 1 ? (
            <>
              <FindPasswordTitle>비밀번호 찾기</FindPasswordTitle>
              <ResetPasswordForm isCurrent={isCurrent} setPage={setPage} />
            </>
          ) : current === 2 ? (
            <>
              <FindPasswordTitle>비밀번호 찾기</FindPasswordTitle>
              <ConfirmPasswordForm setPage={setPage} />
            </>
          ) : (
            ''
          )}
        </FindPasswordArticle>
      )}
      <CoverArticle style={{ background: `url(${pattern})` }} />
      <VideoArticle>
        <Video
          poster={assets.poster}
          loop={true}
          autoPlay={true}
          muted={true}
          playsInline={true}
          src={assets.video}
        >
          <source src={assets.video} type='video/mp4' />
        </Video>
      </VideoArticle>
    </RendingContainer>
  );
};

export default RendingPage;

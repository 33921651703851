import React, { useRef, useState, useEffect } from 'react';
import { Carousel, Image, Tooltip } from 'antd';
import loadingImg from '../../../assets/load.svg';
import ReactPlayer from 'react-player';
import searchIcon from '../../../assets/search-icon.svg';
import { API_URL } from '../../../contants/axios';

import {
  ObjectImageContainer,
  ImageArticle,
  VideoArticle,
  SerchIcon,
  LoadingDiv,
  ThumbnailContainer,
  ImageThumbnail,
  VideoThumbnail,
  PlayButton,
  MutedPopup,
} from './styled/ObjectImageStyled';

const ObjectImage = ({ media }) => {
  const carouselRef = useRef();
  const [isView, setIsView] = useState(true);
  const [video, setVideo] = useState('');
  const [isIndex, setIsIndex] = useState({
    from: 0,
    to: 0,
  });

  useEffect(() => {
    if (isView === true) {
      setTimeout(() => {
        setIsView(false);
      }, 3000);
    }
  }, [isView]);

  useEffect(() => {
    if (media) {
      media.sort((a, b) => {
        if (a.mime === b.mime) {
          return 0;
        }
        return a.mime > b.mime ? 1 : -1;
      });
    }
  }, [media]);

  const handleChange = (index) => {
    carouselRef.current.goTo(index);
  };

  const handleBeforeChange = (from, to) => {
    setIsIndex({
      from,
      to,
    });
  };

  useEffect(() => {
    const videoUrls = [];
    if (media !== undefined) {
      media.forEach((data, index) => {
        if (data.mime.substr(0, 5) === 'video') {
          return videoUrls.push({
            index,
            url: data.url,
            mime: data.mime,
          });
        }
      });
    }
    setVideo(videoUrls);
    return () => {
      setVideo('');
    };
  }, [media]);

  return (
    <ObjectImageContainer>
      <Carousel
        dots={false}
        ref={carouselRef}
        draggable={false}
        swipe={false}
        touchMove={false}
        beforeChange={handleBeforeChange}
      >
        {media !== undefined && media.length > 0 ? (
          media.map((data, index) => {
            const { mime } = data;
            if (mime.substr(0, 5) === 'image') {
              return (
                <div key={index}>
                  {data.formats.medium !== undefined ? (
                    <ImageArticle
                      style={{
                        backgroundImage: `url(${API_URL}${data.formats.medium.url})`,
                      }}
                    >
                      <Image
                        width={'100%'}
                        height={'100%'}
                        src={`${API_URL}${data.url}`}
                        style={{
                          opacity: '0',
                          zoom: '1',
                          filter: 'alpha(opacity=0)',
                        }}
                        crossOrigin='anonymous'
                      />
                      <Tooltip
                        title='이미지를 클릭하면 확대됩니다.'
                        color={'#E50043'}
                      >
                        <SerchIcon icon={searchIcon} />
                      </Tooltip>
                    </ImageArticle>
                  ) : (
                    <ImageArticle
                      style={{
                        backgroundImage: `url(${API_URL}${data.formats.small.url})`,
                      }}
                    >
                      <Image
                        width={'100%'}
                        height={'100%'}
                        src={`${API_URL}${data.url}`}
                        style={{
                          opacity: '0',
                          zoom: '1',
                          filter: 'alpha(opacity=0)',
                        }}
                        crossOrigin='anonymous'
                      />
                      <Tooltip
                        title='이미지를 클릭하면 확대됩니다.'
                        color={'#E50043'}
                      >
                        <SerchIcon icon={searchIcon} />
                      </Tooltip>
                    </ImageArticle>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div>
            <LoadingDiv style={{ backgroundImage: `url(${loadingImg})` }}>
              <img src={loadingImg} alt='loading...' style={{ opacity: 0 }} />
            </LoadingDiv>
          </div>
        )}
        {video !== '' && video !== undefined
          ? video.map((video) => {
              return (
                <div key={video.index}>
                  <VideoArticle>
                    <ReactPlayer
                      className='popup-video'
                      width={'100%'}
                      height={'100%'}
                      playing={isIndex.to === video.index}
                      url={[
                        {
                          src: `${API_URL}${video.url}`,
                          type: `${video.mime}`,
                        },
                      ]}
                      config={{
                        file: {
                          attributes: {
                            controls: true,
                            crossOrigin: 'anonymous',
                            muted: true,
                            preload: 'metadata',
                            playsInline: true,
                            controlsList: 'nodownload',
                            disablePictureInPicture: true,
                          },
                        },
                      }}
                    ></ReactPlayer>
                    {isView === true ? (
                      <MutedPopup tabIndex='-1'>
                        음소거를 해지하면 음성 설명을 들을 수 있습니다.
                      </MutedPopup>
                    ) : (
                      ''
                    )}
                  </VideoArticle>
                </div>
              );
            })
          : ''}
      </Carousel>
      <ThumbnailContainer>
        {media !== undefined && media.length > 0
          ? media.map((data, index) => {
              const { mime } = data;
              if (mime.substr(0, 5) === 'image') {
                return (
                  <ImageThumbnail
                    key={index}
                    image={data.formats.thumbnail.url}
                    onClick={() => {
                      handleChange(index);
                      setIsView(false);
                    }}
                  />
                );
              } else {
                return (
                  <VideoThumbnail
                    key={index}
                    onClick={() => {
                      handleChange(index);
                      setIsView(true);
                    }}
                  >
                    <PlayButton />
                  </VideoThumbnail>
                );
              }
            })
          : ''}
      </ThumbnailContainer>
    </ObjectImageContainer>
  );
};

export default ObjectImage;

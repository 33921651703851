export const SET_SETTING = 'SET_SETTING';

export const setSetting = (action) => ({
  type: SET_SETTING,
  payload: action,
});

export const SET_MUSIC = 'SET_MUSIC';

export const setMusic = (action) => ({
  type: SET_MUSIC,
  payload: action,
});

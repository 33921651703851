import styled from 'styled-components';
import { Tabs } from 'antd';

const ArchiveContianer = styled.div`
  position: relative;
  width: 65vw;
  min-width: 55vw;
  height: 65vh;
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 1200px) {
    width: 90vw;
  }

  @media (max-width: 767px) {
    width: 100%;
    min-width: 100%;
    height: 100%;
    flex-direction: column-reverse;
  }
`;

const TabStyle = styled(Tabs)`
  width: 100%;

  @media (max-width: 767px) {
    height: 100%;
    min-height: 0;
    flex: 1;
  }
`;

const MobileTabClose = styled.div`
  display: none;
  position: relative;
  background-color: #000;
  border: 0;

  @media(max-width: 767px) {
    display: block;
    width: 100%;
    height: 35px;
    min-height: 0;

    &:hover {
      cursor: pointer;
      border-left: 0;
      color: #E50043;
    }
`;

const MobileCloseButton = styled.button`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    display: block;
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
    color: #000;
    background-image: url(${(props) => props.img});
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

export { ArchiveContianer, TabStyle, MobileTabClose, MobileCloseButton };

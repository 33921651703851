import styled from 'styled-components';

const HelpContainer = styled.div`
  position: relative;
  width: 75vw;
  height: 75vh;
  background: #fff;

  @media (max-width: 950px) {
    max-width: 700px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: relative;
  float: right;
  width: 28px;
  height: 28px;
  color: #000;
  background-image: url(${(props) => props.img});
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const TitleArticle = styled.div`
  position: relative;
  width: 100%;
  padding: 15px 30px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
`;

const Title = styled.span`
  width: 100%;
  font-size: 18px;
  color: #fff;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const ContentArticle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const LeftArrow = styled.button`
  width: 28px;
  position: relative;
  background-color: transparent;
  border: 0;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20%;
    border-right: 2px solid #cbc9c9;
    border-bottom: 2px solid #cbc9c9;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &:hover:after {
    left: 10%;
    border-right: 2px solid #e50043;
    border-bottom: 2px solid #e50043;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const RightArrow = styled.button`
  width: 28px;
  position: relative;
  background-color: transparent;
  border: 0;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 80%;
    border-left: 2px solid #cbc9c9;
    border-top: 2px solid #cbc9c9;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &:hover:after {
    left: 90%;
    border-left: 2px solid #e50043;
    border-top: 2px solid #e50043;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const ImageArticle = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export {
  HelpContainer,
  TitleArticle,
  Title,
  CloseButton,
  ContentArticle,
  LeftArrow,
  ImageArticle,
  RightArrow,
};

import React, { useState, useEffect } from 'react';
import closeImg from '../../../assets/closewhite.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';
import axios, { API_URL } from '../../../contants/axios';

import Mission from './Mission';
import Subscribe from './Subscribe';

import {
  StampContianer,
  TitleArticle,
  Title,
  CloseButton,
  Content,
  BannerContianer,
} from './styled/StampPopupStyled';

const StampPopup = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [userStamp, setUserStamp] = useState([]);
  const [userCheck, setUserCheck] = useState(false);
  const { email, username, token } = useSelector((state) => state.auth);
  const { stampBanner } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      (async () => {
        const response = await axios({
          method: 'get',
          url: `/users/me`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userList = response.data.userStamp || '';
        if (userList !== '' && userList !== undefined) {
          setUserStamp(userList.split('|'));
        }
      })();

      (async () => {
        const response = await axios({
          method: 'get',
          url: `/subscribes?email=${email}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.length > 0) {
          setUserCheck(true);
        }
      })();
    }

    return () => {
      setLoading(false);
    };
  }, [token, current, email, loading]);

  const isCurrent = (state) => {
    setCurrent(state);
  };

  return (
    <>
      <StampContianer>
        <TitleArticle>
          <Title>스탬프</Title>
          <CloseButton
            onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
            img={closeImg}
          />
        </TitleArticle>
        <Content>
          {current === 0 ? (
            <Mission
              isCurrent={isCurrent}
              userStamp={userStamp}
              userCheck={userCheck}
            />
          ) : (
            <Subscribe email={email} username={username} />
          )}
        </Content>
        {stampBanner !== '' ? (
          <BannerContianer>
            <img
              src={`${API_URL}${stampBanner[0].url}`}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              alt=''
            />
          </BannerContianer>
        ) : (
          ''
        )}
      </StampContianer>
    </>
  );
};

export default StampPopup;

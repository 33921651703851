import React, { useRef, useState } from 'react';
import closeImg from '../../../assets/closewhite.svg';
import { Carousel, Image } from 'antd';

import help1 from '../../../assets/help/help-mobile-1.png';
import help2 from '../../../assets/help/help-mobile-2.png';
import help3 from '../../../assets/help/help-mobile-3.png';
import help4 from '../../../assets/help/help-mobile-4.png';
import help5 from '../../../assets/help/help-mobile-5.png';
import help6 from '../../../assets/help/help-mobile-6.png';
import help7 from '../../../assets/help/help-mobile-7.png';
import help8 from '../../../assets/help/help-mobile-8.png';
import help9 from '../../../assets/help/help-mobile-9.png';
import help10 from '../../../assets/help/help-mobile-10.png';
import help11 from '../../../assets/help/help-mobile-11.png';
import help12 from '../../../assets/help/help-mobile-12.png';
import help13 from '../../../assets/help/help-mobile-12.png';

import {
  MobileHelpContainer,
  MobileTitle,
  PartArticle,
  MainPart,
  KioskPart,
  ManualPart,
  MobileCloseButton,
  MobileArticle,
  LeftArrows,
  RightArrows,
  Content,
  ImageArticle,
} from './styled/MobileHelpStyled';

const MobileHelp = ({ isCurrent }) => {
  const MobilecarouselRef = useRef();
  const [part, setPart] = useState({
    main: true,
    kiosk: false,
    manual: false,
  });

  const handleOnChange = (event) => {
    if (event < 7) {
      setPart({
        main: true,
        kiosk: false,
        manual: false,
      });
    } else if (event < 10) {
      setPart({
        main: false,
        kiosk: true,
        manual: false,
      });
    } else {
      setPart({
        main: false,
        kiosk: false,
        manual: true,
      });
    }
  };

  return (
    <MobileHelpContainer>
      <MobileTitle>도움말</MobileTitle>
      <PartArticle>
        <MainPart
          part={part.main}
          onClick={() => {
            setPart({
              main: true,
              kiosk: false,
              manual: false,
            });
            MobilecarouselRef.current.goTo(0);
          }}
        >
          메인화면
        </MainPart>
        <KioskPart
          part={part.kiosk}
          onClick={() => {
            setPart({
              main: false,
              kiosk: true,
              manual: false,
            });
            MobilecarouselRef.current.goTo(7);
          }}
        >
          키오스크화면
        </KioskPart>
        <ManualPart
          part={part.manual}
          onClick={() => {
            setPart({
              main: false,
              kiosk: false,
              manual: true,
            });
            MobilecarouselRef.current.goTo(10);
          }}
        >
          조작방법
        </ManualPart>
      </PartArticle>
      <MobileCloseButton onClick={() => isCurrent(0)} img={closeImg} />
      <Carousel
        ref={MobilecarouselRef}
        className='mobile-carousel'
        afterChange={handleOnChange}
      >
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.goTo(11)} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help1})` }}>
                <Image
                  width={'auto'}
                  src={help1}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help2})` }}>
                <Image
                  width={'auto'}
                  src={help2}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help3})` }}>
                <Image
                  width={'auto'}
                  src={help3}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help4})` }}>
                <Image
                  width={'auto'}
                  src={help4}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help5})` }}>
                <Image
                  width={'auto'}
                  src={help5}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help6})` }}>
                <Image
                  width={'auto'}
                  src={help6}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help7})` }}>
                <Image
                  width={'auto'}
                  src={help7}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help8})` }}>
                <Image
                  width={'auto'}
                  src={help8}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help9})` }}>
                <Image
                  width={'auto'}
                  src={help9}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help10})` }}>
                <Image
                  width={'auto'}
                  src={help10}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help11})` }}>
                <Image
                  width={'auto'}
                  src={help11}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.next()} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help12})` }}>
                <Image
                  width={'auto'}
                  src={help12}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.goTo(0)} />
          </MobileArticle>
        </div>
        <div>
          <MobileArticle>
            <LeftArrows onClick={() => MobilecarouselRef.current.prev()} />
            <Content>
              <ImageArticle style={{ backgroundImage: `url(${help13})` }}>
                <Image
                  width={'auto'}
                  src={help13}
                  style={{
                    opacity: '0',
                    zoom: '1',
                    filter: 'alpha(opacity=0)',
                  }}
                />
              </ImageArticle>
            </Content>
            <RightArrows onClick={() => MobilecarouselRef.current.goTo(0)} />
          </MobileArticle>
        </div>
      </Carousel>
    </MobileHelpContainer>
  );
};

export default MobileHelp;

import React from 'react';
import StampBtnImg from '../../../assets/header/stamp.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';

import {
  StampDiv,
  StampBtn,
  StampImg,
  StampLine,
  StampCount,
} from './styled/StampButton';

const StampButton = ({ stampCount }) => {
  const dispatch = useDispatch();
  const count = stampCount.length;
  const { type, status } = useSelector((state) => state.modals);
  const { stamp: stampSwitch } = useSelector((state) => state.settings);

  return (
    <>
      {stampSwitch === true ? (
        <>
          <StampDiv stampSwitch={stampSwitch}>
            {type === 'stamp' && status === 'show' ? (
              <StampBtn
                stampSwitch={stampSwitch}
                onClick={() => {
                  dispatch(setModal({ type: '', status: 'hide' }));
                }}
              >
                <StampImg src={StampBtnImg} alt='Stamp' />
              </StampBtn>
            ) : (
              <StampBtn
                stampSwitch={stampSwitch}
                onClick={() => {
                  dispatch(setModal({ type: 'stamp', status: 'show' }));
                }}
              >
                <StampImg src={StampBtnImg} alt='Stamp' />
              </StampBtn>
            )}
          </StampDiv>
          <StampLine />
          {count < 6 ? (
            <StampCount>{count} / 6</StampCount>
          ) : (
            <StampCount>완료</StampCount>
          )}
        </>
      ) : (
        <>
          <StampDiv stampSwitch={stampSwitch}>
            <StampBtn stampSwitch={stampSwitch} onClick={() => {}}>
              <StampImg src={StampBtnImg} alt='Stamp' />
            </StampBtn>
          </StampDiv>
          <StampLine />
          <StampCount>종료</StampCount>
        </>
      )}
    </>
  );
};

export default StampButton;

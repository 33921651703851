import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';
import {
  LogoutContainer,
  MobileTitle,
  Title,
  Content,
  MainText,
  ButtonWrapper,
  SaveButton,
  CancelButton,
} from './LogoutPopupStyled';
import { sendUserHistory } from '../../../contants/history';

const LogoutPopup = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);

  const logout = () => {
    if (data) {
      sendUserHistory(data, '로그아웃');
    }
    sessionStorage.removeItem('token');
    document.location.href = '/';
  };

  return (
    <LogoutContainer>
      <MobileTitle>로그아웃</MobileTitle>
      <Title>로그아웃</Title>
      <Content>
        <MainText>정말 로그아웃 하시겠습니까?</MainText>
        <ButtonWrapper>
          <SaveButton onClick={() => logout()}>확인</SaveButton>
          <CancelButton
            onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
          >
            취소
          </CancelButton>
        </ButtonWrapper>
      </Content>
    </LogoutContainer>
  );
};

export default LogoutPopup;

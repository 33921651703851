import styled from 'styled-components';
import Bg from '../../assets/bg.png';

const ReplyContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${Bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const ReplyArticle = styled.div`
  position: relative;
  width: 40%;
  height: 45vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 60px 80px;

  @media (max-width: 1560px) {
    width: 45%;
  }

  @media (max-width: 1390px) {
    width: 50%;
  }

  @media (max-width: 1250px) {
    width: 60%;
  }

  @media (max-width: 1040px) {
    width: 70%;
  }

  @media (max-width: 890px) {
    width: 80%;
  }

  @media (max-width: 780px) {
    width: 100%;
    height: 100vh;
    padding: 30px;
  }
`;

const BodyArticle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow: auto;
`;

const Title = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #000000;

  &:after {
    content: '';
    display: block;
    border-bottom: 2px solid #000;
    margin: 15px 0px 16px 0px;
  }
`;

const Content = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  word-break: break-word;
  white-space: pre-line;
  margin-top: 12px;
  padding: 0;
`;

export { ReplyContainer, ReplyArticle, BodyArticle, Title, Content };

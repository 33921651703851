import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setObjectBar, setModal } from '../../../actions/modals.actions';
import { moveProduct } from '../../../actions/move.actions';
import { Collapse } from 'antd';
import mobileCloseImg from '../../../assets/closewhite.svg';

import {
  ObjectArticle,
  ObjectBtn,
  ObjectContent,
  ObjectTitle,
  ObjectTitleText,
  ObjectListContainer,
  ObjectList,
  MobileCloseButton,
} from './styled/ObjectBarStyled';

const removeList = [
  'AE1034',
  'AE1280',
  'AE1090',
  'RiLine 콤펙트 버스바 시스템',
  'Blue e+ Cooling Unit_11',
  'CMC Ⅲ PU & Sensor',
  '콤팩트 인클로저 AE (Fitting)',
  'Bus Duct',
  'Fan & Filter',
  'RAS Semi-Auto',
  '모듈러 데이터센터 도어',
  '디지털 트윈',
];

const { Panel } = Collapse;

const ObjectBar = () => {
  const { status } = useSelector((state) => state.objectBar);
  const dispatch = useDispatch();
  const { productlist } = useSelector((state) => state.productlist);
  const [grouped, setGrouped] = useState('');

  useEffect(() => {
    if (productlist !== null) {
      const objectGroup = productlist.reduce((acc, cur) => {
        if (
          !acc.hasOwnProperty(cur.category2) &&
          cur.productName !== 'RiLine 콤펙트 버스바 시스템'
        ) {
          acc[cur.category2] = [cur];
        } else if (removeList.indexOf(cur.productName) < 0) {
          acc[cur.category2].push(cur);
        }
        return acc;
      }, {});
      setGrouped(objectGroup);
    }
  }, [productlist]);

  return (
    <ObjectArticle show={status} tabIndex='-1'>
      <ObjectBtn
        onClick={() => {
          status === 'hide'
            ? dispatch(setObjectBar({ status: 'show' }))
            : dispatch(setObjectBar({ status: 'hide' }));
        }}
        className={status}
      />
      <ObjectContent show={status}>
        <ObjectTitle>
          <ObjectTitleText>제품리스트</ObjectTitleText>
          <MobileCloseButton
            onClick={() => {
              status === 'hide'
                ? dispatch(setObjectBar({ status: 'show' }))
                : dispatch(setObjectBar({ status: 'hide' }));
            }}
            img={mobileCloseImg}
          />
        </ObjectTitle>
        <ObjectListContainer>
          <Collapse ghost expandIconPosition={'end'} accordion>
            {grouped &&
              // eslint-disable-next-line array-callback-return
              Object.keys(grouped).map((category2, key) => {
                if (category2 !== 'etc') {
                  const count = Object.values(grouped).map((i) => {
                    return i.length;
                  });
                  return (
                    <Panel
                      header={`${category2}(${count[key]})`}
                      key={category2}
                    >
                      {productlist.map((data, index) => {
                        if (
                          data.category2 === category2 &&
                          removeList.indexOf(data.productName) < 0
                        ) {
                          return (
                            <ObjectList
                              value={data.id}
                              key={index}
                              onClick={(e) => {
                                dispatch(moveProduct(e.target.value));
                                dispatch(setObjectBar({ status: 'hide' }));
                                dispatch(
                                  setModal({ type: '', status: 'hide' }),
                                );
                                setTimeout(() => {
                                  dispatch(moveProduct(''));
                                }, 1000);
                              }}
                            >
                              {data.productName}
                            </ObjectList>
                          );
                        }
                        return null;
                      })}
                    </Panel>
                  );
                }
              })}
          </Collapse>
        </ObjectListContainer>
      </ObjectContent>
    </ObjectArticle>
  );
};

export default ObjectBar;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import closeImg from '../../assets/closewhite.svg';
import stampImg from '../../assets/stamprittal.svg';
import { useSelector } from 'react-redux';
import axios, { API_URL } from '../../contants/axios';

const PreventionContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5.43656px);
`;

const PreventionArticle = styled.div`
  position: relative;
  width: 40%;
  height: 50vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;

const HeaderArticle = styled.div`
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direcion: row;
  align-items: center;
  padding: 25px 25px;
`;

const Title = styled.span`
  width: 100%;
  color: #fff;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const CloseButton = styled.button`
  position: relative;
  float: right;
  width: 28px;
  height: 28px;
  color: #000;
  background-image: url(${(props) => props.img});
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    width: 24px;
    height: 24px;
  }
`;

const BodyArticle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
`;

const Content1 = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;
  color: #000;

  @media (max-width: 767px) {
    font-size: 20px;
  }

  @media (max-height: 700px) {
    font-size: 20px;
  }
`;

const Content2 = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-top: 30px;

  @media (max-width: 767px) {
    font-size: 16px;
  }

  @media (max-height: 700px) {
    font-size: 16px;
    margin-top: 20px;
  }
`;

const Content3 = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #e50043;
  margin-top: 30px;

  @media (max-width: 767px) {
    font-size: 18px;
  }

  @media (max-height: 700px) {
    font-size: 18px;
    margin-top: 20px;
  }
`;

const ExitButton = styled.button`
  background: #f8f8f8;
  border: none;
  width: 160px;
  height: 40px;
  color: #666666;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-top: 40px;
  border: 1px solid #cbc9c9;

  &:hover {
    background: #e50043;
    color: #fff;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-height: 700px) {
    margin-top: 20px;
  }
`;

const BannerContianer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color: #000;

  @media (max-width: 767px) {
    height: 90px;
    flex: auto;
  }

  @media (max-height: 850px) {
    height: 90px;
  }
`;

const PreventionPopup = ({ handleIsView }) => {
  const { stampBanner } = useSelector((state) => state.settings);
  const [stamp, setStamp] = useState('');
  const token = sessionStorage.getItem('token');
  const [isView, setIsView] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsView(true);
    }, 480 * 1000); // 8분 후 동작

    return () => {
      clearInterval();
    };
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios({
        method: 'get',
        url: `/users/me`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const list = response.data.userStamp || '';
      if (list !== '' && list !== undefined) {
        setStamp(list.split('|'));
      }
    })();
  }, [token]);

  return (
    <>
      {isView ? (
        <PreventionContainer>
          <PreventionArticle>
            <HeaderArticle>
              <Title>안내 팝업</Title>
              <CloseButton img={closeImg} onClick={() => setIsView(false)} />
            </HeaderArticle>
            <BodyArticle>
              <Content1>잠시만요!</Content1>
              <Content2>
                선물 받을 수 있는 스탬프 미션을 완성하세요!
                <br />
                5초면 스탬프를 받을 수 있습니다.
              </Content2>
              <Content3>
                <img src={stampImg} style={{ marginRight: 10 }} alt='stamp' />{' '}
                나의 스탬프 현황{' '}
                <Content3 style={{ marginLeft: 10 }}>{stamp.length}/6</Content3>
              </Content3>
              <ExitButton onClick={() => handleIsView(false)}>닫기</ExitButton>
            </BodyArticle>
            {stampBanner !== '' ? (
              <BannerContianer>
                <img
                  src={`${API_URL}${stampBanner[0].url}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  alt=''
                />
              </BannerContianer>
            ) : (
              ''
            )}
          </PreventionArticle>
        </PreventionContainer>
      ) : (
        ''
      )}
    </>
  );
};

export default PreventionPopup;

import React from 'react';

import {
  TitleArticle,
  UserName,
  ChatTime,
  ContentArticle,
  Content,
} from './MessageBoxStyled';

const MessageOtherClient = ({ defaultMessage }) => {
  return (
    <div key={defaultMessage.userId}>
      <TitleArticle>
        <UserName
          style={{
            width: 120,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
          }}
        >{`${defaultMessage.username} / ${defaultMessage.company}`}</UserName>
        <ChatTime>{defaultMessage.time}</ChatTime>
      </TitleArticle>
      <ContentArticle>
        <Content>{defaultMessage.userMessage}</Content>
      </ContentArticle>
    </div>
  );
};

export default MessageOtherClient;

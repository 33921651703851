import { LOAD_STAMP, GET_STAMP } from '../actions/stamp.actions';

const initialState = {
  loading: false,
};

export default function stamp(state = initialState, action) {
  switch (action.type) {
    case LOAD_STAMP:
      return {
        loading: true,
      };
    case GET_STAMP:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

import { SET_SETTING, SET_MUSIC } from '../actions/setting.actions';

const initialState = {
  stamp: false,
  tour: false,
  semina: false,
  seminaLink: '',
  stampBanner: '',
  seminarAgenda: '',
  seminarTitle: '',
  seminarVideo: '',
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case SET_SETTING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const musicInitialState = {
  music: true,
};

export function musicToggle(state = musicInitialState, action) {
  switch (action.type) {
    case SET_MUSIC:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

import styled, { css } from 'styled-components';

const SignupWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 35px;

  @media (max-width: 797px) {
    padding: 25px;
  }
`;

const BackLink = styled.span`
  color: #000;
  font-size: 12px;
  font-family: Noto Sans KR;
  font-weight: bold;
  font-style: normal;
  line-height: 22px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #e50043;
    cursor: pointer;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 8px 6px 0;
    border-color: transparent #e50043 transparent transparent;
    margin-right: 8px;
  }
`;

const NameInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  border: ${(props) =>
    props.error === 'name'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'name' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-radius: 2px;

  margin-bottom: ${(props) => (props.error === 'name' ? '0' : '12px')};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PhoneArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.error === 'phone' ? '0' : '12px')};
  color: #000;
`;

const PhoneSelect = styled.select`
  width: 20%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  background-color: ${(props) =>
    props.error === 'phone' ? 'rgba(252, 201, 204, 0.21)' : '#F6F6F6'};
  border-left: ${(props) =>
    props.error === 'phone'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-top: ${(props) =>
    props.error === 'phone'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-bottom: ${(props) =>
    props.error === 'phone'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-right: 0;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  @media (max-width: 767px) {
    width: 40%;
  }

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PhoneInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  background-color: ${(props) =>
    props.error === 'phone' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-left: ${(props) =>
    props.error === 'phone' ? '0' : '1px solid rgba(0, 0, 0, 0.13)'};
  border-right: ${(props) =>
    props.error === 'phone'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-top: ${(props) =>
    props.error === 'phone'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-bottom: ${(props) =>
    props.error === 'phone'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  border-radius: 2px;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const EmailArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
  margin-bottom: 12px;
  color: #000;

  ${(props) =>
    props.error === 'email' &&
    css`
      margin-bottom: 0;
    `}

  ${(props) =>
    props.isDone &&
    css`
      margin-bottom: 0;
    `}
`;

const EmailInput = styled.input`
  width: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 0;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  ${(props) =>
    props.error === 'email' &&
    css`
      border-left: 1px solid #ec4c47;
      border-top: 1px solid #ec4c47;
      border-bottom: 1px solid #ec4c47;
      background-color: rgba(252, 201, 204, 0.21);
    `}

  ${(props) =>
    props.isDone &&
    css`
      border-left: 1px solid #31992e;
      border-top: 1px solid #31992e;
      border-bottom: 1px solid #31992e;
      background-color: rgba(49, 153, 46, 0.1);
    `}
`;

const AtInput = styled.input`
  width: 15%;
  height: 40px;
  border-left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 0;
  color: rgba(0, 0, 0, 0.38);

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    cursor: initial;
  }

  @media (max-width: 1199px) {
    width: 20%;
  }

  @media (max-width: 767px) {
    width: 25%;
  }

  ${(props) =>
    props.error === 'email' &&
    css`
      border-top: 1px solid #ec4c47;
      border-bottom: 1px solid #ec4c47;
      background-color: rgba(252, 201, 204, 0.21);
    `}

  ${(props) =>
    props.isDone &&
    css`
      border-top: 1px solid #31992e;
      border-bottom: 1px solid #31992e;
      background-color: rgba(49, 153, 46, 0.1);
    `}
`;

const EmailSelectInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border-left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 1px solid rgba(0, 0, 0, 0.13);
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  ${(props) =>
    props.error === 'email' &&
    css`
      border-top: 1px solid #ec4c47;
      border-bottom: 1px solid #ec4c47;
      border-right: 1px solid #ec4c47;
      background-color: rgba(252, 201, 204, 0.21);
    `}

  ${(props) =>
    props.isDone &&
    css`
      border-top: 1px solid #31992e;
      border-bottom: 1px solid #31992e;
      border-right: 1px solid #31992e;
      background-color: rgba(49, 153, 46, 0.1);
    `}
`;

const EmailSelectBox = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  background-color: #f6f6f6;
  font-family: 'Noto Sans KR';
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 1px solid rgba(0, 0, 0, 0.13);
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const PasswordInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  border: ${(props) =>
    props.error === 'password'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'password' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-radius: 2px;
  margin-bottom: ${(props) => (props.error === 'password' ? '0' : '12px')};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PasswordCheckInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  border: ${(props) =>
    props.error === 'passwordCheck'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'passwordCheck' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-radius: 2px;
  margin-bottom: ${(props) => (props.error === 'passwordCheck' ? '0' : '12px')};
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000;
`;

const PrivacyArticle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #000;
`;

const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  color: #000;
`;

const CheckMark = styled.span`
  position: relative;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #d3d3d3;
  margin-right: 10px;
  color: #000;

  &:hover {
    background-color: #e50043;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const CheckLabel = styled.label`
  width: 100%;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  font-family: Noto Sans KR;
  color: #5a5a5a;

  & ${CheckInput}:checked ~ ${CheckMark} {
    background-color: #e50043;
  }

  & ${CheckInput}:checked ~ ${CheckMark}:after {
    display: block;
  }

  & > ${CheckMark}:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover > ${CheckMark} {
    background: #e50043;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const PrivacyButton = styled.span`
  text-align: right;
  width: 25%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  text-decoration: underline;
  color: #e50043;

  &:hover {
    cursor: pointer;
  }
`;

const SignupButton = styled.button`
  width: 100%;
  background: #eee;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  border: 0;
  color: #5a5a5a;
  height: auto;
  margin-top: 25px;
  padding: 15px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;

  &:hover {
    color: #fff;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    background: #e50043;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &:hover:after {
    left: 0;
  }
`;

const ButtonText = styled.span`
  position: relative;
  z-index: 1;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: 'Noto Sans KR';
  border: ${(props) =>
    props.error === 'company'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'company' ? 'rgba(252, 201, 204, 0.21)' : ''};
  border-radius: 2px;
  margin-bottom: 0;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const DepartmentSelect = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: ${(props) =>
    props.error === 'department'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'department' ? 'rgba(252, 201, 204, 0.21)' : ''};
  margin-bottom: ${(props) => (props.error === 'department' ? '0' : '12px')};
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const DutySelect = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: ${(props) =>
    props.error === 'duty'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'duty' ? 'rgba(252, 201, 204, 0.21)' : ''};
  margin-bottom: ${(props) => (props.error === 'duty' ? '0' : '12px')};
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const IndustrySelect = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: ${(props) =>
    props.error === 'industry'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'industry' ? 'rgba(252, 201, 204, 0.21)' : ''};
  margin-bottom: ${(props) => (props.error === 'industry' ? '0' : '12px')};
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const PathSelect = styled.select`
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: ${(props) =>
    props.error === 'path'
      ? '1px solid #EC4C47'
      : '1px solid rgba(0, 0, 0, 0.13)'};
  background-color: ${(props) =>
    props.error === 'path' ? 'rgba(252, 201, 204, 0.21)' : ''};
  margin-bottom: ${(props) => (props.error === 'path' ? '0' : '25px')};
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const ErrorText = styled.div`
  display: ${(props) => (props.error !== '' ? 'flex' : 'none')};
  color: #ec4c47;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 12px;
`;

const InformationText = styled.div`
  display: 'flex';
  color: #ec4c47;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 12px;
`;

const CheckText = styled.span`
  margin-left: 5px;
  font-size: 12px;
  width: 20%;
`;

export {
  SignupWrapper,
  BackLink,
  NameInput,
  PhoneArticle,
  PhoneSelect,
  PhoneInput,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectInput,
  EmailSelectBox,
  PasswordInput,
  PasswordCheckInput,
  PrivacyContainer,
  PrivacyArticle,
  CheckMark,
  CheckInput,
  CheckLabel,
  PrivacyButton,
  SignupButton,
  ButtonText,
  CompanyInput,
  DepartmentSelect,
  DutySelect,
  IndustrySelect,
  PathSelect,
  ErrorText,
  InformationText,
  CheckText,
};

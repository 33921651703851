import { MOVE_PRODUCT } from '../actions/move.actions';

const initialMoveState = {
  movePosition: '',
};

export default function move(state = initialMoveState, action) {
  switch (action.type) {
    case MOVE_PRODUCT:
      return {
        movePosition: action.movePosition,
      };
    default:
      return state;
  }
}

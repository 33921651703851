import styled from 'styled-components';

const TimeStamp = styled.p`
  width: 30%;
  height: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin: 0px 0px 0px 21px;
  color: #fff;

  @media (max-width: 1560px) {
    width: 35%;
  }

  @media (max-width: 1390px) {
    width: 40%;
  }

  @media (max-width: 1200px) {
    font-size: 11px;
    width: 45%;
  }

  @media (max-width: 1050px) {
    font-size: 11px;
    width: 50%;
  }

  @media (max-width: 905px) {
    font-size: 11px;
    width: 55%;
  }

  @media (max-width: 850px) {
    font-size: 11px;
    width: 65%;
  }

  @media (max-width: 767px) {
    font-size: 10px;
    width: 75%;
    margin: 0px 0px 0px 15px;
  }
`;

const AgendaData = styled.p`
  width: 80%;
  height: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  background-color: transparent;
  border: none;
  color: #fff;
  white-space: pre-wrap;
  line-height: normal;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 1150px) {
    font-size: 11px;
  }

  @media (max-width: 1050px) {
    font-size: 11px;
  }

  @media (max-width: 767px) {
    font-size: 10px;
    width: 60%;
    margin: 0px 0px 0px 10px;
  }
`;

const AgendaContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 13px 0px;

  @media (max-width: 767px) {
    margin: 10px 0px;
  }
`;

export { TimeStamp, AgendaData, AgendaContainer };

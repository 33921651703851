import styled from 'styled-components';

const ObjectArticle = styled.div`
  width: 280px;
  right: ${(props) => (props.show === 'show' ? '0' : '-255px')};
  height: 60%;
  background-color: #000;
  position: absolute;
  top: 20%;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  z-index: ${(props) => (props.show === 'show' ? '1002' : '999')};
  -webkit-transition: right 1s, -webkit-transform 1s;
  transition: right 1s, transform 1s;
  transition-timing-function: ease;

  @media (max-width: 767px) {
    position: absolute;
    right: 0;
    width: 100%;
    height: calc(100% - 60px);
    top: ${(props) => (props.show === 'show' ? '0' : '100%')};
    flex-direction: column;
    transition: top 1s, transform 1s;
    z-index: 997;
  }
`;

const ObjectContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    height: calc(100% - 60px);
  }
`;

const ObjectBtn = styled.button`
  width: 28px;
  position: relative;
  background-color: #000;
  border: 0;
  color: #000;
  box-sizing: border-box;
  padding: 10px;

  &:hover {
    cursor: pointer;
    border-left: 2px solid #e50043;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.show:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    left: 40%;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &.hide:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    left: 50%;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const ObjectTitle = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 10px;
  position: relative;

  @media (max-width: 767px) {
    background-color: #000;
  }
`;

const ObjectTitleText = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-family: Poppins;
  line-height: 100%;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin: 0p;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const ObjectListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 15px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 4px solid #e50043;
    border-right: 0;
  }

  &::-webkit-scrollbar-track {
    margin: 20px 0px;
    border-left: 4px solid #333;
    border-right: 0;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const ObjectList = styled.button`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  flex: 1;
  text-align: left;
  padding-left: 15px;
  padding-bottom: 20px;
  background-color: transparent;
  text-decoration: none;
  border: 0;

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
    color: #e50043;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

const MobileCloseButton = styled.button`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    display: block;
    top: 13px;
    right: 18px;
    width: 14px;
    height: 14px;
    color: #000;
    background-image: url(${(props) => props.img});
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

export {
  ObjectArticle,
  ObjectBtn,
  ObjectContent,
  ObjectTitle,
  ObjectTitleText,
  ObjectListContainer,
  ObjectList,
  MobileCloseButton,
};

const objectEvent = (
  viewer,
  dispatch,
  loadProduct,
  getProduct,
  setModal,
  moveProduct,
  content,
  semina,
) => {
  let start = false;
  let objectId = '';

  // Node Click Event
  viewer.onNodeTypeClicked(function (node, position, distance) {
    var nodeTypePath = [];
    var objectPath = null;

    while (node) {
      nodeTypePath.push(node.type);
      node = node.parent;
    }

    if (nodeTypePath[nodeTypePath.length - 1] === 'SmallBox_Open_블렌더#1') {
      // 스몰박스들은 각각의 아이디 사용
      objectPath = nodeTypePath[0];
    } else if (nodeTypePath[nodeTypePath.length - 1] === 'Mini_Kiosk#1') {
      // 미니 키오스크 화면만 선택
      objectPath = nodeTypePath[0];
    } else if (nodeTypePath[nodeTypePath.length - 1] === 'magazine rack-1') {
      objectPath = nodeTypePath[0];
    } else if (
      nodeTypePath[nodeTypePath.length - 1] ===
      'Cold aisle containment_디지털 전시장'
    ) {
      objectPath = nodeTypePath[0];
    } else {
      // 그 외 요소는 최상위 부모 가져오기
      objectPath = nodeTypePath[nodeTypePath.length - 1];
    }

    getProductName(objectPath);

    // 오브젝트 클릭시 자동 이동 방지
    return true;
  });

  function getProductName(parent) {
    switch (parent) {
      case 'Main_Kiosk#1': // kiosk
        return dispatch(setModal({ type: 'kiosk', status: 'show' }));

      // Power Distribution
      case '7_RLine 버스바 시스템':
        return show('601ae03b88983358235d1af1');
      case '8_Ri4Pwer_Reblended':
        return show('5fb4a51bdcffb63ea07519f8');
      case '그룹#106':
        return show('601ade2a88983358235d1aee');

      // Climate Control
      case '12-1_TS8+Blue_e+Fan&Filter':
        return show('5fb4a69edcffb63ea0751a04');
      case '13_Blue e+ Childer & 열 교환기':
        return show('5fb4a734dcffb63ea0751a09');

      // Enclosures
      case '3_열반 시스템 TS 8':
        return show('5fb49e41509f793d2773ba1a');
      case '서포트 암 시스템 CP':
        return show('5fb4a393dcffb63ea07519e9');
      case '{HD 콤펙트 인클로저_Close}그룹#12': // HD
        return show('5fb49d77509f793d2773ba11');
      case '{HD 콤펙트 인클로저_Open_블렌더}그룹#11': // HD
        return show('5fb49d77509f793d2773ba11');
      case '{AE1034_Open}그룹#23': // AE1034
        return show('5fb49cae509f793d2773ba02');
      case '{AE1034_Open_블렌더}그룹#28': // AE1034
        return show('5fb49cae509f793d2773ba02');
      case '{터미널박스 KL_Close}그룹#24': // KL
        return show('5fb49818509f793d2773b9f8');
      case '그룹#29': // KL
        return show('5fb49818509f793d2773b9f8');
      case '{E박스 EB_Close}그룹#25': // EB
        return show('5fb49889509f793d2773b9fd');
      case '{E박스 EB_Open_블렌더}그룹#30': // EB
        return show('5fb49889509f793d2773b9fd');
      case '{AE1280_Close}그룹#26': // AE1280
        return show('601bab9cdef52d17d82127d1');
      case '그룹#31': // AE1280
        return show('601bab9cdef52d17d82127d1');
      case '{AE1090_Close}그룹#27': // AE1090
        return show('5fb49d11509f793d2773ba07');
      case '{AE1090_Open_블렌더}그룹#32': // AE1090
        return show('5fb49d11509f793d2773ba07');

      // IT Infrastructure
      case '{27_LCP_Center}그룹#48': // LCP
      case '그룹#347':
      case '{27_LCP}그룹#48':
      case '{27_LCP_Screen}그룹#48':
      case '그룹#352':
      case '그룹#344':
        return show('601ae8a888983358235d1b0f');
      case '그룹#372': // PDU
      case '그룹#367':
      case '그룹#335':
      case '그룹#156':
        return show('5fb4a784dcffb63ea0751a10');
      case '그룹#355': // TS IT
      case '그룹#183':
      case '그룹#357':
      case '그룹#358':
        return show('5fb4a7dcdcffb63ea0751a15');

      // Smart & Monitoring
      case '19_엣지 데이터 센터 (Edge DC)#1':
        return show('5fb4a9fadcffb63ea0751a21');
      case '16_HMDC#4':
        return show('5fb4a825dcffb63ea0751a19');
      case '18_스마트 판넬':
        return show('5fb4a9bbdcffb63ea0751a1d');
      case 'Group#37': // 스마트 모니터링
        return show('601ae34c88983358235d1afc');
      case '30_Smart_Panel_S2_reblended':
        return show('6267baeb2936b7532ec32d38');
      case '29_FMDC_reblended':
        return show('6267bb8ca6dd87531ed82b41');

      // RAS & Digital Twin
      case '21_디지털 트윈':
        return show('601ae4d488983358235d1b00');
      case 'Group#24': // RAS
        return show('601ae9da88983358235d1b1b');
      case '22_RAS Semi-Auto':
        return show('601ae60688983358235d1b07');

      // value chain
      // case 'DigitalTwin_Pillar_Work_2':
      //   return window.open(
      //     'https://www.rittal.com/com_en/industry40/public/index.php/kr/',
      //     '_blank',
      //   );

      // value Chain mini
      case '{Mini_Kiosk_Screen#1}Mini_kiosk':
        return valueChainShow();

      // value Chain Moniter
      case 'Group#89':
        return window.open('https://www.rittal.com/industries/', '_blank');

      // 매거진 꽃이
      case '{Magazine1}그룹#19': // 매거진 1
        return window.open(`${content[0].link}`, '_blank');
      case '{Magazine2}그룹#18': // 매거진 2
        return window.open(`${content[1].link}`, '_blank');
      case '{Magazine3}그룹#21': // 매거진 3
        return window.open(`${content[2].link}`, '_blank');
      case '{Magazine4}그룹#22': // 매거진 4
        return window.open(`${content[3].link}`, '_blank');
      case '{Magazine5}그룹#20': // 매거진 5
        return window.open(`${content[4].link}`, '_blank');
      case '{Magazine6}그룹#17': // 매거진 6
        return window.open(`${content[5].link}`, '_blank');

      // 설계 지원 소프트웨어
      case 'NMonitor#1': // 좌측
        return show('604efb98ab706939a96d0565');
      case 'NMonitor#2': // 우측
        return show('604f149cab706939a96d0576');

      // 세미나 문
      case 'Group#22':
        return seminaOpen();

      // 디지털 키
      case '18_Digital_Lock_Key':
      case '{Digitalloce_Reblended_Black}그룹#423':
      case '그룹#122':
        return show('6102409fc2ddcf08dad85217');

      // 컨테이먼트 (이동 이벤트만 추가)
      case '{27_Cold aisle containment_디지털 전시장_Wall}그룹#275':
      case '{27_Cold aisle containment_디지털 전시장_Wall}그룹#211':
      case '그룹#151':
      case '{27_Cold aisle containment_디지털 전시장_Wall}그룹#228':
      case '{27_Cold aisle containment_디지털 전시장_Wall}그룹#201':
      case '그룹#238':
      case '그룹#233':
      case '그룹#234':
      case '그룹#280':
      case '{27_Cold aisle containment_디지털 전시장_BusDuct_WhitePink}그룹#211':
      case '{27_Cold aisle containment_디지털 전시장_Busduct_Red}그룹#210':
      case '{27_Cold aisle containment_디지털 전시장_BusDuct_WhitePink}그룹#210':
      case '{27_Cold aisle containment_디지털 전시장_울타리 건축}그룹#228':
        return show('61089a85c2ddcf08dad8521c');
    }
  }

  function done(id) {
    if (id === '61089a85c2ddcf08dad8521c') {
      dispatch(loadProduct());
      dispatch(moveProduct(''));
    } else {
      dispatch(loadProduct());
      dispatch(getProduct(id));
      dispatch(setModal({ type: 'object', status: 'show' }));
      dispatch(moveProduct(''));
    }
  }

  function show(id) {
    objectId = id;
    dispatch(moveProduct(objectId));

    start = true;

    viewer.onViewSwitchDone((_) => {
      if (start === true) {
        start = false;
        return done(objectId);
      } else {
        return null;
      }
    });
  }

  function valueChainShow() {
    dispatch(moveProduct('MiniKiosk'));

    // 유저가 사파리 인지 체크
    const ua = navigator.userAgent.toLowerCase();
    const is_safari = ua.indexOf('safari/') > -1 && ua.indexOf('chrome') < 0;
    let start = true;

    if (is_safari) {
      const windowReference = window.open();

      viewer.onViewSwitchDone((_) => {
        if (start === true) {
          start = false;
          dispatch(moveProduct(''));
          return (windowReference.location =
            'https://rittal-exhibition.co.kr/valuechain');
        } else {
          return null;
        }
      });
    } else {
      viewer.onViewSwitchDone((_) => {
        if (start === true) {
          start = false;
          dispatch(moveProduct(''));
          return window.open(
            'https://rittal-exhibition.co.kr/valuechain',
            '_blank',
          );
        } else {
          return null;
        }
      });
    }
  }

  // 세미나 On/Off
  function seminaOpen() {
    if (semina) {
      // dispatch(setModal({ type: 'seminar', status: 'show' }));
      location.href = '/seminar_room';
    } else {
      return null;
    }
  }

  // 세미나 On Air 표시등 설정
  const offAir = viewer.findMaterial('Off_Air');
  const onAir = viewer.findMaterial('On_Air1');

  if (semina) {
    onAir.opacity = 1;
    offAir.opacity = 0;
  } else {
    onAir.opacity = 0;
    offAir.opacity = 1;
  }
};

export default objectEvent;

import React from 'react';

import { UserChatArticle, UserChat } from './MessageBoxStyled';

const MessageClient = ({ defaultMessage }) => {
  return (
    <div key={defaultMessage.userId}>
      <UserChatArticle>
        <UserChat>{defaultMessage.userMessage}</UserChat>
      </UserChatArticle>
    </div>
  );
};

export default MessageClient;

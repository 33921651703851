import styled, { keyframes } from 'styled-components';

const TourContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const TourText = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const ButtonArticle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 24px;
  }
`;

const KioskButton = styled.button`
  width: 160px;
  height: 42px;
  background-color: #e50043;
  border: 1px solid #e50043;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #fff;
  word-break: keep-all;

  &:hover {
    cursor: pointer;
    background-color: #f9777c;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const CloseButton = styled.button`
  margin-left: 10px;
  width: 160px;
  height: 42px;
  background-color: #f8f8f8;
  border: 1px solid #cbc9c9;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #666666;

  &:hover {
    cursor: pointer;
    background: #c6c6c6;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 15px;
  }
`;

const TourTitleWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 50px;
  background: rgba(0, 0, 0, 0.8);
  left: 65px;
  top: 10%;
  padding: 15px 25px 15px 25px;
  border-left: 2px solid #e7006b;

  @media (max-width: 767px) {
    height: 40px;
    width: 100%;
    top: 0;
    left: 0;
    background: #000;
    padding: 0;
    border-left: 0;
  }
`;

const TourTitle = styled.span`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.408px;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TourBottomArticle = styled.div`
  position: absolute;
  width: 100%;
  bottom: 5%;
  dispaly: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TourProgress = styled.div`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direcion: row;
  justify-content: center;
  align-items: center;
`;

const TourWrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direcion: row;
  justify-content: center;
  align-items: center;
`;

const bounce = keyframes`
  0% {
    box-shadow: 0 0 0 0px transparent, 0 0 0 0px #fff, 
    0 0 0 0px transparent, 0 0 0 0px #fff, 
    0 0 0 0px transparent, 0 0 0 0px #fff, 
    0 0 0 0px transparent, 0 0 0 0px #fff;
  }

  100% {
    box-shadow: 0 0 0 4px transparent, 0 0 0 6px rgba(255,255,255,0), 
    0 0 0 10px transparent, 0 0 0 12px rgba(255,255,255,0), 
    0 0 0 16px transparent, 0 0 0 18px rgba(255,255,255,0), 
    0 0 0 20px transparent, 0 0 0 22px rgba(255,255,255,0);
  }
`;

const TourLocation = styled.div`
  width: 32px;
  height: 32px;
  background: #cbc9c9;
  border: 3px solid #ffffff;

  box-sizing: border-box;
  border-radius: 50%;

  &.active {
    background: #e50043;
    animation: ${bounce} 2s ease-in-out infinite;
  }

  &.done {
    background: #e50043;
  }

  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
`;

const TourLine = styled.div`
  width: 180px;
  border: 2px solid #ffffff;
  box-sizing: border-box;

  &.active {
    border: 2px solid #e50043;
  }

  @media (max-width: 767px) {
    width: 80px;
  }
`;

const TourExitText = styled.div`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direciton: row;
  align-items: center;
  justify-content: center;
  margin-top: 28px;

  @media (max-width: 767px) {
    font-size: 12px;
    margin-top: 20px;
  }
`;

const TourExitButton = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin: 0px 10px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  width: 66px;
  height: 40px;
  z-index: 1001;

  &:hover {
    cursor: pointer;
    border: 1px solid #e50043;
    color: #e50043;
  }

  @media (max-width: 767px) {
    width: 45px;
    height: 30px;
    font-size: 12px;
  }
`;

export {
  TourContainer,
  TourText,
  ButtonArticle,
  KioskButton,
  CloseButton,
  TourTitleWrapper,
  TourTitle,
  TourBottomArticle,
  TourProgress,
  TourWrapper,
  TourLocation,
  TourLine,
  TourExitButton,
  TourExitText,
};

import styled from 'styled-components';

const ContentArticle = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContentTitle = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 36px;
  color: #000;
  margin-left: 22px;

  @media (max-width: 767px) {
    font-size: 13px !important;
    line-height: 20px !important;
    margin-top: 0 !important;
  }

  @media (max-height: 900px) {
    font-size: 22px;
    margin-top: 0;
  }

  @media (max-height: 850px) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
  }
`;

const TitleImage = styled.img`
  @media (max-width: 767px) {
    width: 40px !important;
    height: 45px !important;
    margin-top: 0 !important;
  }

  @media (max-height: 900px) {
    width: 50px;
    height: 55px;
    margin-top: 0;
  }

  @media (max-height: 850px) {
    width: 45px !important;
    height: 50px !important;
    margin-top: 0 !important;
  }
`;

const ContentSub = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #000;
  margin-top: 8px;

  @media (max-width: 767px) {
    font-size: 11px;
    line-height: 150%;
    margin-top: 0;
  }
`;

const Stamp = styled.div`
  position: relative;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StampImg = styled.img`
  width: 99px;
  height: 99px;
  outline: none;
  border: ${(props) =>
    props.done === true ? '4px solid #E50043' : '4px solid rgba(0, 0, 0, 0.8)'};

  &:hover {
    cursor: pointer;
    border: 4px solid #e50043;
  }

  @media (max-width: 767px) {
    width: 82px;
    height: 82px;
  }

  @media (max-width: 320px) {
    width: 48px;
    height: 48px;
  }

  @media (max-height: 850px) {
    width: 78px;
    height: 78px;
  }
`;

const StampText = styled.p`
  width: 99px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
  color: ${(props) => (props.done === true ? '#E50043' : '#000')};
  margin-top: 10px;
  margin-bottom: 0;
  word-break: keep-all;

  @media (max-width: 767px) {
    width: 82px;
    font-size: 10px;
  }

  @media (max-width: 320px) {
    width: 75px;
  }

  @media (max-height: 850px) {
    font-size: 12px;
    margin-top: 5px;
  }
`;

const StampArticle1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  height: auto;

  ${Stamp}:nth-child(2) {
    margin: 0px 70px;

    @media (max-width: 767px) {
      margin: 0px 35px;
    }
  }

  @media (max-width: 320px) {
    margin: 10px 0px;
  }

  @media (max-height: 850px) {
    margin: 10px 0px;
  }
`;

const StampArticle2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;

  ${Stamp}:nth-child(2) {
    margin: 0px 70px;

    @media (max-width: 767px) {
      margin: 0px 35px;
    }
  }
`;

const StampButton = styled.button`
  background-color: ${(props) => (props.done === true ? '#E50043' : '#F8F8F8')};
  border: 1px solid #cbc9c9;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: ${(props) => (props.done === true ? '#fff' : '#666666')};
  padding: 13px 24px;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.done === true ? '#f9777c' : 'rgba(252, 201, 204, 0.21)'};
    border: 1px solid #ec4c47;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
  }

  @media (max-height: 850px) {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 13px;
  }
`;

const StampDone = styled.div`
  background-image: url(${(props) => props.img});
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 99px;
  height: 99px;

  @media (max-width: 767px) {
    width: 82px;
    height: 82px;
  }

  @media (max-width: 320px) {
    width: 48px;
    height: 48px;
  }

  @media (max-height: 850px) {
    width: 80px;
    height: 80px;
  }
`;

export {
  ContentArticle,
  TitleImage,
  ContentTitle,
  ContentSub,
  StampArticle1,
  StampArticle2,
  Stamp,
  StampDone,
  StampImg,
  StampText,
  StampButton,
};

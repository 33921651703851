import React, { useRef } from 'react';
import closeImg from '../../../assets/closewhite.svg';
import { Carousel, Image } from 'antd';

import help1 from '../../../assets/help/help1.png';
import help2 from '../../../assets/help/help2.png';
import help3 from '../../../assets/help/help3.png';

import {
  HelpContainer,
  TitleArticle,
  Title,
  CloseButton,
  ContentArticle,
  LeftArrow,
  ImageArticle,
  RightArrow,
} from './styled/HelpStyled';

const Help = ({ isCurrent }) => {
  const carouselRef = useRef();

  const handleOnClick = () => {
    isCurrent(0);
  };

  return (
    <HelpContainer>
      <TitleArticle>
        <Title>조작방법</Title>
        <CloseButton onClick={handleOnClick} img={closeImg} />
      </TitleArticle>
      <Carousel ref={carouselRef} style={{ height: 'calc(100% - 30px)' }}>
        <div>
          <ContentArticle>
            <LeftArrow onClick={() => carouselRef.current.goTo(2)} />
            <ImageArticle style={{ backgroundImage: `url(${help1})` }}>
              <Image
                width={'auto'}
                src={help1}
                style={{ opacity: '0', zoom: '1', filter: 'alpha(opacity=0)' }}
              />
            </ImageArticle>
            <RightArrow onClick={() => carouselRef.current.next()} />
          </ContentArticle>
        </div>
        <div>
          <ContentArticle>
            <LeftArrow onClick={() => carouselRef.current.prev()} />
            <ImageArticle style={{ backgroundImage: `url(${help2})` }}>
              <Image
                width={'auto'}
                src={help2}
                style={{ opacity: '0', zoom: '1', filter: 'alpha(opacity=0)' }}
              />
            </ImageArticle>
            <RightArrow onClick={() => carouselRef.current.next()} />
          </ContentArticle>
        </div>
        <div>
          <ContentArticle>
            <LeftArrow onClick={() => carouselRef.current.prev()} />
            <ImageArticle style={{ backgroundImage: `url(${help3})` }}>
              <Image
                width={'auto'}
                src={help3}
                style={{ opacity: '0', zoom: '1', filter: 'alpha(opacity=0)' }}
              />
            </ImageArticle>
            <RightArrow onClick={() => carouselRef.current.goTo(0)} />
          </ContentArticle>
        </div>
      </Carousel>
    </HelpContainer>
  );
};

export default Help;

/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MessageClient from './chatbox/MessageClient';
import MessageAdminster from './chatbox/MessageAdminster';
import MessageOtherClient from './chatbox/MessageOtherClient';
import moment from 'moment';
import useWebSocket from 'react-use-websocket';
import useEventListener from '../../../hooks/useEventListener';
import axios from '../../../contants/axios';

import {
  ChatContainer,
  ChatPopupTitle,
  PopupTitle,
  ChatArticle,
  DateTitle,
  SendArticle,
  SendInpit,
  SendButton,
} from './SeminarChatStyled';

const WEBSOCKET_URL = 'wss://seminarsocket.rittal-exhibition.co.kr/ws/';
// const WEBSOCKET_URL = 'ws://localhost:9090/ws/';

const DefaultMessages = ({ userId, messages }) => {
  const [data, setData] = useState('');

  useEffect(() => {
    if (messages) {
      setData(messages);
    }
  }, [messages]);

  return (
    <>
      {data !== ''
        ? messages.map((data, index) => {
            data.time =
              moment(data.createdAt).format('HH:mm A') ||
              moment(data.published_at).format('HH:mm A');
            data.text = data.message;
            return data.system ? (
              <MessageAdminster defaultMessage={data} key={index} />
            ) : data.userId === userId ? (
              <MessageClient defaultMessage={data} key={index} />
            ) : (
              <MessageOtherClient defaultMessage={data} key={index} />
            );
          })
        : ''}
    </>
  );
};

const SeminarChat = () => {
  const messagesRef = useRef(null);
  const didUnmount = useRef(false);
  const [chats, setChats] = useState([]);
  const { id: userId, username, company } = useSelector((state) => state.auth);
  const { seminaLink } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem('token');

  useEventListener(messagesRef, 'DOMNodeInserted', (e) => {
    const { currentTarget: target } = e;
    target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  });

  // 채팅 로그 받아오기
  useEffect(() => {
    if (!seminaLink) return;
    console.log('Chat Logs Loading...');

    (async () => {
      axios
        .get(`/seminar-chats?video_url=${seminaLink}&_limit=-1`)
        .then((res) => {
          setChats(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    })();
  }, [seminaLink]);

  useEffect(() => {
    // 유저가 접속할 때
    sendMessage(
      JSON.stringify({
        rule: 'join',
        message: {
          username: `${username} / ${company}`,
          userId,
        },
      }),
    );

    // 유저가 나갔을 때
    return () => {
      sendMessage(
        JSON.stringify({
          rule: 'leave',
          message: {
            username: `${username} / ${company}`,
            userId,
          },
        }),
      );
    };
  }, [username]);

  const sendChat = (userMessage) => {
    sendMessage(
      JSON.stringify({
        rule: 'chat',
        message: {
          video_url: seminaLink,
          userMessage,
          username,
          company,
          userId,
          system: 0,
          time: new Date().toString(),
        },
      }),
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const { chat } = event.target;
    const { value } = chat;

    if (!value || value === '') return;

    sendChat(value);

    event.target.reset();
  };

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (!userId) return;

      resolve(`${WEBSOCKET_URL}seminar`);
    });
  }, [userId]);

  const { sendMessage, lastMessage } = useWebSocket(getSocketUrl, {
    shouldReconnect: () => {
      console.log(`websocket reconnecting...`);
      return didUnmount.current === false;
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  useEffect(() => {
    if (!lastMessage) return;
    const { data } = lastMessage;
    const parseData = JSON.parse(data);

    switch (parseData.rule) {
      case 'chat':
        return setChats((PrevState) => [...PrevState, parseData.message]);
      default:
        return;
    }
  }, [lastMessage]);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    // 창을 꺼버릴 경우 채팅룸에서 탈퇴
    let unloaded = false;
    window.addEventListener('beforeunload', function (e) {
      if (unloaded) return;
      unloaded = true;
      sendMessage(
        JSON.stringify({
          rule: 'leave',
          message: {
            username: `${username} / ${company}`,
            userId,
          },
        }),
      );
    });
    window.addEventListener('visibilitychange', function (e) {
      if (document.visibilityState === 'hidden') {
        // if (unloaded) return;
        // unloaded = true;
        sendMessage(
          JSON.stringify({
            rule: 'leave',
            message: {
              username: `${username} / ${company}`,
              userId,
            },
          }),
        );
      } else {
        sendMessage(
          JSON.stringify({
            rule: 'join',
            message: {
              username: `${username} / ${company}`,
              userId,
            },
          }),
        );
      }
    });

    return () => {
      if (!token) return;
      window.removeEventListener('beforeunload');
      window.removeEventListener('visibilitychange');
    };
  }, []);

  return (
    <ChatContainer show={'show'}>
      <ChatPopupTitle>
        <PopupTitle>메시지</PopupTitle>
      </ChatPopupTitle>
      <ChatArticle ref={messagesRef}>
        <DateTitle>오늘</DateTitle>
        {chats.length ? (
          <DefaultMessages userId={userId} messages={chats} />
        ) : (
          <></>
        )}
      </ChatArticle>
      <SendArticle onSubmit={onSubmit}>
        {loading ? (
          <SendInpit
            placeholder='채팅을 로딩중입니다.'
            type='text'
            name='chat'
            disabled
          />
        ) : (
          <SendInpit
            placeholder='메세지를 입력해주세요.'
            type='text'
            name='chat'
          />
        )}

        <SendButton type='submit' disabled={loading}>
          SEND
        </SendButton>
      </SendArticle>
    </ChatContainer>
  );
};

export default React.memo(SeminarChat);

const videoTexture = (viewer, videos) => {
  // 쇼룸 내부공간 동영상 교체
  let material = '';
  let video = document.createElement('video');
  video.src = `https://api.rittal-exhibition.co.kr${videos.ContentMedia.url}`;
  video.autoplay = true;
  video.loop = true;
  video.muted = true;
  video.preload = 'metadata';
  video.playsinline = true;
  video.type = `${videos.ContentMedia.mime}`;

  let source = document.createElement('source');
  source.src = `https://api.rittal-exhibition.co.kr${videos.ContentMedia.url}`;
  source.type = `${videos.ContentMedia.mime}`;

  video.appendChild(source);
  video.load();

  const TextureChanger = (materialName) => {
    material = viewer.findMaterial(materialName);
    let videoTexture = viewer.createTextureFromHtmlVideo(video);
    material.baseColorTexture = videoTexture;

    if (!videoTexture.isPlaying) {
      if (videoTexture.play !== undefined) {
        videoTexture.play();
      } else {
        videoTexture.isPlaying = true;
      }
    } else {
      if (videoTexture.pause !== undefined) {
        videoTexture.pause();
      } else {
        videoTexture.isPlaying = false;
      }
    }

    // 프레임 렌더링
    viewer.requestFrame();
    return true;
  };

  switch (videos.ContentPosition) {
    case 'Value Chain 디스플레이':
      return TextureChanger('재질35');
    case '인포데스크 디스플레이':
      return TextureChanger('재질36');
    // case '디지털트윈':
    //   return TextureChanger('DigitalTwin_Pillar_Set');
    case 'Blue e+ Cooling Unit 좌측 화살표':
      return TextureChanger('CoolingUnit_Arrows_Way1');
    case 'Blue e+ Cooling Unit 중간 화살표':
      return TextureChanger('CoolingUnit_Arrows_Way2');
    case '상단 화살표':
      return TextureChanger('엣지 데이터 센터 (Edge DC) / Blue e+ top_Arrow');
    case 'HMDC 화살표':
      return TextureChanger('HMDC__Arrow');
    case '서포트암 비디오':
      return TextureChanger('서포트 암 시스템 CP_Screen');
    case '바닥화살표':
      return TextureChanger('27_Cold aisle containment_Bottom#2');
    default:
      return null;
  }
};

export default videoTexture;

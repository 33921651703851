const sceneSwitch = (
  viewer,
  dispatch,
  scene,
  switching,
  objects,
  tourState,
  getproductDetailed,
) => {
  let breakCheck = false;
  let exitButton = null;

  // 오브젝트 이동 타이밍
  const delay = () => {
    return new Promise((resolve) => {
      setTimeout(resolve, 10000);
    });
  };

  // 중간에 키 입력 체크
  const handleKeyEvent = (e) => {
    const key = e.keyCode;
    // key === 81 || key === 87 || key === 69 || key === 65 || key === 83 || key === 68 [w,a,s,d,q,e]
    if (key === 27) {
      // esc
      dispatch(
        tourState({
          guide: true,
          status: 'exit',
          switching: false,
        }),
      );
      document.removeEventListener('keydown', handleKeyEvent);
      breakCheck = true;
    }
  };

  // 투어 완료 시
  const tourDone = async (check) => {
    if (check) {
      document.removeEventListener('keydown', handleKeyEvent);
      exitButton = null;
      dispatch(
        getproductDetailed({
          loading: 'false',
          tourId: '',
          hoverId: '',
          type: '',
        }),
      );
      dispatch(
        tourState({
          guide: true,
          status: 'done',
          switching: false,
        }),
      );
    }
  };

  const progressChange = (index) => {
    const newProgress = document.getElementById('tour-progress');

    if (newProgress !== null && newProgress !== undefined) {
      if (index === 0) {
        newProgress.children[index].children[0].classList.add('active');
      } else if (index === 1) {
        newProgress.children[index - 1].children[0].classList.remove('active');
        newProgress.children[index - 1].children[0].classList.add('done');
        newProgress.children[index].children[0].classList.add('active');
        newProgress.children[index].children[1].classList.add('active');
      } else {
        newProgress.children[index - 1].children[1].classList.remove('active');
        newProgress.children[index - 1].children[1].classList.add('done');
        newProgress.children[index].children[0].classList.add('active');
        newProgress.children[index].children[1].classList.add('active');
      }
    }
  };

  // 커스텀 투어
  async function startTour() {
    document.addEventListener('keydown', handleKeyEvent);

    setTimeout(() => {
      // DOM Loading 대기
      exitButton = document.getElementById('exitButton');
      exitButton.addEventListener('click', () => {
        handleKeyEvent({ keyCode: 27 });
      });
    }, 1000);

    for (let i = 0; i < objects.length; i++) {
      if (breakCheck === true) {
        break;
      }

      progressChange(i);

      viewer.switchToView(objects[i].view);

      // SwitchViewStart
      viewer.onViewSwitchStarted(() => {
        dispatch(
          getproductDetailed({
            loading: 'false',
            tourId: '',
            hoverId: '',
            type: '',
          }),
        );
      });

      // SwitchViewDone
      viewer.onViewSwitchDone(() => {
        if (breakCheck === false) {
          dispatch(
            getproductDetailed({
              tourId: objects[i].id,
              loading: 'true',
              type: 'tour',
            }),
          );
        } else {
          dispatch(
            getproductDetailed({
              loading: 'false',
              tourId: '',
              hoverId: '',
              type: '',
            }),
          );
        }
      });

      await delay();

      if (i === objects.length - 1 && breakCheck === false) {
        tourDone(true);
      }
    }
  }

  if (scene > 0 && switching === true) {
    startTour();
  }
};

export default sceneSwitch;

import React from 'react';
import Header from './Header';

import {
  ObejctInfoContainer,
  ContentArticle,
  TitleArticle,
  ObjectTitle,
  Content,
} from './styled/ObjectInfoStyled';

import {
  FooterContainer,
  OnlineButton,
  DownloadButton,
} from './styled/FooterStyled';

const CooperateTab = () => {
  return (
    <>
      <ObejctInfoContainer>
        <Header title={'프로그램 정보'} />
        <ContentArticle>
          <TitleArticle>
            <ObjectTitle>Therm - 판넬 공조 계산 소프트웨어</ObjectTitle>
          </TitleArticle>
          <Content>
            <p>
              리탈의 Therm 소프트웨어는 공조에 필요한 조건 계산을 쉽고 빠르게
              처리해드리는 무료 제공 소프트웨어입니다. 사용자 친화적인
              인터페이스로 정확한 공조구성을 안내해 드립니다.
            </p>
            <p>
              모든 계산은 인클로저 냉각 장치에 대한 IEC/TR3 60890 890 AMD 1 과
              DIN 3168 필수조건에 기반하여 이루어집니다. IEC 61439에 대한 과열
              계산도 한번의 클릭으로 Therm 에서 확인하실 수 있습니다.
            </p>
            <p>
              계산과 구성을 완료한 뒤에는 상세 자료를 프린트하거나 선호하는
              프로그램으로 변환하여 다운받을 수 있습니다.
            </p>
          </Content>
          <FooterContainer>
            <OnlineButton
              onClick={() => {
                window.open(
                  'https://www.rittal.com/therm-online/html/titellogin.php?userLogin=Please%20fill%20in%20e-mail%20address%20and%20push%20Login&userPassword=1990GK-I&sprache=english',
                  '_blank',
                );
              }}
            >
              온라인 버전
            </OnlineButton>
            <DownloadButton
              onClick={() => {
                window.open(
                  'https://www.rittal.com/imf/none/5_4994/Rittal_Therm_Offline_Full_version',
                  '_blank',
                );
              }}
            >
              소프트웨어 다운로드
            </DownloadButton>
          </FooterContainer>
        </ContentArticle>
      </ObejctInfoContainer>
    </>
  );
};

export default CooperateTab;

export const telData = ['개인', '회사'];

export const emailData = [
  '직접입력',
  'naver.com',
  'hanmail.net',
  'daum.net',
  'nate.com',
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'dreamwiz.com',
];

export const industryData = [
  '자동차 (Automotive)',
  '조선 (Shipbuilding/Oil&Gas)',
  '철강/플랜트 (Steel/Plant)',
  '식음료 (Food & Beverage)',
  '인프라/환경 (Infrastructure/Environment)',
  '기계 (Machinery)',
  '공작 기계 (Machine tool)',
  '전기 전자 (Electrics/Electronics)',
  '화학/생명 (Chemical/bio-industry)',
  '반도체/디스플레이(Semicon/Display)',
  '전력/에너지 (Power/Energy)',
  'IT/통신 (IT/Telecommunication)',
  '기타 (Others)',
];

export const departmentData = [
  '제조 (Manufacturer)',
  '설계/엔지니어링 (Engineering)',
  '시스템 통합(System Integrator)',
  '패널 빌더 (Panel Builder)',
  '장비 제조 (Machine Builder)',
  '건설 (Construction)',
  '파트너 (Partner)',
  '기타 (Others)',
];

export const dutyData = [
  '영업/마케팅 (Sales/Marketing)',
  '설계/R&D (Design/Research)',
  '프로젝트관리 (Project Management)',
  '생산/유지보수 (Production/Mainenance)',
  '구매 (Purchase)',
  '회계/경영/인사 (Management)',
  '기타 (Others)',
];

export const pathData = [
  '리탈 홈페이지',
  '블로그',
  '유튜브',
  'SNS (Facebook, Linkedin)',
  '인터넷 검색',
  '리탈 이메일(뉴스레터)',
  '리탈 영업사원',
  '이플랜 (이메일,전화 등)',
  'QR 카드',
  '기타 (매체광고 등)',
];

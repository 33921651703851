import React, { useEffect, useState } from 'react';
import axios from '../contants/axios';
import iconv from 'iconv-lite';

import {
  ReplyContainer,
  ReplyArticle,
  BodyArticle,
  Title,
  Content,
} from './styled/ReplyPageStyled';

import logo from '../assets/logo.svg';

const ReplyPage = () => {
  const [status, setStatus] = useState({
    loading: false,
    error: '',
  });

  const getQuery = () => {
    var url = document.location.href;
    var qs = url.substring(url.indexOf('?') + 1).split('&');
    for (var i = 0, result = {}; i < qs.length; i++) {
      qs[i] = qs[i].split('=');
      let qsKey = decodeURIComponent(qs[i][0]);
      let kor = unescape(qs[i][1]);
      let qsValue = iconv.decode(kor, 'euc-kr');
      if (qsValue === 'undefined') return;
      if (qsKey !== 'email' && qsKey !== 'content') return;
      result[qsKey] = qsValue;
    }
    return result;
  };

  useEffect(() => {
    setStatus({
      loading: true,
      error: '',
    });
    const query = getQuery();

    if (query && !status.loading) {
      axios
        .post(`/email-reply`, {
          email: query.email,
          content: query.content,
        })
        .then((res) => {
          return setStatus({
            loading: false,
            error: '',
          });
        })
        .catch((err) => {
          return setStatus({
            loading: false,
            error: `이메일 회신에 문제가 발생했습니다.
            다시 시도해주세요.`,
          });
        });
    } else {
      return setStatus({
        loading: false,
        error: `이메일 회신에 문제가 발생했습니다.
        다시 시도해주세요.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReplyContainer>
      <ReplyArticle>
        <BodyArticle>
          <Title>이메일 회신 안내</Title>
          {status.loading ? (
            <Content
              style={{
                color: '#E7006A',
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              선택하신 프로모션 정보를 전달 중입니다. <br /> 잠시만
              기다려주세요.
            </Content>
          ) : status.error !== '' && !status.loading ? (
            <Content
              style={{
                color: '#E7006A',
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {status.error}
            </Content>
          ) : status.error === '' && !status.loading ? (
            <Content
              style={{
                color: '#E7006A',
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              프로모션 정보를 전달하였습니다.
            </Content>
          ) : (
            <></>
          )}

          <Content>참여해주셔서 감사합니다.</Content>
          <img src={logo} alt='logo' style={{ width: 120, marginTop: 40 }} />
        </BodyArticle>
      </ReplyArticle>
    </ReplyContainer>
  );
};

export default ReplyPage;

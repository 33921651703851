import styled from 'styled-components';

const ObjectQnAContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  color: #000;
  height: 100%;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`;

const ContentForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 25px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.87);

  @media (max-width: 414px) {
    height: auto;
    padding: 15px;
    justify-content: center;
    align-items: center;
  }
`;

const TitleInput = styled.input`
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: Noto Sans KR;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 2px;

  &:focus {
    outline: 1px solid #e50043;
    border: 1px solid #e50043;
  }
`;

const ContentArea = styled.textarea`
  width: 100%;
  min-height: 40%;
  font-size: 14px;
  font-family: Noto Sans KR;
  margin-bottom: 20px;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  height: 180px;
  border-radius: 2px;
  resize: none;

  &:focus {
    outline: 1px solid #e50043;
    border: 1px solid #e50043;
  }

  @media (max-width: 767px) {
    height: 150px;
    margin-bottom: 15px;
  }
`;

const NoticeText = styled.span`
  width: 100%;
  color: #ec4c47;
  font-size: 12px;
`;

const SendContainer = styled.div`
  position: relative;
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: flex-end;
`;

const SendButton = styled.button`
  position: relative;
  background: #E50043;
  border: 0;
  color: #fff;
  letter-spacing: 4px;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: bold;
  font-family: Poppins;
  width: 140px;
  height: 35px;

  &:hover {
    background: #ff2288;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow; none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  @media(max-width: 767px) {
    font-size: 12px;
    position: relative;
    right: 0;
    bottom: 20px;
    margin-top: 0;
    min-height: 35px;
  }
`;

export {
  ObjectQnAContainer,
  ContentForm,
  TitleInput,
  ContentArea,
  NoticeText,
  SendContainer,
  SendButton,
};

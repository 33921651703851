import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moveProduct } from '../../../actions/move.actions';
import { setAuth } from '../../../actions/auth.actions';
import axios from '../../../contants/axios';

import kioskimg from '../../../assets/tutorial/kioskmokup.png';
import pcTutorial1 from '../../../assets/tutorial/pc-tutorial-1.png';
import pcTutorial2 from '../../../assets/tutorial/pc-tutorial-2.png';
import pcTutorial3 from '../../../assets/tutorial/pc-tutorial-3.png';
import mobileTutorial1 from '../../../assets/tutorial/mobile-tutorial-1.png';
import mobileTutorial2 from '../../../assets/tutorial/mobile-tutorial-2.png';
import mobileTutorial3 from '../../../assets/tutorial/mobile-tutorial-3.png';

import tooltip from '../../../assets/tooltip.svg';

import {
  WebKioskContainer,
  TutorialImg,
  KioskImageArticle,
  ButtonContainer,
  PrevButton,
  NextButton,
  ButtonArticle,
  KisokPopUpMessage,
  Tooltip,
  MockupKiosk,
  MobileKioskContainer,
  MobilePrevButton,
  MobileNextButton,
  MockupContainer,
} from './styled/KioskTutorialStyled';

const KioskTutorial = () => {
  const dispatch = useDispatch();
  const { id, token } = useSelector((state) => state.auth);
  const [current, setCurrent] = useState(0);

  const startTutorial = () => {
    dispatch(setAuth({ tutorial: false }));
    dispatch(moveProduct('kiosk'));

    (async () => {
      await axios({
        method: 'put',
        url: `/users/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          tutorial: false,
        },
      });
    })();

    setTimeout(() => {
      dispatch(moveProduct(''));
    }, 100);
  };

  const Tutorial1 = ({ device }) => {
    return device === 'pc' ? (
      <>
        <ButtonArticle></ButtonArticle>
        <KioskImageArticle>
          <TutorialImg src={pcTutorial1} alt='pcTutorial1' />
        </KioskImageArticle>
        <ButtonArticle>
          <NextButton onClick={() => setCurrent(1)}>{'→'}</NextButton>
        </ButtonArticle>
      </>
    ) : (
      <>
        <TutorialImg src={mobileTutorial1} alt='mobileTutorial1' />
        <ButtonContainer>
          <MobilePrevButton></MobilePrevButton>
          <MobileNextButton onClick={() => setCurrent(1)}>
            {'다음 >'}
          </MobileNextButton>
        </ButtonContainer>
      </>
    );
  };

  const Tutorial2 = ({ device }) => {
    return device === 'pc' ? (
      <>
        <ButtonArticle>
          <PrevButton onClick={() => setCurrent(0)}>{'←'}</PrevButton>
        </ButtonArticle>
        <KioskImageArticle>
          <TutorialImg src={pcTutorial2} alt='pcTutorial2' />
        </KioskImageArticle>
        <ButtonArticle>
          <NextButton onClick={() => setCurrent(2)}>{'→'}</NextButton>
        </ButtonArticle>
      </>
    ) : (
      <>
        <TutorialImg src={mobileTutorial2} alt='mobileTutorial2' />
        <ButtonContainer>
          <MobilePrevButton onClick={() => setCurrent(0)}>
            {'< 이전'}
          </MobilePrevButton>
          <MobileNextButton onClick={() => setCurrent(2)}>
            {'다음 >'}
          </MobileNextButton>
        </ButtonContainer>
      </>
    );
  };

  const Tutorial3 = ({ device }) => {
    return device === 'pc' ? (
      <>
        <ButtonArticle>
          <PrevButton onClick={() => setCurrent(1)}>{'←'}</PrevButton>
        </ButtonArticle>
        <KioskImageArticle>
          <TutorialImg src={pcTutorial3} alt='pcTutorial3' />
        </KioskImageArticle>
        <ButtonArticle>
          <NextButton onClick={() => setCurrent(3)}>{'→'}</NextButton>
        </ButtonArticle>
      </>
    ) : (
      <>
        <TutorialImg src={mobileTutorial3} alt='mobileTutorial3' />
        <ButtonContainer>
          <MobilePrevButton onClick={() => setCurrent(1)}>
            {'< 이전'}
          </MobilePrevButton>
          <MobileNextButton onClick={() => setCurrent(3)}>
            {'다음 >'}
          </MobileNextButton>
        </ButtonContainer>
      </>
    );
  };

  const Tutorial4 = ({ device }) => {
    return device === 'pc' ? (
      <>
        <MockupContainer>
          <KisokPopUpMessage onClick={() => startTutorial()}>
            <Tooltip src={tooltip} alt='tooltip' />
            안내를 위해 키오스크 이미지 혹은 메시지를 눌러주세요.
          </KisokPopUpMessage>
          <MockupKiosk
            src={kioskimg}
            alt='kioskImg'
            onClick={() => startTutorial()}
          />
        </MockupContainer>
      </>
    ) : (
      <>
        <KisokPopUpMessage onClick={() => startTutorial()}>
          <Tooltip src={tooltip} alt='tooltip' />
          안내를 위해 키오스크 이미지 혹은 메시지를 눌러주세요.
        </KisokPopUpMessage>
        <MockupKiosk
          src={kioskimg}
          alt='kioskImg'
          onClick={() => startTutorial()}
        />
      </>
    );
  };

  return (
    <>
      <WebKioskContainer current={current}>
        {current === 0 ? (
          <Tutorial1 device={'pc'} />
        ) : current === 1 ? (
          <Tutorial2 device={'pc'} />
        ) : current === 2 ? (
          <Tutorial3 device={'pc'} />
        ) : current === 3 ? (
          <Tutorial4 device={'pc'} />
        ) : (
          ''
        )}
      </WebKioskContainer>
      <MobileKioskContainer current={current}>
        {current === 0 ? (
          <Tutorial1 device={'mobile'} />
        ) : current === 1 ? (
          <Tutorial2 device={'mobile'} />
        ) : current === 2 ? (
          <Tutorial3 device={'mobile'} />
        ) : current === 3 ? (
          <Tutorial4 device={'mobile'} />
        ) : (
          ''
        )}
      </MobileKioskContainer>
    </>
  );
};

export default KioskTutorial;

import styled from 'styled-components';

const Title = styled.span`
  font-size: 25px;
  line-height: 22px;
  color: #000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  border-bottom: 2px solid #000;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Content = styled.form`
  width: 100%;
  height: 90%;
  margin-top: 25px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    height: calc(100% - 80px);
  }
`;

const MobileTitle = styled.div`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 42px;
    background: #000;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }
`;

const MainText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  text-align: center;
  margin-top: 61px;
  margin-bottom: 76px;

  @media (max-width: 767px) {
    margin: auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SaveButton = styled.button`
  background: #e50043;
  border: 1px solid #e50043;
  width: 149px;
  height: 40px;
  color: #ffffff;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-right: 10px;

  &:hover {
    background: #ff2288;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const CancelButton = styled.button`
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  width: 149px;
  height: 40px;
  color: #666666;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-right: 10px;

  &:hover {
    background: #c6c6c6;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export {
  MobileTitle,
  Title,
  Content,
  MainText,
  ButtonWrapper,
  SaveButton,
  CancelButton,
};

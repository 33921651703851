import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import React, { Suspense } from 'react';
import { Spin } from 'antd';

import Rending from './pages/RendingPage';
import NotFound from './pages/NotFound';
import Main from './pages/Main';
import CheckPage from './pages/CheckPage';
import WaitingPage from './pages/WaitingPage';
import SubPage from './pages/SubPage';
import ReplyPage from './pages/ReplyPage';
import EDMPage from './pages/eDM/EDMPage';

// 벨류 체인
const ValueChain = React.lazy(() => import('./pages/ValueChain/ValueChain'));

const ZiPIDA = styled.span`
  position: fixed;
  color: rgba(255, 255, 255, 0.7);
  bottom: 15px;
  right: 15px;
  z-index: 995;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  &:hover {
    cursor: pointer;
    color: #fff;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    bottom: 10%;
  }
`;

function App() {
  let pathCheck = window.location.pathname;

  return (
    <>
      {pathCheck !== '/valuechain' && pathCheck !== '/edm' ? (
        <ZiPIDA
          tabIndex='-1'
          onClick={() => window.open('https://www.zipida.com/', '_blank')}>
          Powered by ZiPIDA
        </ZiPIDA>
      ) : (
        ''
      )}
      <BrowserRouter>
        <Suspense fallback={<Spin tip='Loading...' />}>
          <Switch>
            <Route path='/' exact component={Rending} />
            <Route path='/showroom' exact component={Main} />
            <Route path='/waiting' exact component={WaitingPage} />
            <Route path='/check' exact component={CheckPage} />
            <Route path='/valuechain' exact component={ValueChain} />
            <Route path='/seminar_room' exact component={SubPage} />
            <Route path='/reply' exact component={ReplyPage} />
            <Route path='/edm' exact component={EDMPage} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;

import styled from 'styled-components';

const ChatContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.show === 'show' ? '0' : '-100%')};
  left: 0;
  min-width: 480px;
  max-width: 480px;
  height: 70vh;
  background-color: #000;
  z-index: 996;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transition: bottom 1s, -webkit-transform 1s;
  transition: bottom 1s, transform 1s;
  transition-timing-function: ease;

  @media (max-width: 767px) {
    position: absolute;
    display: block;
    top: ${(props) => (props.show === 'show' ? '0' : '100%')};
    left: 0;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    transition: all 1s, transform 1s;
  }
`;

const CloseBtn = styled.button`
  position: relative;
  background-color: #000;
  border: 0;
  color: #fff;

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    cursor: pointer;
    border-left: 0;
  }

  &:after {
    content: '';
    width: 11px;
    height: 11px;
    position: absolute;
    left: 50%;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: translate(-50%, -50%) rotate(225deg);
  }

  &:hover:after {
    border-left: 2px solid #e50043;
    border-top: 2px solid #e50043;
    transform: translate(-50%, -50%) rotate(225deg);
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const ChatPopupTitle = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;

  @media (max-width: 767px) {
    height: 0;
    padding: 22px;
  }
`;

const PopupTitle = styled.span`
  position: relative;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #fff;

  @media (max-width: 767px) {
    line-height: 100%;
  }
`;

const ChatArticle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  padding: 20px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 15px;
    height: calc(100% - 155px);
  }
`;

// 채팅 시간 표시
const DateTitle = styled.span`
  position: relative;
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
  text-align: center;
  margin-bottom: 15px;
`;

// 유저 채팅 타이틀
const TitleArticle = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

// 유저 이름
const UserName = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-right: 10px;
`;

// 유저 채팅 시간
const ChatTime = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
`;

// 챗봇 콘텐츠 아티클
const ContentArticle = styled.div`
  position: relative;
  width: 70%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 25px;
  background: #f8f8f8;
  word-break: keep-all;

  @media (max-width: 767px) {
    width: 90%;
  }
`;

// 챗봇 콘텐츠
const Content = styled.span`
  position: relative;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  color: #000000;
  white-space: pre-line;
`;

// 챗봇 예 버튼 디자인
const YesButton = styled.button`
  position: relative;
  margin-top: 14px;
  height: 40px;
  background: #e50043;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 14px 13px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #f9777c;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

// 챗봇 아니오 버튼 디자인
const NoButton = styled.button`
  position: relative;
  margin-top: 10px;
  height: 40px;
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #666666;
  padding: 14px 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #c6c6c6;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

// ChatPopup Footer
const SendArticle = styled.form`
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  padding: 13px 20px;
  border-top: 2px solid #cbc9c9;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// 메시지 입력 input
const SendInpit = styled.input`
  position: relative;
  width: 100%;
  border: 0;
  color: #000;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

// 전송 버튼
const SendButton = styled.button`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 3px;
  color: #fff;
  border: 0;
  background: #000;
  width: 55px;
  height: 30px;
  padding: 5px 11px;
  margin-left: 21px;

  &:hover {
    cursor: pointer;
    background: #e50043;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

// 모바일 종료 버튼
const MobileClose = styled.button`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    display: block;
    z-index: 997;
    top: 14px;
    right: 25px;
    width: 16px;
    height: 16px;
    color: #000;
    background-image: url(${(props) => props.img});
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

// 유저 채팅 로그 아티클
const UserChatArticle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  overflow-wrap: break-word;
`;

// 유저 채팅 로그
const UserChat = styled.span`
  position: relative;
  max-width: 70%;
  padding: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #313131 100%),
    #000000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  color: #ffffff;
  white-space: pre-line;
  word-break: keep-all;
  overflow-wrap: break-word;

  @media (max-width: 767px) {
    max-width: 90%;
  }
`;

export {
  ChatContainer,
  CloseBtn,
  ChatPopupTitle,
  PopupTitle,
  ChatArticle,
  DateTitle,
  TitleArticle,
  UserName,
  ChatTime,
  ContentArticle,
  Content,
  YesButton,
  NoButton,
  SendArticle,
  SendInpit,
  SendButton,
  MobileClose,
  UserChatArticle,
  UserChat,
};

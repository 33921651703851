import React, { useState } from 'react';
import { Modal, Row, Col, Divider, Form, Input, Space, message } from 'antd';
import logo from '../../assets/logo.svg';
import axios from '../../contants/axios';
import { EDMButton, Title, Content, OkButton, ExitButton } from './EDMStyled';

const EDMPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [product, setProduct] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleClick = (product) => {
    setProduct(product);
    return setIsVisible(true);
  };

  const handleCancel = () => {
    setProduct('');
    return setIsVisible(false);
  };

  const handleOk = async ({ email }) => {
    const key = 'sending';

    if (loading) return;

    if (!product)
      return message.error(
        '제품 정보를 읽어올 수 없습니다. 창을 닫았다가 다시 시도해주세요.',
      );

    setLoading(true);
    message.loading({ content: '이메일 발송 준비중입니다.', key: key });

    await axios
      .post(`/email-reply`, {
        email: email,
        content: product,
      })
      .then((res) => {
        message.success({
          content: '이메일 발송에 성공했습니다.',
          key: key,
        });
        setIsVisible(false);
        return setIsDone(true);
      })
      .catch((err) => {
        message.error({
          content: '이메일 발송에 실패했습니다. 다시 시도해 주세요.',
          key: key,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div className='container' style={{ width: '700px', margin: 'auto' }}>
        <img
          src='https://api.rittal-exhibition.co.kr/uploads/1_18e5c0947a.png'
          style={{ width: '100%', marginBottom: '-5px' }}
          alt='img'
        />
        <directive
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '3px 45px',
            background: '#A0004B',
          }}
        >
          <EDMButton onClick={() => handleClick('TS 8 인클로저')}>
            상담하기
          </EDMButton>
          <EDMButton onClick={() => handleClick('콤팩트 박스 AE')}>
            상담하기
          </EDMButton>
          <EDMButton onClick={() => handleClick('팬 필터')}>상담하기</EDMButton>
          <EDMButton onClick={() => handleClick('Blue e+ 냉각장치')}>
            상담하기
          </EDMButton>
        </directive>
        <img
          src='https://api.rittal-exhibition.co.kr/uploads/2_baf53c750f.png'
          style={{ width: '100%', marginBottom: '-5px' }}
          alt='img'
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '3px 65px',
            background: '#E50043',
          }}
        >
          <EDMButton onClick={() => handleClick('TS 8 인클로저')}>
            상담하기
          </EDMButton>
          <EDMButton onClick={() => handleClick('RiLine 버스바 시스템')}>
            상담하기
          </EDMButton>
          <EDMButton onClick={() => handleClick('RSP 리탈 스마트 판넬')}>
            상담하기
          </EDMButton>
        </div>
        <img
          src='https://api.rittal-exhibition.co.kr/uploads/3_b6025fd9a9.png'
          style={{ width: '100%' }}
          alt='img'
        />
      </div>
      <Modal
        visible={isVisible}
        closable={false}
        maskClosable={false}
        footer={null}
        destroyOnClose={true}
        centered={true}
      >
        <Row>
          <Col span={24}>
            <Title>이메일 정보입력</Title>
          </Col>
          <Divider style={{ margin: '15px 0' }} />
          <Col span={24}>
            <Content>선택한 제품 : </Content>
            <Content style={{ color: '#E50043' }}>{product}</Content>
          </Col>
          <Col span={24}>
            <Content>회신을 위해 이메일 정보를 입력해주세요.</Content>
          </Col>
          <Col span={24} style={{ marginTop: 20 }}>
            <Form form={form} onFinish={handleOk}>
              <Form.Item
                name='email'
                label='이메일'
                rules={[
                  {
                    type: 'email',
                    message: '이메일을 정확하게 입력해주세요.',
                  },
                  {
                    required: true,
                    message: '이메일을 입력하지 않았습니다.',
                  },
                ]}
              >
                <Input size='middle' />
              </Form.Item>
              <Divider style={{ margin: '15px 0' }} />
              <Row justify='end'>
                <Col>
                  <Space direction='horizontal'>
                    <ExitButton onClick={handleCancel}>취소</ExitButton>
                    <OkButton htmlType='submit' type='primary'>
                      확인
                    </OkButton>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={isDone}
        closable={false}
        maskClosable={false}
        footer={null}
        destroyOnClose={true}
        centered={true}
      >
        <Row justify='center'>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Title
              style={{ borderBottom: '2px solid #000', paddingBottom: 10 }}
            >
              이메일 회신 안내
            </Title>
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: 40 }}>
            <Content
              style={{
                color: '#E7006A',
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              프로모션 정보를 전달하였습니다.
            </Content>
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
            <Content>이제 페이지를 종료하셔도 좋습니다.</Content>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Content>참여해주셔서 감사합니다.</Content>
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: 35 }}>
            <img src={logo} alt='logo' style={{ width: 120 }} />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EDMPage;

import styled from 'styled-components';
import Div100vh from 'react-div-100vh';

const Mobile100vh = styled(Div100vh)`
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  margin: 0;
  padding: 0;
  backdrop-filter: blur(5.43656px);
`;

const ContentArticle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;

  @media (max-width: 767px) {
    flex-direction: column;
    position: relative;
  }
`;

// 투어 시작 시 화면을 선택할 수 없게 가려버린다.
const Guard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: transparent;
`;

export { Guard, Mobile100vh, ContentArticle };

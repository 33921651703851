import styled from 'styled-components';

const Title = styled.span`
  font-size: 25px;
  line-height: 22px;
  color: #000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  border-bottom: 2px solid #000;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Content = styled.form`
  width: 100%;
  height: 90%;
  margin-top: 25px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    height: calc(100% - 80px);
  }
`;

const MobileTitle = styled.div`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 42px;
    background: #000;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }
`;

const MainText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 5px;

  &:before {
    content: '';
    display: inline-block;
    width: 7.14px;
    height: 11.43px;
    border: solid #e50043;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
    margin-right: 9px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const SubText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
`;

const SubText2 = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.65);
  margin: 0px 4px;

  display: inline-block;

  &:before {
    content: '';
    display: inline-block;
    width: 7.14px;
    height: 11.43px;
    border: solid #e50043;
    border-width: 0 1.2px 1.2px 0;
    transform: rotate(45deg);
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const TableArticle = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SaveButton = styled.button`
  background: #e50043;
  border: 1px solid #e50043;
  width: 149px;
  height: 40px;
  color: #ffffff;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-right: 10px;

  &:hover {
    background: #ff2288;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const CancelButton = styled.button`
  background: #f8f8f8;
  border: 1px solid #cbc9c9;
  width: 149px;
  height: 40px;
  color: #666666;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-right: 10px;

  &:hover {
    background: #c6c6c6;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  color: #000;
`;

const CheckMark = styled.span`
  position: relative;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #d3d3d3;
  margin-right: 10px;
  color: #000;

  &:hover {
    background-color: #e50043;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const CheckLabel = styled.label`
  width: 100%;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 27px;
  margin-top: 27px;

  & ${CheckInput}:checked ~ ${CheckMark} {
    background-color: #e50043;
  }

  & ${CheckInput}:checked ~ ${CheckMark}:after {
    display: block;
  }

  & > ${CheckMark}:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover > ${CheckMark} {
    background: #e50043;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const TableWrapper = styled.div`
  width: 95%;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0px auto 10px auto;
`;

const TableContent = styled.div`
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items-center;
  padding: 10px 20px;

  @media(max-width: 767px) {
    padding: 5px 5px;
  }
`;

const TextTitle = styled.span`
  width: 25%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);

  @media (max-width: 767px) {
    font-size: 11px;
  }
`;

const TextContent = styled.span`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);

  @media (max-width: 767px) {
    font-size: 11px;
  }
`;

export {
  MobileTitle,
  Title,
  Content,
  SubText,
  TableArticle,
  TableContent,
  TableWrapper,
  MainText,
  TextTitle,
  TextContent,
  SubText2,
  CheckLabel,
  CheckInput,
  CheckMark,
  ButtonWrapper,
  SaveButton,
  CancelButton,
};

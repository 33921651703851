import styled from 'styled-components';

const StampBtn = styled.button`
  width: 100%;
  margin: 0px 10px;
  padding: 0;
  background-color: transparent;
  border: 0;

  &:hover {
    cursor: ${(props) => (props.stampSwitch === true ? 'pointer' : 'default')};
  }

  &:active {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

const StampLine = styled.div`
  border-right: 1px solid rgba(203, 201, 201, 0.5);
  height: 32px;
  margin: 0px;
`;

const StampCount = styled.span`
  width: 100%;
  margin: 10px;
  padding: 0;
  color: #fff;
  font-family: Noto Sans KR;
  font-styled: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const StampDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  opacity: ${(props) => (props.stampSwitch === true ? 1 : 0.5)};

  &:hover {
    background-color: ${(props) =>
      props.stampSwitch === true ? '#E50043' : 'transparent'};
  }
`;

const StampImg = styled.img`
  position: relative;

  @media (max-width: 767px) {
    width: 18px;
    height: 20px;
  }
`;

export { StampDiv, StampBtn, StampImg, StampLine, StampCount };

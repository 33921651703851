export const userNameValidate = (name) => {
  const spe = name.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
  if (spe <= 0 && name === '') {
    return false;
  } else {
    return true;
  }
};

export const emailValidate = (email, emailAddress) => {
  const mail = `${email}@${emailAddress}`;
  var regExp = /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  if (mail === '@' || !regExp.test(mail)) {
    return false;
  } else {
    return true;
  }
};

export const passwordValidate = (password) => {
  var regExp = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,15}$/; //  8 ~ 15자 영문, 숫자 조합
  var regExp2 = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/; // 특수문자 / 문자 / 숫자 포함 형태의 8~15자리 이내의 암호 정규식
  if (regExp.test(password) || regExp2.test(password)) {
    return true;
  } else {
    return false;
  }
};

export const passwordCheckValidate = (password, passwordCheck) => {
  if (password === passwordCheck) {
    return true;
  } else {
    return false;
  }
};

export const companyValidate = (company) => {
  if (company !== undefined && company !== '') {
    return true;
  } else {
    return false;
  }
};

// 선택 사항
export const phoneValidate = (phone) => {
  const rule = `${phone}`;
  const phoneRule = /^\d{3}\d{3,4}\d{4}$/;
  const telrule = /^\d{2,3}\d{3,4}\d{4}$/;
  if (phoneRule.test(rule) || telrule.test(rule)) {
    return true;
  } else {
    return false;
  }
};

export const departmentValidate = (department) => {
  if (department === undefined || department === 'default') {
    return false;
  } else {
    return true;
  }
};

export const dutyValidate = (duty) => {
  if (duty === 'default' || duty === undefined) {
    return false;
  } else {
    return true;
  }
};

export const industryValidate = (industry) => {
  if (industry === 'default' || industry === undefined) {
    return false;
  } else {
    return true;
  }
};

export const pathValidate = (path) => {
  if (path === '미선택' || path === undefined) {
    return false;
  } else {
    return true;
  }
};

import React, { useRef } from 'react';
import { message } from 'antd';
import axios from '../../contants/axios';
import { emailData } from '../assets/SelectData';

import {
  PasswordWrapper,
  BackLink,
  InfoText,
  EmailArticle,
  EmailInput,
  AtInput,
  EmailSelectInput,
  EmailSelectBox,
  PasswordButton,
  ButtonText,
} from './PasswordStyled';

const FindPasswordForm = ({ isCurrent, setPage }) => {
  const emailSelect = useRef();
  const key = 'send';

  const handleChange = (event) => {
    const selectEmail = event.target.value;
    if (selectEmail === '직접입력') {
      emailSelect.current.value = '';
    } else {
      emailSelect.current.value = selectEmail;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, emailAddress } = event.target;

    message.loading({ content: '서버에 요청중입니다.', key });

    if (email.value !== '' && emailAddress.value !== '') {
      await axios({
        method: 'POST',
        url: '/auth/forgot-password',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email: `${email.value}@${emailAddress.value}`,
        },
      })
        .then((response) => {
          message.success({
            content: '요청을 완료했습니다. 이메일을 확인해주세요.',
            key,
          });
          isCurrent(1);
        })
        .catch((error) => {
          message.error({
            content: '없는 이메일 이거나, 잘못된 요청입니다.',
            key,
          });
        });
    } else {
      message.error({ content: '이메일 입력이 잘못되었습니다.', key });
    }
  };

  const handleCancel = () => {
    return setPage(2);
  };

  return (
    <PasswordWrapper onSubmit={handleSubmit} autoComplete='off'>
      <BackLink onClick={() => handleCancel()}>로그인으로 돌아가기</BackLink>
      <InfoText>
        회원가입시 입력하셨던 정보를 입력하여 주세요. <br />
        해당 이메일 주소로 비밀번호 재설정 코드가 전송됩니다.
      </InfoText>
      <EmailArticle>
        <EmailInput type='text' placeholder='e-mail 입력' name='email' />
        <AtInput readOnly value='@' tabIndex='-1' />
        <EmailSelectInput type='text' ref={emailSelect} name='emailAddress' />
        <EmailSelectBox onChange={handleChange}>
          {emailData.map((address) => {
            return (
              <option key={address} value={address}>
                {address}
              </option>
            );
          })}
        </EmailSelectBox>
      </EmailArticle>
      <PasswordButton type='submit'>
        <ButtonText>다음</ButtonText>
      </PasswordButton>
    </PasswordWrapper>
  );
};

export default FindPasswordForm;

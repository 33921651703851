import React from 'react';
import styled from 'styled-components';
import checkImg from '../assets/check.svg';

const CheckContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 10px;
`;

const Image = styled.img`
  margin-bottom: 77px;

  @media (max-width: 767px) {
    width: 15%;
  }
`;

const Title = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 42px;

  @media (max-width: 767px) {
    font-size: 22px;
  }
`;

const Content = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 14px;
    word-break: keep-all;
  }
`;

const WaitingPage = () => {
  return (
    <CheckContainer>
      <Image src={checkImg} alt='' />
      <Title>
        디지털 전시관
        <Title style={{ color: '#E50043' }}> 시스템 점검 </Title>중
      </Title>
      <Content style={{ marginBottom: '12px' }}>
        안정적인 서비스 제공을 위하여 시스템 점검을 실시하고 있습니다.
      </Content>
      <Content>
        점검시간 동안 전시관 입장이 중단되오니 양해 부탁드립니다.
      </Content>
    </CheckContainer>
  );
};

export default WaitingPage;

import React, { useState } from 'react';
import Header from './Header';
import { industryData } from '../../assets/SelectData';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import axios from '../../../contants/axios';

import {
  ObjectEstimateContainer,
  ContentForm,
  TopArticle,
  CompanyInput,
  IndustrySelect,
  PhoneInput,
  AddressInput,
  TitleInput,
  ContentTextArea,
  NoticeText,
  SendContainer,
  SendButton,
} from './styled/ObjectEstimateStyled';

const ObjectEstimate = ({ productName, category }) => {
  const key = 'sending';
  const { email, company, industry, phone } = useSelector(
    (state) => state.auth,
  );
  const [loading, setLoading] = useState(true);
  const phones = phone.split('|') || '';

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { company, industry, phone, address, title, content } = event.target;
    message.loading({ content: '견적 요청을 처리 중입니다.', key });

    if (title.value !== '' && content.value !== '' && loading) {
      setLoading(false);
      await axios({
        method: 'POST',
        url: '/email',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          from: 'no-reply@rittal-exhibition.co.kr',
          subject: `[견적요청] ${title.value}`,
          to: 'mkt@rittal.co.kr',
          replyTo: email,
          cc: '',
          bcc: '',
          text: `
          리탈 가상 전시관에서 발송된 견적요청 입니다.<br/>
          답장은 문의하신 고객님의 주소로 연결됩니다.
          <br/><br/>
          회사명 : ${company.value}<br/>
          산업군 : ${industry.value}<br/>
          이메일 : ${email}<br/>
          연락처 : ${phone.value}<br/>
          주소 : ${address.value}<br/>
          제품명 : ${productName}<br/>
          <br/>
          내용 : ${content.value}
          `,
          html: `
          리탈 가상 전시관에서 발송된 견적요청 입니다.<br/>
          답장은 문의하신 고객님의 주소로 연결됩니다.
          <br/><br/>
          회사명 : ${company.value}<br/>
          산업군 : ${industry.value}<br/>
          이메일 : ${email}<br/>
          연락처 : ${phone.value}<br/>
          주소 : ${address.value}<br/>
          제품명 : ${productName}<br/>
          <br/>
          내용 : ${content.value}
          `,
        },
      })
        .then((response) => {
          message.success({
            content: '견적 요청을 완료 했습니다.',
            key,
            duration: 2,
          });
          event.target.reset();
          setLoading(true);

          //eslint-disable-next-line no-undef
          gtag('event', 'send_email', {
            event_category: `Estimate_email`,
            event_label: `${category}|${productName}`,
          });
        })
        .catch((error) => {
          message.error({
            content: '없는 이메일 이거나, 잘못된 요청입니다.',
            key,
          });
          setLoading(true);
        });
    } else if (title.value === '' || content.value === '') {
      message.error({
        content: '견적 요청에 실패했거나 빈칸이 있습니다.',
        key,
        duration: 2,
      });
    }
  };

  return (
    <>
      <ObjectEstimateContainer onSubmit={handleSubmit}>
        <Header title={'견적 요청'} />
        <ContentForm>
          <TopArticle>
            <CompanyInput
              placeholder='회사명 *'
              type='text'
              name='company'
              defaultValue={company}
            />
            <IndustrySelect name='industry' defaultValue={industry}>
              {industryData.map((industry) => {
                return (
                  <option key={industry} value={industry}>
                    {industry}
                  </option>
                );
              })}
            </IndustrySelect>
          </TopArticle>
          <PhoneInput
            placeholder='연락처 *'
            type='text'
            name='phone'
            defaultValue={phones[1]}
          />
          <AddressInput placeholder='주소 *' type='text' name='address' />
          <TitleInput placeholder='제목 *' type='text' name='title' />
          <ContentTextArea placeholder='내용' type='text' name='content' />
          <NoticeText>※ 회신은 입장하실때 정보로 회신드립니다. </NoticeText>
          <SendContainer>
            <SendButton type='submit'>보내기</SendButton>
          </SendContainer>
        </ContentForm>
      </ObjectEstimateContainer>
    </>
  );
};

export default ObjectEstimate;

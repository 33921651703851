import React, { useState, useEffect } from 'react';
import { Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, setObjectBar } from '../../../actions/modals.actions';
import axios from '../../../contants/axios';

import helpImg from '../../../assets/kioskmenu/help.svg';
import notiImg from '../../../assets/kioskmenu/noti.svg';
import archiveImg from '../../../assets/kioskmenu/event.svg';
import infoImg from '../../../assets/kioskmenu/info.svg';
import tourImg from '../../../assets/kioskmenu/tour.svg';
import stampImg from '../../../assets/kioskmenu/stamp.svg';
import listImg from '../../../assets/kioskmenu/list.svg';
import seminarImg from '../../../assets/kioskmenu/seminar.svg';

import EplanLogo from '../../../assets/EPLAN_logo.png';
import RittalLogo from '../../../assets/RITTAL_logo.png';

import stampOffImg from '../../../assets/kioskmenu/stampOff.svg';
import tourOffImg from '../../../assets/kioskmenu/tourOff.svg';
import seminarOffImg from '../../../assets/kioskmenu/seminarOff.svg';

import Help from './Help';
import Archive from './Archive/Archive';
import Tour from './Tour';
import MobileHelp from './MobileHelp';

import {
  KioskContainer,
  MobileKioskClose,
  KioskDisplay,
  Rows,
  Cols,
  KioskLogo,
  MenuButton,
  MenuIcon,
  MenuTitle,
  CloseButton,
  MenuOffButton,
} from './styled/KioskPopupStyled';

const KioskPopup = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem('token');
  const [noticeCount, setNoticeCount] = useState(0);
  const { status: objectBarShow } = useSelector((state) => state.objectBar);
  const {
    stamp: stampSwitch,
    tour: tourSwitch,
    semina: seminaSwitch,
  } = useSelector((state) => state.settings);

  useEffect(() => {
    if (loading) {
      (async () => {
        const response = await axios({
          method: 'get',
          url: `/notices/count`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNoticeCount(response.data);
      })();
    }

    return () => {
      setLoading(false);
    };
  }, [loading, token]);

  const isCurrent = (state) => {
    setCurrent(state);
  };

  return (
    <>
      {current === 0 ? (
        <KioskContainer>
          <MobileKioskClose>키오스크</MobileKioskClose>
          <KioskDisplay>
            <Rows type='flex'>
              <Cols span={24} align='middle'>
                <KioskLogo
                  src={RittalLogo}
                  alt='logo'
                  style={{ marginRight: 15 }}
                />
                <KioskLogo src={EplanLogo} alt='logo' />
              </Cols>
            </Rows>
            <Rows type='flex'>
              <Cols span={8} align='middle'>
                <MenuButton onClick={() => setCurrent(1)}>
                  <MenuIcon src={helpImg} alt='help' />
                </MenuButton>
                <MenuTitle>조작방법</MenuTitle>
              </Cols>
              <Cols span={8} align='middle'>
                <Badge count={noticeCount} overflowCount={99}>
                  <MenuButton
                    onClick={() => {
                      dispatch(setModal({ type: 'notice', status: 'show' }));
                    }}
                  >
                    <MenuIcon src={notiImg} alt='noti' />
                  </MenuButton>
                  <MenuTitle>공지사항</MenuTitle>
                </Badge>
              </Cols>
              <Cols span={8} align='middle'>
                <MenuButton onClick={() => setCurrent(2)}>
                  <MenuIcon src={archiveImg} alt='archive' />
                </MenuButton>
                <MenuTitle>기술자료</MenuTitle>
              </Cols>
            </Rows>
            <Rows type='flex'>
              <Cols span={8} align='middle'>
                <MenuButton
                  onClick={() => {
                    dispatch(setModal({ type: 'airView', status: 'show' }));
                  }}
                >
                  <MenuIcon src={infoImg} alt='info' />
                </MenuButton>
                <MenuTitle>전시관 정보</MenuTitle>
              </Cols>
              {tourSwitch === true ? (
                <Cols span={8} align='middle'>
                  <MenuButton onClick={() => setCurrent(3)}>
                    <MenuIcon src={tourImg} alt='tour' />
                  </MenuButton>
                  <MenuTitle>전시관 투어</MenuTitle>
                </Cols>
              ) : (
                <Cols span={8} align='middle'>
                  <MenuOffButton onClick={() => {}}>
                    <MenuIcon src={tourOffImg} alt='tour' />
                  </MenuOffButton>
                  <MenuTitle style={{ color: '#CBC9C9' }}>
                    전시관 투어
                  </MenuTitle>
                </Cols>
              )}
              {stampSwitch === true ? (
                <Cols span={8} align='middle'>
                  <MenuButton
                    onClick={() => {
                      dispatch(setModal({ type: 'stamp', status: 'show' }));
                    }}
                  >
                    <MenuIcon src={stampImg} alt='stamp' />
                  </MenuButton>
                  <MenuTitle>스탬프</MenuTitle>
                </Cols>
              ) : (
                <Cols span={8} align='middle'>
                  <MenuOffButton onClick={() => {}}>
                    <MenuIcon src={stampOffImg} alt='stamp' />
                  </MenuOffButton>
                  <MenuTitle style={{ color: '#CBC9C9' }}>스탬프</MenuTitle>
                </Cols>
              )}
            </Rows>
            <Rows type='flex'>
              <Cols span={8} align='middle'>
                {objectBarShow === 'hide' ? (
                  <MenuButton
                    onClick={() => dispatch(setObjectBar({ status: 'show' }))}
                  >
                    <MenuIcon src={listImg} alt='list' />
                  </MenuButton>
                ) : (
                  <MenuButton
                    onClick={() => dispatch(setObjectBar({ status: 'hide' }))}
                  >
                    <MenuIcon src={listImg} alt='list' />
                  </MenuButton>
                )}
                <MenuTitle>제품 리스트</MenuTitle>
              </Cols>
              {seminaSwitch === true ? (
                <Cols span={8} align='middle'>
                  <MenuButton
                    onClick={() => {
                      window.location.href = '/seminar_room';
                    }}
                  >
                    <MenuIcon src={seminarImg} alt='seminar' />
                  </MenuButton>
                  <MenuTitle>세미나실</MenuTitle>
                </Cols>
              ) : (
                <Cols span={8} align='middle'>
                  <MenuOffButton onClick={() => {}}>
                    <MenuIcon src={seminarOffImg} alt='seminar' />
                  </MenuOffButton>
                  <MenuTitle style={{ color: '#CBC9C9' }}>세미나실</MenuTitle>
                </Cols>
              )}
            </Rows>
            <Rows type='flex'>
              <Cols span={24} align='middle'>
                <CloseButton
                  onClick={() =>
                    dispatch(setModal({ type: '', status: 'hide' }))
                  }
                >
                  닫기
                </CloseButton>
              </Cols>
            </Rows>
          </KioskDisplay>
        </KioskContainer>
      ) : current === 1 ? (
        <>
          <Help isCurrent={isCurrent} />
          <MobileHelp isCurrent={isCurrent} />
        </>
      ) : current === 2 ? (
        <Archive isCurrent={isCurrent} />
      ) : current === 3 ? (
        <Tour isCurrent={isCurrent} />
      ) : (
        setCurrent(0)
      )}
    </>
  );
};

export default KioskPopup;

import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ObjectBar from './Object/ObjectBar';
import ChatPopup from './Message/ChatPopup';
import ObjectPopup from './Object/ObjectPopup';
import KioskTutorial from './Kiosk/KioskTutorial';
import KioskPopup from './Kiosk/KioskPopup';
import StampPopup from './Stamp/StampPopup';
import AirView from './AirView/Info';
import NoticePopup from './Kiosk/Notice';
import ObjectDetail from './Object/ObjectDetail';
import TourGiude from './Tour/TourGuide';
import Myinfo from './MyInfo/MyInfo';
import PreventionPopup from './PreventionPopup';
import LogoutPopup from './MyInfo/LogoutPopup';
import ControlPopup from './Control/ControlPopup';

import { Guard, Mobile100vh, ContentArticle } from './ContentStyled';

const Content = () => {
  const { type, status } = useSelector((state) => state.modals);
  const { tutorial } = useSelector((state) => state.auth);
  const { switching } = useSelector((state) => state.tour);
  const [isView, setIsView] = useState(true);

  const handleIsView = useCallback((state) => {
    setIsView(state);
  }, []);

  return (
    <>
      <ObjectBar />
      <ChatPopup />
      <ObjectDetail />
      <TourGiude />
      {isView && <PreventionPopup handleIsView={handleIsView} />}

      {switching && <Guard />}

      {tutorial && <KioskTutorial tutorial={tutorial} />}

      {status === 'show' && type !== 'chat' ? (
        <>
          <Mobile100vh>
            <ContentArticle>
              {type === 'object' && <ObjectPopup />}
              {type === 'kiosk' && <KioskPopup />}
              {type === 'stamp' && <StampPopup />}
              {type === 'airView' && <AirView />}
              {type === 'notice' && <NoticePopup />}
              {type === 'myinfo' && <Myinfo />}
              {type === 'logout' && <LogoutPopup />}
              {type === 'control' && <ControlPopup />}
            </ContentArticle>
          </Mobile100vh>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Content;

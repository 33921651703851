import styled from 'styled-components';

const BoradContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  color: #000;
  height: 100%;
  margin-left: 134px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
`;

const ArchiveTitle = styled.div`
  min-height: 55px;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const TitleText = styled.span`
  width: 100%;
  color: #fff;
  font-fmaily: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  margin-left: 20px;
`;

const TitleButton = styled.button`
  color: #fff;
  border: none;
  font-fmaily: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  background: none;

  &:hover {
    color: #e50043;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    color: #e50043;
    outline: none;
    box-shadow: none;
  }
`;

const ContentArticle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 95%;
  padding: 10px;
  overflow: overlay;

  @media (max-width: 767px) {
    height: 95%;
  }
`;

export { BoradContainer, ArchiveTitle, TitleText, TitleButton, ContentArticle };

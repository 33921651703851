import styled from 'styled-components';

const NotFoundContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
`;

const Title = styled.h1`
  color: #fff;
  font-family: Poppins;
  font-weight: bold;
  font-size: 48px;
`;

const SubTitle = styled.h3`
  color: #fff;
  font-family: Poppins;
  font-weight: bold;
  font-size: 28px;
`;

const BackLink = styled.button`
  color: #e50043;
  margin-top: 25px;
  border: none;
  background-color: transparent;
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export { NotFoundContainer, Title, SubTitle, BackLink };

import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import axios from '../../contants/axios';

import {
  PasswordWrapper,
  InfoText,
  BackLink,
  CodeInput,
  PasswordInput,
  PasswordCheckInput,
  PasswordButton,
  ButtonText,
  ErrorText,
} from './PasswordStyled';

import { passwordValidate, passwordCheckValidate } from '../assets/validate';

const ResetPasswordForm = ({ isCurrent, setPage }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const key = 'send';

  useEffect(() => {
    setTimeout(() => {
      setError('');
    }, 3000);
  }, [error]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { resetCode, password, passwordCheck } = event.target;
    const finalCode = resetCode.value.replace(/\s/g, '');

    if (!passwordValidate(password.value)) {
      setError('password');
      setLoading(false);
    } else if (!passwordCheckValidate(password.value, passwordCheck.value)) {
      setError('passwordCheck');
      setLoading(false);
    } else if (resetCode.value === '') {
      setError('name');
      setLoading(false);
    } else {
      message.loading({ content: '비밀번호를 재설정합니다.', key });

      await axios
        .post('/auth/reset-password', {
          code: finalCode,
          password: password.value,
          passwordConfirmation: password.value,
        })
        .then((res) => {
          message.success({ content: '비밀번호를 재설정 하였습니다.', key });
          isCurrent(2);
        })
        .catch((err) => {
          message.error({ content: '비밀번호를 재설정 하지 못했습니다.', key });
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    isCurrent(0);
    return setPage(2);
  };

  return (
    <PasswordWrapper onSubmit={handleSubmit} autoComplete='off'>
      <BackLink onClick={() => handleCancel()}>로그인으로 돌아가기</BackLink>
      <InfoText>
        입력하신 메일에서 비밀번호 재설정 코드를 확인해주세요. <br />
        비밀번호 재설정 코드와 새 비밀번호를 입력하여 주세요.
      </InfoText>
      <CodeInput
        type='text'
        placeholder='코드'
        name='resetCode'
        error={error}
        autoComplete='new-password'
      />
      {error === 'name' ? (
        <ErrorText error={error}>※ 잘못된 코드입니다.</ErrorText>
      ) : (
        ''
      )}
      <PasswordInput
        type='password'
        placeholder='새 비밀번호'
        name='password'
        error={error}
        autoComplete='new-password'
      />
      {error === 'password' ? (
        <ErrorText error={error}>
          ※ 8~15자리 이내의 특수문자/문자/숫자 입니다.
        </ErrorText>
      ) : (
        ''
      )}
      <PasswordCheckInput
        type='password'
        placeholder='새 비밀번호 확인'
        name='passwordCheck'
        error={error}
        autoComplete='new-password'
      />
      {error === 'passwordCheck' ? (
        <ErrorText error={error}>※ 비밀번호가 일치하지 않습니다.</ErrorText>
      ) : (
        ''
      )}
      <PasswordButton type='submit' disabled={loading}>
        <ButtonText>다음</ButtonText>
      </PasswordButton>
    </PasswordWrapper>
  );
};

export default ResetPasswordForm;

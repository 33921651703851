import React from 'react';

import {
  PrivacyArticle,
  SignupTitle,
  PrivacyWrapper,
  PrivacyTitle,
  PrivacyContent,
  BackButton,
  PrivacyLink,
} from './styled/PrivacyStyled';

const PrivacyFrom = ({ isView, number }) => {
  return (
    <PrivacyArticle>
      <SignupTitle>개인정보수집동의</SignupTitle>
      <PrivacyWrapper>
        <span>
          ㈜리탈는 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의
          개인정보를 안전하게 취급하는데 최선을 다합니다.
        </span>
        <PrivacyTitle>개인정보 수집 및 이용에 대한 안내</PrivacyTitle>
        {number === '1' ? (
          <>
            <PrivacyContent>
              1. 목적 : 홈페이지 회원가입 및 관리, 재화 또는 서비스 제공,
              고객요청 처리
              <br />
              2. 항목 : 귀하가 서비스를 사용함으로써 당사는 귀하로부터 다음
               개인정보를 수집할 수 있습니다(특히 귀하가 정보를 제공하기로
              선택한 경우).
              <br />
              &nbsp;&nbsp; - 귀하의 아이디(이메일주소), 비밀번호, 이름,
              휴대폰번호, 회사명, 회사 주소, 전화번호, 서비스 이용 기록,
              접속로그, 쿠키, 접속 IP 정보
              <br />
              &nbsp;&nbsp; - 귀하의 성별, 나이, 국적, 교육 수준, 직업, 직책,
              소득 수준("인구 통계 정보").
              <br />
              &nbsp;&nbsp; - 귀하의 신용카드 번호, 청구 주소, 배송 주소, 보안
              코드, 기타 결제 거래 및 확인 세부 정보("결제 정보")
              <br />
              &nbsp;&nbsp; - 귀하가 본 페이지 및 제품, 클릭한 광고, 당사가 보낸
              이메일 가운데 귀하가 열어본 이메일, 브라우저 유형, 운영체제("OS"),
              인터넷 프로토콜("IP") 주소 및 장치 및 위치 정보(통칭 "분석 정보").
              <br />
              &nbsp;&nbsp; - 배송 주소 확인을 위한 우편 서비스 등 제3자의
              출처로부터 귀하와 관련하여 공개적으로 이용할 수 있는 정보
              <br />
              &nbsp;&nbsp; - 귀하의 모바일 OS, 당사가 내장한 모바일 장치 식별자
              또는 기타 일반적으로 사용되는 모바일 장치 식별자.
              <br />
              <br />
              <b>
                회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                불필요하게 되었을 때에는 5일 이내 해당 개인정보를 파기합니다.
              </b>
            </PrivacyContent>
            <span>
              위 개인정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부
              시에는 방문자 등록이 제한될 수 있습니다.
            </span>
          </>
        ) : number === '2' ? (
          <>
            <PrivacyContent>
              1. 목적: 마케팅 및 광고에의 활용
              <br />
              2. 항목: 위 ‘일반 개인정보 수집 및 이용’에 기재된 항목
              <br />
              3. 보유기간:{' '}
              <b>
                회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                불필요하게 되었을 때에는 5일 이내 해당 개인정보를 파기합니다.
              </b>
            </PrivacyContent>
            <span>
              위 개인정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부
              시에는 방문자 등록이 제한될 수 있습니다.
            </span>
          </>
        ) : (
          <PrivacyContent>
            ㈜리탈에서 제공하는 마케팅, 이벤트/혜택 등 다양한 정보를 전화, 문자
            메시지(SMS), 이메일(eDM)로 수신하는 것에 대하여 동의 여부를 표시하여
            주시기 바랍니다. 동의를 표시하지 않은 경우에는 해당 방식을 통한 상기
            정보 및 서비스 제공이 제한됩니다.
          </PrivacyContent>
        )}
        <br />
        <span>
          더 자세한 내용에 대해서는
          <PrivacyLink
            target='_blank'
            href='https://www.rittal.com/kr-ko/content/ko/impressum/impressum.jsp'
          >
            [리탈 코리아 전시관 개인정보 처리방침]
          </PrivacyLink>
          을 참고하시길 바랍니다.
        </span>
        <BackButton onClick={() => isView(false)}>닫기</BackButton>
      </PrivacyWrapper>
    </PrivacyArticle>
  );
};

export default PrivacyFrom;

import React, { useEffect, useState } from 'react';
import axios from '../../../contants/axios';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';
import mobileCloseImg from '../../../assets/closewhite.svg';
import { popupHistory, sendProductHistory } from '../../../contants/history';

import ObjectInfo from './ObjectInfo';
import ObjectEstimate from './ObjectEstimate';
import ObjectQnA from './ObjectQnA';
import ObjectImage from './ObjectImage';
import ObjectSurvey from './ObjectSurvey';
import CooperateTab from './CooperateTab';

import {
  ObjectContianer,
  TabStyle,
  MobileTabClose,
  MobileCloseButton,
} from './styled/ObjectPopupStyled';

const ObejctPopup = () => {
  const token = sessionStorage.getItem('token');
  const [stampList, setStampList] = useState([]);
  const { product } = useSelector((state) => state.product);
  const { loading: stampLoading } = useSelector((state) => state.stamp);
  const userData = useSelector((state) => state.auth);
  const { music } = useSelector((state) => state.musicToggle);
  const { productlist } = useSelector((state) => state.productlist);
  const [object, setObject] = useState({
    productMedia: undefined,
    productName: undefined,
    category2: undefined,
    productStamp: false,
    productLink: undefined,
    productFile: undefined,
  });
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [isView, setIsView] = useState(true);

  useEffect(() => {
    const newLocal = null;
    if (product !== newLocal && product !== undefined && productlist !== null) {
      productlist.forEach((data) => {
        if (data.id === product) {
          popupHistory(userData, data.productName, 'open');
          sendProductHistory(userData, data.productName);
          return setObject(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productlist]);

  useEffect(() => {
    if (isView) {
      (async () => {
        const response = await axios({
          method: 'get',
          url: `/users/me`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const list = response.data.userStamp || '';
        if (list !== '' && list !== undefined) {
          setStampList(list.split('|'));
        }
      })();
    }

    return () => {
      setIsView(false);
    };
  }, [isView, stampLoading, token]);

  // 구글 애널리틱스
  useEffect(() => {
    // 제품 인터랙션 횟수 시간 데이터
    if (object.productName !== undefined) {
      const clickDate = new Date();
      // eslint-disable-next-line no-undef
      gtag('event', 'user_location_data', {
        event_category: 'user_location',
        event_label: `${userData.email}|${object.productName}|${clickDate}`, // 사용자 위치 이동 데이터
      });

      // eslint-disable-next-line no-undef
      gtag('event', 'product_click', {
        event_category: 'product',
        event_label: `${object.category2}|${object.productName}`, // 사용자 위치 이동 데이터
      });
    }
  }, [userData, object]);

  useEffect(() => {
    if (music) {
      const toggle = document.getElementById('musicToggle');
      toggle.click();
    }
  }, [music]);

  const items = [
    {
      key: '1',
      label: '제품정보',
      children: (
        <ObjectInfo
          object={object}
          setCurrent={setCurrent}
          stampList={stampList}
        />
      ),
    },
    {
      key: '2',
      label: '견적요청',
      children: (
        <ObjectEstimate
          productName={object.productName}
          category={object.category2}
        />
      ),
    },
    {
      key: '3',
      label: 'Q&A',
      children: (
        <ObjectQnA
          productName={object.productName}
          category={object.category2}
        />
      ),
    },
    object.category2 === '공조' && {
      key: '4',
      label: '열량계산',
      children: <CooperateTab />,
    },
  ];

  return (
    <>
      {current === 0 ? (
        <ObjectContianer>
          <TabStyle tabPosition='left' defaultActiveKey='1' items={items}>
            {/* <TabPane tab='제품정보' key='1' style={{ height: '100%' }}>
              <ObjectInfo
                object={object}
                setCurrent={setCurrent}
                stampList={stampList}
                tabChange={tabChange}
              />
            </TabPane>
            <TabPane tab='견적요청' key='2'>
              <ObjectEstimate
                productName={object.productName}
                category={object.category2}
              />
            </TabPane>
            <TabPane tab='Q&A' key='3'>
              <ObjectQnA
                productName={object.productName}
                category={object.category2}
              />
            </TabPane>
            {object.category2 === '공조' && (
              <TabPane tab='열량계산' key='4'>
                <CooperateTab />
              </TabPane>
            )} */}
          </TabStyle>
          <ObjectImage media={object.productMedia} />
          <MobileTabClose className={'show'}>
            <MobileCloseButton
              onClick={() => dispatch(setModal({ type: '', stauts: 'hide' }))}
              img={mobileCloseImg}
            />
          </MobileTabClose>
        </ObjectContianer>
      ) : (
        <ObjectSurvey
          setCurrent={setCurrent}
          productName={object.productName}
          category={object.category2}
        />
      )}
    </>
  );
};

export default ObejctPopup;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ObjectDetailContainer,
  DetailArticle,
  DetailTitle,
  DetailContent,
  DetailSub,
} from './styled/ObjectDetailStyled';

const ObjectDetail = () => {
  const { tourId, hoverId, loading, type } = useSelector(
    (state) => state.getProductDetailed,
  );
  const { productlist } = useSelector((state) => state.productlist);
  const { switching } = useSelector((state) => state.tour);
  const [list, setList] = useState('');

  useEffect(() => {
    const isList = productlist.filter((index) => {
      if (switching === true && type === 'tour') {
        return index.id === tourId;
      } else if (switching === false && type === 'hover') {
        return index.id === hoverId;
      } else if (switching === true && type === 'hover') {
        return index.id === hoverId;
      } else {
        return null;
      }
    });
    setList(isList);
  }, [hoverId, productlist, switching, tourId, type]);

  return (
    <>
      {list.length > 0 && (
        <>
          {switching === false ? (
            <ObjectDetailContainer loading={loading}>
              {list !== ''
                ? list.map((data, index) => {
                    return (
                      <DetailArticle key={index}>
                        <DetailTitle>{data.productName}</DetailTitle>
                        <DetailContent>{data.BriefInfo}</DetailContent>
                        <DetailSub>
                          제품 클릭 시 상세 설명을 볼 수 있습니다.
                        </DetailSub>
                      </DetailArticle>
                    );
                  })
                : ''}
            </ObjectDetailContainer>
          ) : switching === true ? (
            <ObjectDetailContainer loading={loading}>
              {list !== ''
                ? list.map((data, index) => {
                    return (
                      <DetailArticle key={index}>
                        <DetailTitle>{data.productName}</DetailTitle>
                        <DetailContent>{data.BriefInfo}</DetailContent>
                      </DetailArticle>
                    );
                  })
                : ''}
            </ObjectDetailContainer>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default React.memo(ObjectDetail);

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { moveProduct } from '../../../actions/move.actions';
import { tourState } from '../../../actions/tour.actions';

import {
  TourContainer,
  TourText,
  ButtonArticle,
  KioskButton,
  CloseButton,
  TourTitleWrapper,
  TourTitle,
  TourBottomArticle,
  TourProgress,
  TourWrapper,
  TourLocation,
  TourLine,
  TourExitButton,
  TourExitText,
} from './TourGuideStyled';

const TourGiude = () => {
  const { guide, status, title, objects, switching } = useSelector(
    (state) => state.tour,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === 'start') {
      setTimeout(() => {
        dispatch(
          tourState({
            guide: false,
            status: '',
            switching: true,
          }),
        );
      }, 2000);
    }
  }, [dispatch, status]);

  const tourDone = () => {
    dispatch(
      tourState({
        guide: false,
        scene: 0,
        status: '',
        objects: [],
        switching: false,
        title: '',
      }),
    );
  };

  const moveKiosk = () => {
    tourDone();
    dispatch(moveProduct('kiosk'));
    setTimeout(() => {
      dispatch(moveProduct(''));
    }, 1000);
  };

  const tourRestart = () => {
    dispatch(
      tourState({
        guide: true,
        status: 'start',
      }),
    );
  };

  return (
    <>
      {guide === true && status === 'start' ? (
        <TourContainer>
          <TourText>투어를 시작합니다.</TourText>
        </TourContainer>
      ) : guide === true && status === 'exit' ? (
        <TourContainer>
          <TourText>투어가 종료되었습니다.</TourText>
          <TourText>다시 시작하시려면 키오스크로 가주세요.</TourText>
          <ButtonArticle>
            <KioskButton onClick={() => moveKiosk()}>
              키오스크로 가기
            </KioskButton>
            <CloseButton onClick={() => tourDone()}>닫기</CloseButton>
          </ButtonArticle>
        </TourContainer>
      ) : guide === true && status === 'done' ? (
        <TourContainer>
          <TourText>투어가 완료되었습니다.</TourText>
          <TourText>감사합니다.</TourText>
          <ButtonArticle>
            <KioskButton onClick={() => tourDone()}>확인</KioskButton>
            <CloseButton onClick={() => tourRestart()}>다시시작</CloseButton>
          </ButtonArticle>
        </TourContainer>
      ) : (
        ''
      )}
      {switching === true ? (
        <>
          <TourTitleWrapper>
            <TourTitle>{title}</TourTitle>
          </TourTitleWrapper>
          <TourBottomArticle>
            <TourProgress id='tour-progress'>
              {objects.map((_, index) => {
                if (index === 0) {
                  return (
                    <TourWrapper key={index}>
                      <TourLocation className='active' />
                    </TourWrapper>
                  );
                } else {
                  return (
                    <TourWrapper key={index}>
                      <TourLine />
                      <TourLocation />
                    </TourWrapper>
                  );
                }
              })}
            </TourProgress>
            <TourExitText>
              투어를 마치시려면
              <TourExitButton id='exitButton'>ESC</TourExitButton>
              버튼을 누르세요.
            </TourExitText>
          </TourBottomArticle>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default TourGiude;

import { message, Dropdown, Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { sendFileHistory } from '../../../contants/history';

import {
  FooterContainer,
  OnlineButton,
  DownloadButton,
} from './styled/FooterStyled';

const Footer = ({
  buy,
  brochure,
  tabChange,
  object_id,
  productName,
  category,
}) => {
  const userData = useSelector((state) => state.auth);

  const menu = (
    <Menu>
      {brochure !== undefined
        ? brochure.map((link, index) => {
            return (
              <Menu.Item key={index}>
                <span
                  onClick={() => {
                    window.open(
                      `https://api.rittal-exhibition.co.kr${link.url}`,
                    );
                    sendFileHistory(userData, productName, link.name, false);

                    //eslint-disable-next-line no-undef
                    gtag('event', 'catalog', {
                      event_category: `download`,
                      event_label: `${category}|${productName}`,
                    });
                  }}
                  style={{ textDecoration: 'underline' }}>
                  {link.name}
                </span>
              </Menu.Item>
            );
          })
        : ''}
    </Menu>
  );

  return (
    <FooterContainer>
      {object_id === '604efb98ab706939a96d0565' ? (
        <>
          <OnlineButton
            onClick={() => {
              window.open(
                'http://www.ecae.co.kr/index.php?mid=inquiry&act=dispBoardWrite',
                '_blank',
              );
            }}>
            구매문의
          </OnlineButton>
          <DownloadButton
            onClick={() => {
              window.open('http://www.ecae.co.kr/solutions', '_blank');
            }}>
            자세히보기
          </DownloadButton>
        </>
      ) : object_id === '604f149cab706939a96d0576' ? (
        <>
          <OnlineButton
            onClick={() => {
              window.open(
                'https://www.rittal.com/kr-ko/content/ko/support/software/ausw_hlen/rittal_configuration_system/rittalconfigurationsystem.jsp',
                '_blank',
              );

              //eslint-disable-next-line no-undef
              gtag('event', 'RiCS_click', {
                event_category: `rics`,
              });
            }}>
            무료 사용
          </OnlineButton>
          <DownloadButton
            onClick={() => {
              window.open(
                'https://www.youtube.com/watch?v=pztBmvkL8Gk',
                '_blank',
              );
            }}>
            사용 설명 보기
          </DownloadButton>
        </>
      ) : (
        <>
          <>
            {buy !== undefined && buy ? (
              <OnlineButton onClick={() => window.open(`${buy}`)}>
                온라인 구매
              </OnlineButton>
            ) : (
              <OnlineButton
                onClick={() => {
                  message.success(
                    '현재 제품의 온라인 구매가 불가능하여 견적문의로 전환합니다.',
                  );
                  tabChange('2');
                }}>
                온라인 구매
              </OnlineButton>
            )}
          </>
          <>
            {brochure !== undefined && brochure.length > 0 ? (
              <Dropdown overlay={menu} placement='topRight' arrow>
                <DownloadButton>브로셔 다운로드</DownloadButton>
              </Dropdown>
            ) : (
              <DownloadButton
                onClick={() =>
                  message.error('현재 해당 제품의 브로셔가 없습니다.')
                }>
                브로셔 다운로드
              </DownloadButton>
            )}
          </>
        </>
      )}
    </FooterContainer>
  );
};

export default Footer;

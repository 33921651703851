const viewSwitch = (viewer, movePosition) => {
  switch (movePosition) {
    // Kiosk
    case 'kiosk':
      return viewer.switchToView('Kiosk');
    case 'MiniKiosk':
      return viewer.switchToView('MiniKiosk');

    // Power Distribution
    case '601ae03b88983358235d1af1':
      return viewer.switchToView('RLine Busbar System');
    case '601ade2a88983358235d1aee':
      return viewer.switchToView('RiLineCompact_Close');
    case '5fb4a51bdcffb63ea07519f8':
      return viewer.switchToView('Ri4Power');

    // Climate Control
    case '5fb4a69edcffb63ea0751a04':
      return viewer.switchToView('blueECooling');
    case '5fb4a734dcffb63ea0751a09':
      return viewer.switchToView('blueEChiller');

    // Enclosures
    case '5fb49e41509f793d2773ba1a':
      return viewer.switchToView('TS 8');
    case '5fb49d77509f793d2773ba11':
      return viewer.switchToView('HD');
    case '5fb49818509f793d2773b9f8':
      return viewer.switchToView('KL');
    case '5fb49889509f793d2773b9fd':
      return viewer.switchToView('EB');
    case '601adb6b88983358235d1ae7':
      return viewer.switchToView('CompactAE');
    case '5fb4a393dcffb63ea07519e9':
      return viewer.switchToView('TSArm');
    case '5fb49cae509f793d2773ba02':
      return viewer.switchToView('AE1034');
    case '601bab9cdef52d17d82127d1':
      return viewer.switchToView('AE1280');
    case '5fb49d11509f793d2773ba07':
      return viewer.switchToView('AE1090');

    // IT Infrastructure
    case '601ae8a888983358235d1b0f':
      return viewer.switchToView('lcp');
    case '5fb4a784dcffb63ea0751a10':
      return viewer.switchToView('PDU');
    case '5fb4a7dcdcffb63ea0751a15':
      return viewer.switchToView('tsit');

    // Smart & Monitoring
    case '5fb4a9fadcffb63ea0751a21':
      return viewer.switchToView('edgeDc');
    case '5fb4a825dcffb63ea0751a19':
      return viewer.switchToView('HMDC');
    case '5fb4a9bbdcffb63ea0751a1d':
      return viewer.switchToView('smartPanel');
    case '601ae34c88983358235d1afc':
      return viewer.switchToView('SmartMonitoring');
    case '6267bb8ca6dd87531ed82b41':
      return viewer.switchToView('FMDC');

    // RAS & Digital Twin
    case '6267baeb2936b7532ec32d38':
      return viewer.switchToView('Smart Panel S2');
    case '601ae9da88983358235d1b1b':
      return viewer.switchToView('ras');

    // AirView
    case '인클로저':
      return viewer.switchToView('enclosures');
    case '배전':
      return viewer.switchToView('powerDistribution');
    case '공조':
      return viewer.switchToView('climateControl');
    case 'IT 인프라':
      return viewer.switchToView('27_Open');
    case '데이터 센터':
      return viewer.switchToView('dataCenter');
    case '스마트 팩토리':
      return viewer.switchToView('smartFactory');

    // EPlan
    case '602b921217ca810c81fd07aa':
      return viewer.switchToView('ValueChainZone');
    case '604efb98ab706939a96d0565':
    case '604f149cab706939a96d0576':
      return viewer.switchToView('designsupporting');

    // 디지털키

    case '6102409fc2ddcf08dad85217':
      return viewer.switchToView('digitalKey');

    // 모듈러센터

    case '61089a85c2ddcf08dad8521c':
      return viewer.switchToView('27_Open');

    default:
      return null;
  }
};

export default viewSwitch;

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Content from '../components/SubMenu/Content';
import { setModal } from '../actions/modals.actions';
import useAuth from '../hooks/useAuth';
import { setAuth } from '../actions/auth.actions';
import { setSetting } from '../actions/setting.actions';
import { message } from 'antd';
import axios from '../contants/axios';

const SubPage = () => {
  const [isLoad, setIsLoad] = useState(false);
  const [isView, setIsView] = useState(false);
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token');
  useAuth(true);

  // 유저 정보 받아오기
  useEffect(() => {
    (async () => {
      const response = await axios({
        method: 'get',
        url: `/users/me`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const {
        id,
        username,
        email,
        company,
        department,
        duty,
        industry,
        phone,
        tutorial,
      } = response.data;
      dispatch(
        setAuth({
          token,
          id,
          username,
          email,
          company,
          department,
          duty,
          industry,
          phone,
          tutorial,
        }),
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // 세미나 정보 받아오기
  useEffect(() => {
    if (!token) return;
    (async () => {
      const settingKey = 'setting';
      message.loading({
        content: '세미나 정보를 불러옵니다.',
        key: settingKey,
        duration: 0,
      });
      await axios({
        method: 'get',
        url: `/settings`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          message.success({
            content: '세미나 정보를 불러왔습니다.',
            key: settingKey,
          });
          dispatch(
            setSetting({
              seminaLink: res.data[0].seminaLink || '',
              seminarAgenda: res.data[0].seminarAgenda || '',
              seminarTitle: res.data[0].seminarTitle || '',
            }),
          );

          setIsLoad(true);
        })
        .catch((err) => {
          console.log(err);
          message.error({
            content: '세미나 정보가 누락되었습니다. 새로고침 해주세요.',
            key: settingKey,
          });
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleIsView = useCallback((props) => {
    setIsView(props);
  }, []);

  const handleClose = () => {
    dispatch(setModal({ type: '', status: 'hide' }));
    window.location.href = '/showroom';
  };

  return (
    <>
      {isLoad && (
        <Content
          handleClose={handleClose}
          handleIsView={handleIsView}
          isView={isView}
        />
      )}
    </>
  );
};

export default SubPage;

import { useHistory } from 'react-router-dom';

const useAuth = (hasToken) => {
  const token = sessionStorage.getItem('token');
  const history = useHistory();

  if (hasToken) {
    if (token === null) {
      history.push('/');
    }
  }
};

export default useAuth;

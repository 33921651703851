import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../../actions/modals.actions';
import { API_URL } from '../../../../contants/axios';
import { sendFileHistory } from '../../../../contants/history';

import {
  BoradContainer,
  ArchiveTitle,
  TitleText,
  TitleButton,
  ContentArticle,
} from './styled/BoradStyled';

import { Table } from 'antd';

const columns = [
  {
    title: '파일명',
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => a.title.localeCompare(b.title),
    render: (text, record) => (
      <a
        href={`${API_URL}${record.url}`}
        target='_blank'
        rel='noreferrer'
        title='클릭하면 pdf 파일을 오픈합니다.'
        onClick={() =>
          sendFileHistory(record.userData, record.category, record.title, true)
        }
      >
        {text}
      </a>
    ),
  },
  {
    title: '제공자',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '유형',
    dataIndex: 'ext',
    key: 'ext',
  },
  {
    title: '크기',
    dataIndex: 'size',
    key: 'size',
  },
];

const SolutionBoard = ({ data, loading, userData }) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      data.sort((a, b) => {
        return a.file.name.localeCompare(b.file.name, undefined, {
          numeric: true,
        });
      });

      const list = data.map((item, index) => {
        return {
          key: index,
          title: item.file.name || '',
          name: item.name || '',
          ext: item.file.ext || '',
          userData: userData,
          category: '솔루션',
          size:
            Number((item.file.size / 1024).toFixed(1)) < 1
              ? Number(item.file.size.toFixed(1)) + 'KB' || ''
              : Number((item.file.size / 1024).toFixed(1)) + 'MB' || '',
          url: item.file.url,
        };
      });

      setDataSource(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <BoradContainer>
      <ArchiveTitle>
        <TitleText>{'솔루션 기술자료'}</TitleText>
        <TitleButton
          onClick={() => {
            dispatch(setModal({ type: '', status: 'hide' }));
          }}
        >
          X
        </TitleButton>
      </ArchiveTitle>
      <ContentArticle>
        <Table
          dataSource={dataSource}
          columns={columns}
          size='small'
          loading={loading}
          footer={() =>
            '* .pdf 내장뷰어를 지원하는 브라우저에서 확인이 가능합니다.'
          }
        />
      </ContentArticle>
    </BoradContainer>
  );
};

export default SolutionBoard;

const imageTexture = (viewer, images) => {
  // url 없을 시 예외처리 추가 필요
  // 쇼룸 내부공간 이미지 교체
  let material = '';
  let image = new Image();
  image.src = `https://api.rittal-exhibition.co.kr${images.ContentMedia.url}`;
  image.crossOrigin = 'anonymous';

  const TextureChanger = (materialName) => {
    material = viewer.findMaterial(materialName);
    let imageTexture = viewer.createTextureFromHtmlImage(image);
    material.baseColorTexture = imageTexture;

    // 프레임 렌더링
    viewer.requestFrame();
    return true;
  };

  switch (images.ContentPosition) {
    case '매거진1':
      return TextureChanger('Magazine1');
    case '매거진2':
      return TextureChanger('Magazine2');
    case '매거진3':
      return TextureChanger('Magazine3');
    case '매거진4':
      return TextureChanger('Magazine4');
    case '매거진5':
      return TextureChanger('Magazine5');
    case '매거진6':
      return TextureChanger('Magazine6');
    case '키오스크 스크린세이버':
      return TextureChanger('Main_Kiosk_Screen#1');
    case '좌측 설계지원':
      return TextureChanger('MonitorScreen1');
    case '우측 설계지원':
      return TextureChanger('MonitorScreen2#1');
    default:
      return null;
  }
};

export default imageTexture;

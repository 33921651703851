const materialHover = (viewer, setDetail) => {
  // Metarial Hover Event

  // Power Distribution

  // RiLine
  viewer.onMaterialHoverChanged('RLine 버스바 시스템', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    'RLine 버스바 시스템_하단1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_빨간점',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템발간점과글자_',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_ㅠㅣㅕㄷ1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Red',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Gray_Bold',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Brown2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Blue2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Black2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Black1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Black',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_고리',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_녹색',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_하늘',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_하늘2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_회색',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '7_RLine 버스바 시스템_Blue',
    handleHoverChanged,
  );

  // 인클로저와 RiLine 콤팩트
  viewer.onMaterialHoverChanged(
    '인클로저 RiLine_Close_Red',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저 RiLine_Open_Green',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 RLine 콤팩트_Close1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 RLine 콤팩트_Close_Blue1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 RLine 콤팩트_Close_Logo1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Black',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Black2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Black3',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Blue',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Brown',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Gray',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Gray2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Logo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Red',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Sky',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 Rline 콤팩트_Open_Sky2',
    handleHoverChanged,
  );

  // 인클로저와 RiLine 콤팩트 버튼 (dispatch 제외)
  viewer.onMaterialHoverChanged(
    '인클로저와 RiLine_Botten_Close',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 RiLine_Button_Open',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 RiLine_Switch_Close',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '인클로저와 RiLine_Switch_Open',
    handleHoverChanged,
  );

  //Ri4Power
  viewer.onMaterialHoverChanged('8_Ri4Power', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Amerald', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Black', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Black2', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Blue', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Blue2', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Brown', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Brown1', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Brown3', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Gray', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Picture', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Red', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Rittal', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_Sky', handleHoverChanged);
  viewer.onMaterialHoverChanged('8_Ri4Power_White', handleHoverChanged);

  // Kiosk
  viewer.onMaterialHoverChanged('Main_Kiosk_Screen#1', handleHoverChanged);
  viewer.onMaterialHoverChanged('Main_Kiosk_Gray_Bold', handleHoverChanged);
  viewer.onMaterialHoverChanged('Main_Kiosk_Basic', handleHoverChanged);

  // ValueChain
  // viewer.onMaterialHoverChanged('DigitalTwin_Pillar_Set', handleHoverChanged);
  // viewer.onMaterialHoverChanged('Digital_Test2', handleHoverChanged);
  // viewer.onMaterialHoverChanged('Digital_Twin_test1', handleHoverChanged);
  // viewer.onMaterialHoverChanged(
  //   'DigitalTwin_Pillar_Color M08',
  //   handleHoverChanged,
  // );
  // viewer.onMaterialHoverChanged(
  //   'DigitalTwin_Pillar_Color M04',
  //   handleHoverChanged,
  // );
  // viewer.onMaterialHoverChanged('재질35', handleHoverChanged);

  // Climate Control
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_Black',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_Black2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_Blue',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_BlueLogo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_Logo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_Pink',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_Screen',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '12-1_TS8+Blue_e+Fan&Filter_Screen2',
    handleHoverChanged,
  );

  // Blue e+ Chiller & 열교환기
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기_Black',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기_Blue',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기_Gray',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기_Gray2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기_Logo1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기_Pink',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_Blue e+ Childer & 열 교환기_Pitch',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '13_lue e+ Childer & 열 교환기_그림',
    handleHoverChanged,
  );

  // Enclosures

  // HD
  viewer.onMaterialHoverChanged('HD 콤펙트 인클로저_Close', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    'HD 콤펙트 인클로저_Open_블렌더',
    handleHoverChanged,
  );

  // AE1034
  viewer.onMaterialHoverChanged('AE1034_Open', handleHoverChanged);
  viewer.onMaterialHoverChanged('AE1034_Open_블렌더', handleHoverChanged);

  // KL
  viewer.onMaterialHoverChanged('터미널박스 KL_Close', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    '터미널박스 KL_Open_블렌더',
    handleHoverChanged,
  );

  // EB
  viewer.onMaterialHoverChanged('E박스 EB_Close', handleHoverChanged);
  viewer.onMaterialHoverChanged('E박스 EB_Open_블렌더', handleHoverChanged);

  // AE1280
  viewer.onMaterialHoverChanged('AE1280_Close', handleHoverChanged);
  viewer.onMaterialHoverChanged('AE1280_Open_블렌더', handleHoverChanged);

  // AE1090
  viewer.onMaterialHoverChanged('AE1090_Close', handleHoverChanged);
  viewer.onMaterialHoverChanged('AE1090_Open_블렌더', handleHoverChanged);

  // small box button
  viewer.onMaterialHoverChanged('SmallBox_Switch_Close', handleHoverChanged);
  viewer.onMaterialHoverChanged('SmallBox_Switch_Open', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    'SmallBox_Swtich_Button_Close',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    'SmallBox_Switch_Button_Open1',
    handleHoverChanged,
  );

  viewer.onMaterialHoverChanged('서포트 암 시스템 CP', handleHoverChanged);
  viewer.onMaterialHoverChanged('서포트 암 시스템 CP_Gray', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    '서포트 암 시스템 CP_Gray2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '서포트 암 시스템 CP_Green',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged('서포트 암 시스템 CP_Logo', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    '서포트 암 시스템 CP_Screen',
    handleHoverChanged,
  );

  // TS8

  viewer.onMaterialHoverChanged('열반 시스템 TS 8', handleHoverChanged);
  viewer.onMaterialHoverChanged('열반 시스템 TS 8_Black', handleHoverChanged);
  viewer.onMaterialHoverChanged('열반 시스템 TS 8_Logo', handleHoverChanged);

  // IT Infrastructure
  viewer.onMaterialHoverChanged('27_LCP', handleEmissiveChanged);
  viewer.onMaterialHoverChanged('27_LCP_Center', handleEmissiveChanged);
  viewer.onMaterialHoverChanged('27_LCP_Screen', handleEmissiveChanged);
  viewer.onMaterialHoverChanged('27_LCP 울차리', handleEmissiveChanged);

  viewer.onMaterialHoverChanged('27_PDU_그물', handleEmissiveChanged);
  viewer.onMaterialHoverChanged('27_PDU', handleEmissiveChanged);

  // TS IT
  viewer.onMaterialHoverChanged('27_TS IT', handleEmissiveChanged);
  viewer.onMaterialHoverChanged('27_TS IT 울타리', handleEmissiveChanged);

  // Smart & Monitoring

  // 엣지 데이터 센터
  viewer.onMaterialHoverChanged(
    '19_엣지 데이터 센터 (Edge DC)',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '19_엣지 데이터 센터 (Edge DC)_Black2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '19_엣지 데이터 센터 (Edge DC)_Black3',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '19_엣지 데이터 센터 (Edge DC)_Glass',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '19_엣지 데이터 센터 (Edge DC)_Logo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '19_엣지 데이터 센터 (Edge DC)_Pink',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '19_엣지 데이터 센터 (Edge DC)_Screen',
    handleHoverChanged,
  );

  // HMDC
  viewer.onMaterialHoverChanged('16_HMDC', handleHoverChanged);
  viewer.onMaterialHoverChanged('16_HMDC_Logo', handleHoverChanged);
  viewer.onMaterialHoverChanged('16_HMDC_Screen', handleHoverChanged);
  viewer.onMaterialHoverChanged('16_HMDC_아래', handleHoverChanged);
  viewer.onMaterialHoverChanged('HMDC_Glass', handleHoverChanged);

  // 스마트 판넬
  viewer.onMaterialHoverChanged('18_스마트 판넬', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    '18_스마트 판넬 (Smart Panel)_Glass',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged('18_스마트 판넬_Black', handleHoverChanged);
  viewer.onMaterialHoverChanged('18_스마트 판넬_Black1', handleHoverChanged);
  viewer.onMaterialHoverChanged('18_스마트 판넬_Logo', handleHoverChanged);
  viewer.onMaterialHoverChanged('18_스마트 판넬_Screen', handleHoverChanged);
  viewer.onMaterialHoverChanged('18_스마트 판넬_Screen2', handleHoverChanged);
  viewer.onMaterialHoverChanged('18_스마트 판넬_Sky', handleHoverChanged);

  viewer.onMaterialHoverChanged('재질68', handleHoverChanged); // 스마트 모니터링

  // RAS & Digital Twin
  viewer.onMaterialHoverChanged('Mini_Kiosk_Screen#1', handleHoverChanged); // Value Chain
  viewer.onMaterialHoverChanged('rasimage', handleHoverChanged); // RAS (Rittal Automation System)

  // Smart Panel S2
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_Glass',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_HandleSide',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_Green',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_Black1',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Pannel_S2_Display',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_black2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_Black3',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_손잡이 Logo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_Fan&Filter_Logo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_Fan&Filter_Pink',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '30_Smart_Panel_S2_reblended_',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged('30_Smart_Pannel_Bluebox1', handleHoverChanged);
  viewer.onMaterialHoverChanged('30_Smart_Pannel_Bluebox2', handleHoverChanged);

  // FMDC
  viewer.onMaterialHoverChanged('29_FMDC_Reblended', handleHoverChanged);
  viewer.onMaterialHoverChanged('29_FMDC_reblended_유리', handleHoverChanged);
  viewer.onMaterialHoverChanged(
    '29_FMDC_Reblended_손잡이 Logo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '29_FMDC_Reblended_Fan&Filter_Logo',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '29_FMDC_Reblended_Fan&Filter_Pink',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '29_FMDC_Reblended_Fan&Filter_Screen2',
    handleHoverChanged,
  );

  // 설계 지원 & 매거진
  viewer.onMaterialHoverChanged('MonitorScreen1', handleHoverChanged);
  viewer.onMaterialHoverChanged('MonitorScreen2#1', handleHoverChanged);

  viewer.onMaterialHoverChanged('Magazine1', handleHoverChanged);
  viewer.onMaterialHoverChanged('Magazine2', handleHoverChanged);
  viewer.onMaterialHoverChanged('Magazine3', handleHoverChanged);
  viewer.onMaterialHoverChanged('Magazine4', handleHoverChanged);
  viewer.onMaterialHoverChanged('Magazine5', handleHoverChanged);
  viewer.onMaterialHoverChanged('Magazine6', handleHoverChanged);

  // 세미나 문
  viewer.onMaterialHoverChanged('SeminarRoom', handleHoverChanged);

  // 디지털 키
  viewer.onMaterialHoverChanged(
    'Digitalloce_Reblended_Black',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    'Digitalloce_Reblended_Blue',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    'Digitalloce_Reblended_Blue2',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    'Digitalloce_Reblended_Blue3',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    'Digitalloce_Reblended_White',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged('Digitallock_Reblended', handleHoverChanged);
  viewer.onMaterialHoverChanged('18_Digital_Lock_Table', handleHoverChanged);

  // 컨테이먼트
  viewer.onMaterialHoverChanged(
    '27_Cold aisle containment_디지털 전시장_Wall',
    handleEmissiveChanged,
  );

  // 컨테이먼트 도어
  viewer.onMaterialHoverChanged(
    '27_Cold aisle containment_디지털 전시장_Translucent_Glass_Gray',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '27_Cold aisle containment_디지털 전시장_Door',
    handleHoverChanged,
  );
  viewer.onMaterialHoverChanged(
    '27_Cold aisle containment_디지털 전시장_DoorLogo',
    handleHoverChanged,
  );

  // nonEmissive
  function handleHoverChanged(material, hoverActive) {
    if (hoverActive) {
      document.body.style.cursor = 'pointer';
      viewer.requestFrame();
      return hoverNameChange(material.name);
    } else {
      document.body.style.cursor = 'default';
      viewer.requestFrame();
      return setDetail({
        hoverId: '',
        loading: 'false',
        type: 'hover',
      });
    }
  }

  // onEmissive
  function handleEmissiveChanged(material, hoverActive) {
    if (hoverActive) {
      document.body.style.cursor = 'pointer';
      material.emissive = true;
      material.emissionStrength = 1;
      viewer.requestFrame();
      return hoverNameChange(material.name);
    } else {
      document.body.style.cursor = 'default';
      material.emissive = false;
      viewer.requestFrame();
      return setDetail({
        hoverId: '',
        loading: 'false',
        type: 'hover',
      });
    }
  }

  const hoverSubmit = (id) => {
    return setDetail({
      hoverId: id,
      loading: 'true',
      type: 'hover',
    });
  };

  function hoverNameChange(name) {
    switch (name) {
      // Power Distribution
      case 'RLine 버스바 시스템':
      case 'RLine 버스바 시스템_하단1':
      case '7_RLine 버스바 시스템_빨간점':
      case '7_RLine 버스바 시스템발간점과글자_':
      case '7_RLine 버스바 시스템_ㅠㅣㅕㄷ1':
      case '7_RLine 버스바 시스템_Red':
      case '7_RLine 버스바 시스템_Gray_Bold':
      case '7_RLine 버스바 시스템_Brown2':
      case '7_RLine 버스바 시스템_Blue2':
      case '7_RLine 버스바 시스템_Black2':
      case '7_RLine 버스바 시스템_Black1':
      case '7_RLine 버스바 시스템_Black':
      case '7_RLine 버스바 시스템_고리':
      case '7_RLine 버스바 시스템_녹색':
      case '7_RLine 버스바 시스템_하늘':
      case '7_RLine 버스바 시스템_하늘2':
      case '7_RLine 버스바 시스템_회색':
      case '7_RLine 버스바 시스템_Blue':
        return hoverSubmit('601ae03b88983358235d1af1');

      case '인클로저 RiLine_Close_Red':
      case '인클로저 RiLine_Open_Green':
      case '인클로저와 RLine 콤팩트_Close1':
      case '인클로저와 RLine 콤팩트_Close_Blue1':
      case '인클로저와 RLine 콤팩트_Close_Logo1':
      case '인클로저와 Rline 콤팩트_Open_Black':
      case '인클로저와 Rline 콤팩트_Open_Black2':
      case '인클로저와 Rline 콤팩트_Open_Black3':
      case '인클로저와 Rline 콤팩트_Open_Blue':
      case '인클로저와 Rline 콤팩트_Open_Brown':
      case '인클로저와 Rline 콤팩트_Open_Gray':
      case '인클로저와 Rline 콤팩트_Open_Gray2':
      case '인클로저와 Rline 콤팩트_Open_Logo':
      case '인클로저와 Rline 콤팩트_Open_Red':
      case '인클로저와 Rline 콤팩트_Open_Sky':
      case '인클로저와 Rline 콤팩트_Open_Sky2':
        return hoverSubmit('601ade2a88983358235d1aee');

      case '8_Ri4Power':
      case '8_Ri4Power_Amerald':
      case '8_Ri4Power_Black':
      case '8_Ri4Power_Black2':
      case '8_Ri4Power_Blue':
      case '8_Ri4Power_Blue2':
      case '8_Ri4Power_Brown':
      case '8_Ri4Power_Brown1':
      case '8_Ri4Power_Brown3':
      case '8_Ri4Power_Gray':
      case '8_Ri4Power_Picture':
      case '8_Ri4Power_Red':
      case '8_Ri4Power_Rittal':
      case '8_Ri4Power_Sky':
      case '8_Ri4Power_White':
        return hoverSubmit('5fb4a51bdcffb63ea07519f8');

      // Climate Control
      case '12-1_TS8+Blue_e+Fan&Filter':
      case '12-1_TS8+Blue_e+Fan&Filter_Black':
      case '12-1_TS8+Blue_e+Fan&Filter_Black2':
      case '12-1_TS8+Blue_e+Fan&Filter_Blue':
      case '12-1_TS8+Blue_e+Fan&Filter_BlueLogo':
      case '12-1_TS8+Blue_e+Fan&Filter_Logo':
      case '12-1_TS8+Blue_e+Fan&Filter_Pink':
      case '12-1_TS8+Blue_e+Fan&Filter_Screen':
      case '12-1_TS8+Blue_e+Fan&Filter_Screen2':
        return hoverSubmit('5fb4a69edcffb63ea0751a04');

      case '13_Blue e+ Childer & 열 교환기':
      case '13_Blue e+ Childer & 열 교환기_Black':
      case '13_Blue e+ Childer & 열 교환기_Blue':
      case '13_Blue e+ Childer & 열 교환기_Gray':
      case '13_Blue e+ Childer & 열 교환기_Gray2':
      case '13_Blue e+ Childer & 열 교환기_Logo1':
      case '13_Blue e+ Childer & 열 교환기_Pink':
      case '13_Blue e+ Childer & 열 교환기_Pitch':
      case '13_lue e+ Childer & 열 교환기_그림':
        return hoverSubmit('5fb4a734dcffb63ea0751a09');

      // Enclosures
      case 'HD 콤펙트 인클로저_Close':
      case 'HD 콤펙트 인클로저_Open_블렌더':
        return hoverSubmit('5fb49d77509f793d2773ba11');
      case 'AE1034_Open':
      case 'AE1034_Open_블렌더':
        return hoverSubmit('5fb49cae509f793d2773ba02');
      case '터미널박스 KL_Close':
      case '터미널박스 KL_Open_블렌더':
        return hoverSubmit('5fb49818509f793d2773b9f8');
      case 'E박스 EB_Close':
      case 'E박스 EB_Open_블렌더':
        return hoverSubmit('5fb49889509f793d2773b9fd');
      case 'AE1280_Close':
      case 'AE1280_Open_블렌더':
        return hoverSubmit('601bab9cdef52d17d82127d1');
      case 'AE1090_Close':
      case 'AE1090_Open_블렌더':
        return hoverSubmit('5fb49d11509f793d2773ba07');

      case '열반 시스템 TS 8':
      case '열반 시스템 TS 8_Black':
      case '열반 시스템 TS 8_Logo':
        return hoverSubmit('5fb49e41509f793d2773ba1a');

      case '콤팩트 인클로저 AE':
      case '콤팩트 인클로저 AE_Black':
      case '콤팩트 인클로저 AE_Black2':
      case '콤팩트 인클로저 AE_Black3':
      case '콤팩트 인클로저 AE_Blue':
      case '콤팩트 인클로저 AE_Gray':
      case '콤팩트 인클로저 AE_Gray2':
      case '콤팩트 인클로저 AE_Logo':
      case '콤팩트 인클로저 AE_Red':
      case '콤팩트 인클로저 AE_Sky':
      case '콤팩트 인클로저 AE_White':
      case '콤팩트 인클로저 AE_화살표와 원':
      case '콤펙트 인클로저 AE_블렌더':
        return hoverSubmit('601adb6b88983358235d1ae7');

      case '서포트 암 시스템 CP':
      case '서포트 암 시스템 CP_Gray':
      case '서포트 암 시스템 CP_Gray2':
      case '서포트 암 시스템 CP_Green':
      case '서포트 암 시스템 CP_Logo':
      case '서포트 암 시스템 CP_Screen':
        return hoverSubmit('5fb4a393dcffb63ea07519e9');

      // IT Infrastructure
      case '27_LCP':
      case '27_LCP_Center':
      case '27_LCP_Screen':
      case '27_LCP 울차리':
        return hoverSubmit('601ae8a888983358235d1b0f');

      case '27_PDU_그물':
      case '27_PDU':
        return hoverSubmit('5fb4a784dcffb63ea0751a10');

      case '27_TS IT':
      case '27_TS IT 울타리':
        return hoverSubmit('5fb4a7dcdcffb63ea0751a15');

      // Smart & Monitoring
      case '19_엣지 데이터 센터 (Edge DC)':
      case '19_엣지 데이터 센터 (Edge DC)_Black2':
      case '19_엣지 데이터 센터 (Edge DC)_Black3':
      case '19_엣지 데이터 센터 (Edge DC)_Glass':
      case '19_엣지 데이터 센터 (Edge DC)_Logo':
      case '19_엣지 데이터 센터 (Edge DC)_Pink':
      case '19_엣지 데이터 센터 (Edge DC)_Screen':
        return hoverSubmit('5fb4a9fadcffb63ea0751a21');

      case '16_HMDC':
      case '16_HMDC_Logo':
      case '16_HMDC_Screen':
      case '16_HMDC_아래':
      case 'HMDC_Glass':
        return hoverSubmit('5fb4a825dcffb63ea0751a19');

      case '18_스마트 판넬':
      case '18_스마트 판넬 (Smart Panel)_Glass':
      case '18_스마트 판넬_Black':
      case '18_스마트 판넬_Black1':
      case '18_스마트 판넬_Logo':
      case '18_스마트 판넬_Screen':
      case '18_스마트 판넬_Screen2':
      case '18_스마트 판넬_Sky':
        return hoverSubmit('5fb4a9bbdcffb63ea0751a1d');

      case '재질68':
        return hoverSubmit('601ae34c88983358235d1afc');

      // RAS & Digital Twin

      // FMDC
      case '29_FMDC_Reblended':
      case '29_FMDC_reblended_유리':
      case '29_FMDC_Reblended_손잡이 Logo':
      case '29_FMDC_Reblended_Fan&Filter_Logo':
      case '29_FMDC_Reblended_Fan&Filter_Pink':
      case '29_FMDC_Reblended_Fan&Filter_Screen2':
        return hoverSubmit('6267bb8ca6dd87531ed82b41');

      // Smart Panel S2
      case '30_Smart_Panel_S2_reblended':
      case '30_Smart_Panel_S2_reblended_Glass':
      case '30_Smart_Panel_S2_reblended_HandleSide':
      case '30_Smart_Panel_S2_reblended_Green':
      case '30_Smart_Panel_S2_reblended_Black1':
      case '30_Smart_Pannel_S2_Display':
      case '30_Smart_Panel_S2_reblended_black2':
      case '30_Smart_Panel_S2_reblended_Black3':
      case '30_Smart_Panel_S2_reblended_손잡이 Logo':
      case '30_Smart_Panel_S2_reblended_Fan&Filter_Logo':
      case '30_Smart_Panel_S2_reblended_Fan&Filter_Pink':
      case '30_Smart_Panel_S2_reblended_':
      case '30_Smart_Pannel_Bluebox1':
      case '30_Smart_Pannel_Bluebox2':
        return hoverSubmit('6267baeb2936b7532ec32d38');

      case 'rasimage':
        return hoverSubmit('601ae9da88983358235d1b1b');

      // 설계 지원 소프트웨어 :
      case 'MonitorScreen1':
        return hoverSubmit('604efb98ab706939a96d0565'); // 좌측
      case 'MonitorScreen2#1':
        return hoverSubmit('604f149cab706939a96d0576'); // 우측

      case 'Mini_Kiosk_Screen#1':
        return hoverSubmit('602b921217ca810c81fd07aa');

      // 키오스크 매거진 꽂이
      case 'Main_Kiosk_Screen#1':
        return hoverSubmit('60cc3f511da8f11a83b013c2');

      case 'Magazine1':
      case 'Magazine2':
      case 'Magazine3':
      case 'Magazine4':
      case 'Magazine5':
      case 'Magazine6':
        return hoverSubmit('60cc43ced881dd1a7365e367');

      // 디지털 키
      case 'Digitalloce_Reblended_Black':
      case 'Digitalloce_Reblended_Blue':
      case 'Digitalloce_Reblended_Blue2':
      case 'Digitalloce_Reblended_Blue3':
      case 'Digitalloce_Reblended_White':
      case 'Digitallock_Reblended':
      case '18_Digital_Lock_Table':
        return hoverSubmit('6102409fc2ddcf08dad85217');

      // 컨테이먼트
      case '27_Cold aisle containment_디지털 전시장_Wall':
      case '27_Cold aisle containment_디지털 전시장_BusDuct_WhitePink':
      case '27_Cold aisle containment_디지털 전시장_Busduct_Red':
      case '27_Cold aisle containment_디지털 전시장_울타리 건축':
        return hoverSubmit('61089a85c2ddcf08dad8521c');

      // 컨테이먼트 도어
      case '27_Cold aisle containment_디지털 전시장_Translucent_Glass_Gray':
      case '27_Cold aisle containment_디지털 전시장_Door':
      case '27_Cold aisle containment_디지털 전시장_DoorLogo':
        return hoverSubmit('611f631ec2ddcf08dad85283');

      default:
        return null;
    }
  }
};

export default materialHover;

import styled from 'styled-components';

const SigninWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 35vh;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 150px;
`;

const EmailArticle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
`;

const EmailText = styled.span`
  font-size: 12px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: #000;
`;

const EmailInput = styled.input`
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  margin-top: 5px;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
`;

const PasswordArticle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

const PasswordText = styled.span`
  font-size: 12px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: #000;
`;

const PasswordInput = styled.input`
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  margin-top: 5px;
  color: #000;

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
`;

const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CheckMark = styled.span`
  position: relative;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #d3d3d3;
  margin-right: 5px;

  &:hover {
    background-color: #E50043;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const CheckLabel = styled.label`
  width: 100%;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 15px 0px 15px 0px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  font-family: Noto Sans KR;
  color: #5a5a5a;

  & ${CheckInput}:checked ~ ${CheckMark} {
    background-color: #E50043;
  }

  & ${CheckInput}:checked ~ ${CheckMark}:after {
    display: block;
  }

  & > ${CheckMark}:after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover > ${CheckMark} {
    background: #E50043;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
`;

const SigninButton = styled.button`
  width: 100%;
  background: #eee;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  border: 0;
  color: #5a5a5a;
  height: auto;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 15px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;

  &:hover {
    color: #fff;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    background: #E50043;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &:hover:after {
    left: 0;
  }
`;

const ButtonText = styled.span`
  position: relative;
  z-index: 1;
`;

const SigninInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SignupLink = styled.span`
  color: #000;
  font-size: 12px;
  font-family: Noto Sans KR;
  font-weight: normal;
  font-style: normal;
  line-height: 22px;
  text-decoration: none;

  &:hover {
    color: #E50043;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    font-size: 10px;
  }
`;

const FindPwLink = styled.span`
  color: #000;
  font-size: 12px;
  font-family: Noto Sans KR;
  font-weight: normal;
  font-style: normal;
  line-height: 22px;

  &:hover {
    color: #E50043;
    cursor: pointer;
  }

  &:before {
    content: '|';
    color: #8a8a8a;
    margin: 0px 50px;
  }

  @media (max-width: 767px) {
    font-size: 10px;

    &:before {
      content: '|';
      color: #8a8a8a;
      margin: 0px 35px;
    }
  }
`;

export {
  SigninWrapper,
  Logo,
  EmailArticle,
  EmailText,
  EmailInput,
  PasswordArticle,
  PasswordText,
  PasswordInput,
  CheckMark,
  CheckInput,
  CheckLabel,
  SigninButton,
  ButtonText,
  SigninInfo,
  SignupLink,
  FindPwLink,
};

import React, { useEffect, useState } from 'react';
import { Collapse, Pagination } from 'antd';
import closeImg from '../../../assets/close.svg';
import mobileCloseImg from '../../../assets/closewhite.svg';
import axios, { API_URL } from '../../../contants/axios';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../actions/modals.actions';

import {
  NoticeContainer,
  MobileTitle,
  MobileCloseButton,
  CloseButton,
  Title,
  Content,
  NoticeImg,
} from './styled/NoticeStyled';

const { Panel } = Collapse;

const Notice = () => {
  const token = sessionStorage.getItem('token');
  const dispatch = useDispatch();
  const perPage = 5; // 페이지 최대 수
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({
    total: 0,
    current: 1, // 현재 페이지
    minIndex: 0,
    maxIndex: 0,
  });

  useEffect(() => {
    if (loading) {
      (async () => {
        const response = await axios({
          method: 'get',
          url: `/notices`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const notices = response.data;
        if (response.data !== undefined && response.data !== '') {
          notices.sort((a, b) => {
            if (a.updatedAt === b.updatedAt) {
              return 0;
            }
            return a.updatedAt < b.updatedAt ? 1 : -1;
          });
        }
        setDataList(notices);
        setPage({
          total: response.data.length / perPage,
          minIndex: 0,
          maxIndex: perPage,
        });
      })();
    }

    return () => {
      setLoading(false);
    };
  }, [loading, token]);

  const pageChange = (index) => {
    setPage({
      current: index,
      minIndex: (index - 1) * perPage,
      maxIndex: index * perPage,
    });
  };

  const { current, minIndex, maxIndex } = page;

  return (
    <NoticeContainer>
      <MobileTitle>공지사항</MobileTitle>
      <MobileCloseButton
        onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
        img={mobileCloseImg}
      />
      <CloseButton
        onClick={() => dispatch(setModal({ type: '', status: 'hide' }))}
        img={closeImg}
      />
      <Title>공지사항</Title>
      <Content>
        {dataList.map((data, index) => {
          const subStr = data.createdAt;
          const date = subStr.substr(0, 10);
          return (
            index >= minIndex &&
            index < maxIndex && (
              <Collapse expandIconPosition={'end'} key={index}>
                <Panel header={`${data.title}`} key={index}>
                  <p>작성일시 : {date}</p>
                  {data.media.length > 0 ? (
                    <NoticeImg
                      src={`${API_URL}${data.media[0].url}`}
                      alt='notice'
                    />
                  ) : (
                    ''
                  )}
                  {data.content}
                </Panel>
              </Collapse>
            )
          );
        })}
        <Pagination
          defaultCurrent={1}
          pageSize={perPage}
          total={dataList.length}
          onChange={pageChange}
          current={current}
          hideOnSinglePage={true}
        />
      </Content>
    </NoticeContainer>
  );
};

export default Notice;

/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import mobileCloseImg from '../../../assets/closewhite.svg';
import { message as Message, Spin } from 'antd';
import useWebSocket from 'react-use-websocket';
import axios from '../../../contants/axios';
import useEventListener from '../../../hooks/useEventListener';
import { moveProduct } from '../../../actions/move.actions';

import { setChatModal } from '../../../actions/modals.actions';

import {
  ChatContainer,
  CloseBtn,
  ChatPopupTitle,
  PopupTitle,
  ChatArticle,
  DateTitle,
  TitleArticle,
  UserName,
  ChatTime,
  ContentArticle,
  Content,
  YesButton,
  NoButton,
  SendArticle,
  SendInpit,
  SendButton,
  MobileClose,
  UserChatArticle,
  UserChat,
} from './ChatPopupStyled';

const WEBSOCKET_URL = 'wss://rittalsocket.rittal-exhibition.co.kr/ws/';
// const WEBSOCKET_URL = 'ws://localhost:8080/ws/';

const message = {
  id: null,
  name: '',
  text: '',
  time: '',
  controls: null,
};

const ChatRecv = ({
  key,
  username = '리탈코리아',
  time = '11:35 AM',
  content = '안녕하세요, 리탈 코리아 디지털 전시관입니다.\n아래 버튼을 클릭하시면\n제가 안내해드릴게요.',
  controls = null,
}) => {
  return (
    <div key={key}>
      <TitleArticle>
        <UserName>{username}</UserName>
        <ChatTime>{time}</ChatTime>
      </TitleArticle>
      <ContentArticle>
        <Content>{content}</Content>
        {controls &&
          controls.map((item, index) => {
            // eslint-disable-next-line array-callback-return
            if (item.length < 2) return;

            const text = item[0];
            const script = item[1];

            return item.length > 2 ? (
              <YesButton key={`${key}-${index}`} onClick={() => eval(script)}>
                {text}
              </YesButton>
            ) : (
              <NoButton key={`${key}-${index}`} onClick={() => eval(script)}>
                {text}
              </NoButton>
            );
          })}
      </ContentArticle>
    </div>
  );
};

const ChatSend = ({ key, content = '아니오, 주문 취소할래요.' }) => {
  return (
    <div key={key}>
      <UserChatArticle>
        <UserChat>{content}</UserChat>
      </UserChatArticle>
    </div>
  );
};

const ChatPopup = () => {
  const token = sessionStorage.getItem('token');
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.chatModal);
  const { id: userId, username } = useSelector((state) => state.auth);
  const didUnmount = useRef(false);
  const messagesRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [botMessages, setBotMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { productlist } = useSelector((state) => state.productlist);

  useEventListener(messagesRef, 'DOMNodeInserted', (e) => {
    const { currentTarget: target } = e;
    target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  });

  const findBotMessage = useCallback(async () => {
    if (!token) return;

    const response = await axios({
      method: 'get',
      url: `/bot-messages`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setBotMessages(response.data);
  }, [token]);

  const findChat = useCallback(async () => {
    if (!token) return;

    const response = await axios({
      method: 'get',
      url: `/chats?room=${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setChats(response.data);
  }, [token, userId]);

  const insertChat = useCallback(
    async ({ message, name, system, userId, room, controls }) => {
      if (!token) return;

      try {
        await axios({
          method: 'post',
          url: `/chats`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            message,
            name,
            system,
            userId,
            room,
            controls,
          },
        });
      } catch (error) {
        Message.error('채팅 데이터베이스 오류');
      }
    },
    [token],
  );

  const upsertRoom = useCallback(
    async ({ message, name, room, userId, userName }) => {
      if (!token) return;

      let id = null;

      try {
        const response = await axios({
          method: 'get',
          url: `/rooms?room_eq=${room}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.length > 0) {
          id = response.data[0].id;
        }
      } catch (error) {
        Message.error('채팅 데이터베이스 오류');
      }

      try {
        const data = {
          message,
          name,
          room,
        };

        if (userId) data.userId = userId;
        if (userName) data.userName = userName;

        await axios({
          method: id ? 'put' : 'post',
          url: id ? `/rooms/${id}` : `/rooms`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
        });
      } catch (error) {
        Message.error('채팅 데이터베이스 오류');
      }
    },
    [token],
  );

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (!userId) return;

      resolve(`${WEBSOCKET_URL}${userId}`);
    });
  }, [userId]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(getSocketUrl, {
    shouldReconnect: () => {
      console.log(`websocket reconnecting...`);
      return didUnmount.current === false;
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  useEffect(() => {
    if (readyState !== 1) return;

    // set global
    window.send = sendChat;
    window.link = linkChat;
    window.move = moveChat;
  }, [readyState, botMessages]);

  useEffect(() => {
    if (!userId) return;

    findBotMessage();
    findChat();
    setIsLoading(false);
  }, [userId]);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (!lastMessage) return;
    setMessages([...messages, lastMessage]);
  }, [lastMessage]);

  const chatToMessage = (data) => {
    const { userId, name, message: text, createdAt } = data;
    message.userId = userId;
    message.name = name;
    message.text = text;
    message.time = moment(new Date(createdAt)).format('YYYY-MM-DD HH:mm:ss');
    message.controls = data.controls;

    return { ...message };
  };

  const dataToMessage = (data, timeStamp) => {
    try {
      const { name, text, time, controls, userId } = JSON.parse(data);
      message.userId = userId;
      message.name = name;
      message.text = text || data;
      message.time = moment(new Date(time)).format('YYYY-MM-DD HH:mm:ss');
      message.controls = controls;
    } catch (e) {
      message.userId = null;
      message.name = '리탈코리아';
      message.text = data;
      message.time = moment(timeStamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    }

    return { ...message };
  };

  const botMessageToComponents = (items) => {
    return items
      .filter(({ seq }) => seq > 0)
      .sort((a, b) => a.seq - b.seq)
      .map((data, index) => {
        const item = chatToMessage(data);
        return ChatRecv({
          key: index,
          username: item.name,
          content: item.text,
          time: item.time,
          controls: data.controls,
        });
      });
  };

  const chatToComponents = (items) => {
    return items.map((data, index) => {
      const item = chatToMessage(data);
      return itemToComponent(userId, item, index);
    });
  };

  const messageToComponents = (items) => {
    return items.map(({ data, timeStamp }, index) => {
      const item = dataToMessage(data.toString(), timeStamp);
      return itemToComponent(userId, item, index);
    });
  };

  const itemToComponent = (userId, item, index) => {
    if (!item.userId || item.userId !== userId) {
      return ChatRecv({
        key: index,
        username: item.name,
        content: item.text,
        time: item.time,
        controls: item.controls,
      });
    } else {
      return ChatSend({
        key: index,
        content: item.text,
      });
    }
  };

  const sendChat = async (value) => {
    await sendFromUser(userId, userId, username, value);

    // bot command
    await botCommand(value);

    return `send : ${value}`;
  };

  const sendFromUser = async (
    room,
    userId,
    username,
    value,
    system = 0,
    controls = null,
  ) => {
    // OPEN STATE
    if (readyState !== 1) return;
    if (!username || username === '') return;

    // send socket
    sendMessage(
      JSON.stringify({
        id: room,
        name: username,
        text: value,
        time: new Date().toString(),
        userId,
        controls,
      }),
    );

    // insert db
    insertChat({
      message: value,
      name: username,
      userId: userId,
      system,
      room,
      controls,
    });

    // update and insert db
    await upsertRoom({
      message: value,
      name: username,
      room,
      userId: system === 0 ? userId : null,
      userName: system === 0 ? username : null,
    });
  };

  const botCommand = async (value) => {
    const found = botMessages.find(({ command }) => command === value);
    if (!found) return;

    await sendFromUser(userId, '', '리탈봇', found.message, 1, found.controls);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const { chat } = event.target;
    const { value } = chat;

    if (!value || value === '') return;

    sendChat(value);

    event.target.reset();
  };

  const linkChat = async (value) => {
    await sendFromUser(userId, '', '리탈봇', '홈페이지로 이동합니다.');

    return window.open(`${value}`, '_blank'); // 새 창으로 열기
  };

  const moveChat = async (value) => {
    if (
      // 공간을 제외한 제품 체크
      value !== '공조' &&
      value !== '배전' &&
      value !== '인클로저' &&
      value !== 'IT 인프라' &&
      value !== '솔루션' &&
      value !== 'RAS & Digital'
    ) {
      productlist.map((data) => {
        if (data.productName === value) {
          dispatch(setChatModal({ show: 'hide' }));
          setTimeout(() => {
            dispatch(moveProduct(''));
          }, 2000);
          return dispatch(moveProduct(`${data.id}`));
        } else {
          return null;
        }
      });
    } else {
      // 공간은 해당 이름으로 이동
      dispatch(setChatModal({ show: 'hide' }));
      setTimeout(() => {
        dispatch(moveProduct(''));
      }, 2000);
      return dispatch(moveProduct(`${value}`));
    }
  };

  return (
    <ChatContainer show={show}>
      <MobileClose
        onClick={() => dispatch(setChatModal({ show: 'hide' }))}
        img={mobileCloseImg}
      />
      <ChatPopupTitle>
        <PopupTitle>메시지</PopupTitle>
        <CloseBtn onClick={() => dispatch(setChatModal({ show: 'hide' }))} />
      </ChatPopupTitle>
      <ChatArticle ref={messagesRef}>
        <DateTitle>오늘</DateTitle>
        {botMessages && botMessageToComponents(botMessages)}
        {chats && chatToComponents(chats)}
        {messages && messageToComponents(messages)}
      </ChatArticle>
      <SendArticle onSubmit={onSubmit}>
        <SendInpit
          placeholder='메세지를 입력해주세요.'
          type='text'
          name='chat'
        />
        <SendButton type='submit'>SEND</SendButton>
      </SendArticle>
      {isLoading && (
        <Spin style={{ position: 'absolute', left: '50%', top: '50%' }} />
      )}
    </ChatContainer>
  );
};

export default React.memo(ChatPopup);

import { TOUR_STATE } from '../actions/tour.actions';

const initialState = {
  guide: false,
  scene: 0,
  status: '',
  objects: [],
  switching: false,
  title: '',
};

export default function tour(state = initialState, action) {
  switch (action.type) {
    case TOUR_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

import styled from 'styled-components';

const MobileHelpContainer = styled.div`
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;

  @media (max-width: 767px) {
    display: block;
  }
`;

const LeftArrows = styled.button`
  position: relative;
  background-color: transparent;
  border: 0;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 100%;
    border-right: 2px solid #cbc9c9;
    border-bottom: 2px solid #cbc9c9;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &:hover:after {
    left: 90%;
    border-right: 2px solid #e50043;
    border-bottom: 2px solid #e50043;
    transition: all 0.3s ease-in-out;
  }
`;

const RightArrows = styled.button`
  position: relative;
  background-color: transparent;
  border: 0;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    border-left: 2px solid #cbc9c9;
    border-top: 2px solid #cbc9c9;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &:hover:after {
    left: 10%;
    border-left: 2px solid #e50043;
    border-top: 2px solid #e50043;
    transition: all 0.3s ease-in-out;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileTitle = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 42px;
  background: #000;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  }
`;

const MobileCloseButton = styled.button`
  display: none;

  @media (max-width: 767px) {
    position: absolute;
    display: block;
    top: 13px;
    right: 25px;
    width: 18px;
    height: 18px;
    color: #000;
    background-image: url(${(props) => props.img});
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const MobileArticle = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

const ImageArticle = styled.div`
  width: 90%;
  height: 80%;
  display: inline-block;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const PartArticle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const MainPart = styled.button`
  width: 115px;
  height: 42px;
  background: ${(props) => (props.part === true ? '#E50043' : '#F8F8F8')};
  border: ${(props) =>
    props.part === true ? '1px solid #E50043' : '1px solid #CBC9C9'};
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: ${(props) => (props.part === true ? '#ffffff' : '#666666')};

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }
`;

const KioskPart = styled.button`
  width: 115px;
  height: 42px;
  background: ${(props) => (props.part === true ? '#E50043' : '#F8F8F8')};
  border: ${(props) =>
    props.part === true ? '1px solid #E50043' : '1px solid #CBC9C9'};
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: ${(props) => (props.part === true ? '#ffffff' : '#666666')};

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }
`;

const ManualPart = styled.button`
  width: 115px;
  height: 42px;
  background: ${(props) => (props.part === true ? '#E50043' : '#F8F8F8')};
  border: ${(props) =>
    props.part === true ? '1px solid #E50043' : '1px solid #CBC9C9'};
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: ${(props) => (props.part === true ? '#ffffff' : '#666666')};

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }
`;

export {
  MobileHelpContainer,
  MobileTitle,
  PartArticle,
  MainPart,
  KioskPart,
  ManualPart,
  MobileCloseButton,
  MobileArticle,
  LeftArrows,
  RightArrows,
  Content,
  ImageArticle,
};

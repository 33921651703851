import styled from 'styled-components';

const StampContianer = styled.div`
  position: relative;
  width: 50%;
  height: 80vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  justify-content: center;
  align-items: center;

  @media (max-width: 1179px) {
    width: 75%;
  }

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100% !important;
  }
`;

const CloseButton = styled.button`
  position: relative;
  float: right;
  width: 28px;
  height: 28px;
  color: #000;
  background-image: url(${(props) => props.img});
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
  }

  @media (max-height: 900px) {
    width: 16px;
    height: 16px;
  }
`;

const TitleArticle = styled.div`
  position: relative;
  width: 100%;
  padding: 15px 30px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;

  @media (max-width: 767px) {
    padding: 15px 40px;
    height: 50px;
    flex-direction: row;
    align-items: center;
    flex: auto;
  }

  @media (max-height: 900px) {
    padding: 10px 30px;
  }
`;

const Title = styled.span`
  width: 100%;
  font-size: 18px;
  color: #fff;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  @media (max-height: 900px) {
    font-size: 14px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: overlay;
  background-color: #fff;
  padding: 0px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;

  @media (max-width: 1179px) {
    padding: 0px 60px;
  }

  @media (max-width: 767px) {
    padding: 15px;
    height: 100%;
    flex: auto;
  }

  @media (max-height: 955px) {
    padding: 0px 10px;
  }
`;

const BannerContianer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color: #000;

  @media (max-width: 767px) {
    height: 85px;
    flex: auto;
  }

  @media (max-height: 850px) {
    height: 85px;
  }
`;

export {
  StampContianer,
  TitleArticle,
  Title,
  CloseButton,
  Content,
  BannerContianer,
};

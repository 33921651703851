import React, { useState, useEffect } from 'react';
import closeImg from '../../../assets/closewhite.svg';
import { Checkbox, Radio } from 'antd';
import axios from '../../../contants/axios';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loadStamp, getStamp } from '../../../actions/stamp.actions';

import {
  ObjectSurveyContainer,
  TitleArticle,
  Title,
  CloseButton,
  ContentForm,
  SurvayArticle,
  SurvayTitle,
  EtcInput,
  SubmitButton,
} from './styled/ObjectSurveyStyled';

const ObjectSurvey = ({ setCurrent, productName, category }) => {
  const token = sessionStorage.getItem('token');
  const key = 'sending';
  const dispatch = useDispatch();
  const { email, id } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState('');
  const [userStamp, setUserStamp] = useState('');
  const [surveys, setSurveys] = useState({
    survey1: {
      seq: 1,
      content: '',
      type: '',
      question: '',
      answer: [],
    },
    survey2: {
      seq: 2,
      content: '',
      type: '',
      question: '',
      answer: [],
    },
    survey3: {
      seq: 3,
      content: '',
      type: '',
      question: '',
      answer: [],
    },
  });

  useEffect(() => {
    (async () => {
      await axios
        .get(`/questions?category=${category}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setQuestions(res.data);
        })
        .catch((err) => {
          message.error('설문지를 불러오지 못했습니다.');
        });
    })();

    (async () => {
      await axios({
        url: '/users/me',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setUserStamp(res.data.userStamp);
        })
        .catch((err) => {
          message.error('스탬프 목록을 불러오지 못했습니다.');
        });
    })();
  }, [category, token]);

  const handleChange = (event, index) => {
    if (index === 0) {
      setSurveys((prevState) => {
        return {
          ...prevState,
          survey1: {
            ...prevState.survey1,
            question: questions[0].question,
            content: questions[0].content,
            type: questions[0].type,
            answer: event,
            version: questions[0].version,
          },
        };
      });
    } else if (index === 1) {
      setSurveys((prevState) => {
        return {
          ...prevState,
          survey2: {
            ...prevState.survey2,
            question: questions[1].question,
            content: questions[1].content,
            type: questions[1].type,
            answer: event,
            version: questions[1].version,
          },
        };
      });
    } else if (index === 2) {
      setSurveys((prevState) => {
        return {
          ...prevState,
          survey3: {
            ...prevState.survey3,
            question: questions[2].question,
            content: questions[2].content,
            type: questions[2].type,
            answer: event,
            version: questions[2].version,
          },
        };
      });
    }
  };

  const surveyPost = async (survey, etc) => {
    let etcText = '';
    let answerText = '';

    if (typeof survey.answer === 'object') {
      answerText = survey.answer.join('|');
    } else {
      answerText = survey.answer;
    }

    if (etc !== undefined && etc.value !== undefined) {
      etcText = etc.value;
    }

    return await axios({
      url: '/Surveys',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        email: email,
        category: category,
        seq: survey.seq,
        question: survey.question,
        type: survey.type,
        content: survey.content,
        answer: answerText,
        etc: etcText,
        version: survey.version,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  const stampPost = async () => {
    // 스탬프가 있을 시
    if (userStamp !== '' && userStamp !== undefined) {
      axios({
        url: `/users/${id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          userStamp: `${userStamp}|${productName}`,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          return e;
        });
      // 스탬프가 없을 시
    } else {
      axios({
        url: `/users/${id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          userStamp: `${productName}`,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          return e;
        });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { etc1, etc2, etc3 } = event.target;

    if (
      surveys.survey1.answer.length > 0 &&
      surveys.survey2.answer.length > 0 &&
      surveys.survey3.answer.length > 0
    ) {
      setLoading(true);
      message.loading({
        content: '데이터를 전송중입니다. 잠시만 기다려주세요.',
        key,
      });

      try {
        await Promise.all([
          surveyPost(surveys.survey1, etc1),
          surveyPost(surveys.survey2, etc2),
          surveyPost(surveys.survey3, etc3),
          stampPost(),
        ]).then((res) => {
          dispatch(loadStamp());
          setCurrent(0);
          dispatch(getStamp());

          const clickDate = new Date();
          // eslint-disable-next-line no-undef
          gtag('event', 'stamp_click', {
            event_category: 'product_stamp',
            event_label: `${category}|${productName}|${email}|${clickDate}`, // 사용자 위치 이동 데이터
          });

          message.success({ content: '설문에 참여해주셔서 감사합니다.', key });
        });
      } catch (e) {
        message.error({
          content: '오류가 발생했습니다. 다시 한번 시도해주세요.',
          key,
        });
        setLoading(false);
      }
    } else {
      message.error({ content: '미선택 항목이 있습니다.' });
    }
  };

  return (
    <ObjectSurveyContainer>
      <TitleArticle>
        <Title>스탬프 설문</Title>
        <CloseButton img={closeImg} onClick={() => setCurrent(0)} />
      </TitleArticle>
      <ContentForm onSubmit={handleSubmit}>
        {questions !== ''
          ? questions.map((data, index) => {
              const contentData = data.content;
              const contentArray = contentData.split('|');
              const etcCheck = contentArray.indexOf('기타');
              if (etcCheck > -1) {
                contentArray.splice(etcCheck);
              }

              return (
                <SurvayArticle key={index}>
                  <SurvayTitle>{data.question}</SurvayTitle>
                  {data.type === 'checkbox' ? (
                    <>
                      <Checkbox.Group
                        onChange={(event) => handleChange(event, index)}
                      >
                        {contentArray.map((data) => {
                          return (
                            <Checkbox value={data} key={data}>
                              {data}
                            </Checkbox>
                          );
                        })}
                        <>
                          {etcCheck > -1 ? (
                            <Checkbox value={'기타'} key={index + 5}>
                              기타
                              {index === 0 &&
                              surveys.survey1.answer.indexOf('기타') > -1 ? (
                                <EtcInput
                                  type='text'
                                  id={`etc${index + 1}`}
                                  name={`etc${index + 1}`}
                                  required
                                />
                              ) : index === 1 &&
                                surveys.survey2.answer.indexOf('기타') > -1 ? (
                                <EtcInput
                                  type='text'
                                  id={`etc${index + 1}`}
                                  name={`etc${index + 1}`}
                                  required
                                />
                              ) : index === 2 &&
                                surveys.survey3.answer.indexOf('기타') > -1 ? (
                                <EtcInput
                                  type='text'
                                  id={`etc${index + 1}`}
                                  name={`etc${index + 1}`}
                                  required
                                />
                              ) : (
                                ''
                              )}
                            </Checkbox>
                          ) : (
                            ''
                          )}
                        </>
                      </Checkbox.Group>
                    </>
                  ) : (
                    <>
                      <Radio.Group
                        onChange={(event) =>
                          handleChange(event.target.value, index)
                        }
                      >
                        {contentArray.map((data) => {
                          return (
                            <Radio value={data} key={data}>
                              {data}
                            </Radio>
                          );
                        })}
                        <>
                          {etcCheck > -1 ? (
                            <Radio value={'기타'} key={index + 5}>
                              기타
                              {index === 0 &&
                              surveys.survey1.answer === '기타' ? (
                                <EtcInput
                                  type='text'
                                  id={`etc${index + 1}`}
                                  name={`etc${index + 1}`}
                                  required
                                />
                              ) : index === 1 &&
                                surveys.survey2.answer === '기타' ? (
                                <EtcInput
                                  type='text'
                                  id={`etc${index + 1}`}
                                  name={`etc${index + 1}`}
                                  required
                                />
                              ) : index === 2 &&
                                surveys.survey3.answer === '기타' ? (
                                <EtcInput
                                  type='text'
                                  id={`etc${index + 1}`}
                                  name={`etc${index + 1}`}
                                  required
                                />
                              ) : (
                                ''
                              )}
                            </Radio>
                          ) : (
                            ''
                          )}
                        </>
                      </Radio.Group>
                    </>
                  )}
                </SurvayArticle>
              );
            })
          : ''}
        <SubmitButton type='submit' disabled={loading}>
          완료
        </SubmitButton>
      </ContentForm>
    </ObjectSurveyContainer>
  );
};

export default ObjectSurvey;

import {
  SET_MODAL,
  SET_OBJECT_BAR,
  SET_CHAT_MODAL,
} from '../actions/modals.actions';

const initialState = {
  type: '',
  status: 'hide',
};

export function modals(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

const objectBarInitialState = {
  status: 'hide',
};

export function objectBar(state = objectBarInitialState, action) {
  switch (action.type) {
    case SET_OBJECT_BAR:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

const chatInitialState = {
  show: 'hide',
};

export function chatModal(state = chatInitialState, action) {
  switch (action.type) {
    case SET_CHAT_MODAL:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

import React from 'react';

import AdministerCheck from '../../../../assets/administer-check.svg';

import {
  TitleArticle,
  UserName,
  ChatTime,
  ContentArticle,
  Content,
} from './MessageBoxStyled';

const MessageAdminster = ({ defaultMessage }) => {
  return (
    <div key={defaultMessage.userId}>
      <TitleArticle>
        <UserName>
          리탈 / 이플랜{' '}
          <object
            style={{ marginLeft: '6px' }}
            type='image/svg+xml'
            data={AdministerCheck}
          >
            <embed></embed>
          </object>{' '}
        </UserName>
        <ChatTime>{defaultMessage.time}</ChatTime>
      </TitleArticle>
      <ContentArticle style={{ backgroundColor: '#E50043' }}>
        <Content style={{ color: '#fff' }}>
          {defaultMessage.userMessage}
        </Content>
      </ContentArticle>
    </div>
  );
};

export default MessageAdminster;
